import { useCheckUsersAffectedMutation, usePutGlobalRoleMutation } from '@apis';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector, useForm } from '@hooks';
import { PermissionItem, PermissionTableItem, UpdateRoleForm } from '@interfaces';
import MaterialTable from '@material-table/core';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { green, red } from '@constants';
import { openSnackbar } from '@slices';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialog } from '../ui';

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  refetch?: () => void;
  data: UpdateRoleForm;
  serviceId?: number;
  isSysAdmin: boolean;
  roleId: number;
};

const EditRoleDialogForm = ({ setOpen, open, refetch, data, isSysAdmin, roleId }: Props): JSX.Element => {
  const { t } = useTranslation('pano');
  const tableRef = createRef() as React.RefObject<any> | React.MutableRefObject<undefined> | undefined;
  const { roles } = useAppSelector((x) => x.role);
  const dispatch = useAppDispatch();
  const { publicPermissions, allPermissions } = useAppSelector((x) => x.permission);
  const { activeTeam, userRights } = useAppSelector((x) => x.app);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState<string | JSX.Element>('');
  const [updatePayload, setUpdatePayload] = useState<UpdateRoleForm | undefined>(undefined);
  const [isUserAffectedDialogOpen, setIsUserAffectedDialogOpen] = useState<boolean>(false);
  const [affectedUsersCount, setAffectedUsersCount] = useState<number>(0);

  const [tableData, setTableData] = useState<PermissionTableItem[]>(
    userRights.isSysAdmin
      ? allPermissions.map((permission) => {
          return {
            id: permission.id,
            name: permission.name,
            public: permission.public,
            adminOnly: permission.adminOnly,
            active: permission.active,
            serviceId: permission.serviceId,
            tableData: {
              checked: data.permissionIds.includes(permission.id),
            },
          };
        })
      : publicPermissions.map((permission) => {
          return {
            id: permission.id,
            name: permission.name,
            public: permission.public,
            adminOnly: permission.adminOnly,
            active: permission.active,
            serviceId: permission.serviceId,
            tableData: {
              checked: data.permissionIds.includes(permission.id),
            },
          };
        }),
  );

  useEffect(() => {
    setFormData({ ...data });
    setTableData(
      userRights.isSysAdmin
        ? allPermissions.map((permission) => {
            return {
              id: permission.id,
              name: permission.name,
              public: permission.public,
              adminOnly: permission.adminOnly,
              active: permission.active,
              serviceId: permission.serviceId,
              tableData: {
                checked: data.permissionIds.includes(permission.id),
              },
            };
          })
        : publicPermissions.map((permission) => {
            return {
              id: permission.id,
              name: permission.name,
              public: permission.public,
              adminOnly: permission.adminOnly,
              active: permission.active,
              serviceId: permission.serviceId,
              tableData: {
                checked: data.permissionIds.includes(permission.id),
              },
            };
          }),
    );
  }, [open]);

  const handleCloseDialog = (_e?: {}, reason?: 'backdropClick' | 'escapeKeyDown'): void => {
    if (reason === 'backdropClick') return;
    resetForm();
    setOpen(false);
  };

  const [updateRole, { isLoading }] = usePutGlobalRoleMutation();
  const [checkUpdateRole, { isLoading: isCheckLoading }] = useCheckUsersAffectedMutation();

  const handleCloseConfirmDialog = (): void => {
    setIsConfirmDialogOpen(false);
  };

  const renderMessage = (permissionData: PermissionItem[]): JSX.Element => {
    const content = permissionData.map((x, i) => {
      return (
        <>
          <i key={i}>{x.name}</i> <br />
        </>
      );
    });

    return (
      <Typography>
        {`${t('nonPublicPermissionRemovedMessage')}`}
        <br />
        <br />
        {content}
      </Typography>
    );
  };

  const handleRoleSubmit = (): void => {
    if (userRights.isSysAdmin) {
      const nonPublicPermissionData = allPermissions.filter((x) => !x.public && data.permissionIds.some((y) => y === x.id));
      const nonPublicRemoved = nonPublicPermissionData.filter((x) => !formData.permissionIds.some((y) => y === x.id));
      if (nonPublicRemoved.length !== 0) {
        setConfirmDialogMessage(renderMessage(nonPublicRemoved));
        setIsConfirmDialogOpen(true);
      } else {
        handleUpdateCheck(formData);
      }
    } else {
      const roleData = { ...formData };
      allPermissions.filter((x) => !x.public && data.permissionIds.some((y) => y === x.id)).map((p) => roleData.permissionIds.push(p.id));
      handleUpdateCheck(roleData);
    }
  };

  const handleUpdateCheck = async (updateData: UpdateRoleForm): Promise<void> => {
    try {
      const result = await checkUpdateRole({
        data: updateData,
        roleId,
      }).unwrap();

      if (result > 5) {
        setUpdatePayload(updateData);
        setAffectedUsersCount(result);
        setIsUserAffectedDialogOpen(true);
      } else {
        handleUpdateRole(updateData);
      }
    } catch (err) {
      console.debug('Failed while checking affected users', err);
    }
  };

  const handleUpdateRole = (updateData?: UpdateRoleForm): void => {
    handleCloseConfirmDialog();
    handleCloseUserAffectedDialog();
    updateRole({ data: updateData ?? formData, roleId })
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
        if (refetch) refetch();
        handleCloseDialog();
      })
      .catch((err) => {
        console.debug('Failed while attempting to updating role', err);
        return;
      });
  };

  const nameCheck = (value: string): boolean => {
    return (
      !roles.some((x) => x.name.toLowerCase().trim() === value.toLocaleLowerCase().trim()) ||
      data.name.toLowerCase().trim() === value.toLocaleLowerCase().trim()
    );
  };

  const { handleSubmit, handleChange, formData, setFormData, errors, resetForm } = useForm<UpdateRoleForm>({
    validations: {
      name: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
        custom: {
          isValid: nameCheck,
          message: t('nameAlreadyExists'),
        },
      },
    },
    onSubmit: handleRoleSubmit,
    initialValues: { ...data },
  });

  const onSelectionChange = (data: PermissionTableItem[], rowData?: PermissionTableItem | undefined): void => {
    let permissionIds = [] as number[];
    let count = tableData.length;
    if (!isSysAdmin) {
      count = tableData.filter((x) => !x.adminOnly).length;
    }
    if (!rowData) {
      let isChecked = false;
      if (tableData.filter((x) => x.tableData.checked).length !== count) isChecked = true;

      tableData.forEach((rowData, index) => {
        if (!(!isSysAdmin && rowData.adminOnly)) {
          tableData[index].tableData.checked = isChecked;
          permissionIds.push(rowData.id);
        }
      });
      setFormData({ ...formData, permissionIds: permissionIds });
      setTableData(tableData);
      return;
    }

    tableData.forEach((rowData, index) => {
      if (data.find((x) => x.id === rowData.id)) {
        permissionIds.push(rowData.id);
        tableData[index].tableData.checked = true;
      } else if (tableRef?.current.dataManager.data.find((x: any) => x.id === rowData.id)) tableData[index].tableData.checked = false;
    });
    setFormData({ ...formData, permissionIds: permissionIds });
    setTableData(tableData);
  };

  const handleCloseUserAffectedDialog = (): void => {
    setIsUserAffectedDialogOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" fullWidth={true}>
        <DialogTitle id="dialog-title">{t('editRole')}</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Paper className="w-full p-5 space-y-4 mb-4">
              <Typography variant="subtitle1" gutterBottom>
                {t('roleDetails')}
              </Typography>

              <TextField
                name="name"
                autoComplete="nope"
                label={t('name')}
                placeholder={t('name') as string}
                fullWidth
                value={formData.name}
                onChange={handleChange}
                variant="outlined"
                {...(errors?.name && { error: true, helperText: errors.name })}
              />

              <FormGroup row>
                <FormControlLabel control={<Checkbox checked={formData.active} onChange={handleChange} color="primary" name="active" />} label={t('active')} />
              </FormGroup>
            </Paper>

            <Paper className="w-full space-y-4">
              <MaterialTable
                tableRef={tableRef}
                options={{
                  tableLayout: 'auto',
                  addRowPosition: 'first',
                  actionsColumnIndex: -1,
                  sorting: false,
                  search: true,
                  selection: true,
                  draggable: false,
                  selectionProps: (rowData) => ({
                    disabled: !isSysAdmin && rowData.adminOnly,
                  }),
                }}
                title={t('permissions')}
                onSelectionChange={onSelectionChange}
                columns={[
                  { title: t('name'), field: 'name' },
                  {
                    title: t('active'),
                    field: 'active',
                    type: 'boolean',
                    align: 'center',
                    width: '10%',
                    render: (rowData) =>
                      rowData.active ? (
                        <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
                      ) : (
                        <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />
                      ),
                  },
                  {
                    title: t('public'),
                    field: 'public',
                    type: 'boolean',
                    align: 'center',
                    width: '10%',
                    render: (rowData) =>
                      rowData.public ? (
                        <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
                      ) : (
                        <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />
                      ),
                  },
                  {
                    title: t('adminOnly'),
                    field: 'adminOnly',
                    type: 'boolean',
                    align: 'center',
                    width: '10%',
                    render: (rowData) =>
                      rowData.adminOnly ? (
                        <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
                      ) : (
                        <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />
                      ),
                  },
                ]}
                data={activeTeam?.isSysAdmin ? tableData : tableData.filter((x) => !x.adminOnly)}
              />
            </Paper>
          </DialogContent>

          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleCloseDialog} disabled={isLoading}>
              {t('cancelButtonText')}
            </Button>
            <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
              {isLoading && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
              {t('submitButtonText')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmationDialog
        onClose={handleCloseConfirmDialog}
        open={isConfirmDialogOpen}
        onConfirm={() => handleUpdateRole(updatePayload)}
        title={t('pleaseConfirm')}
        message={confirmDialogMessage}
      />

      <ConfirmationDialog
        onClose={handleCloseUserAffectedDialog}
        open={isUserAffectedDialogOpen}
        onConfirm={() => handleUpdateRole(updatePayload)}
        title={t('pleaseConfirm')}
        message={
          <Typography>
            {t('theseChangesWillAffect')} {affectedUsersCount} {t('users')}. {t('areYouSureYouWantToProceedWithTheseChanges')}
          </Typography>
        }
      />
    </>
  );
};

export default EditRoleDialogForm;
