import { reportApi } from '@apis';
import { FilterDto, ReportColumnDto, ReportData, ReportGroup, ScheduleRun, TableInfo, Template, TemplateSchedule } from '@interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ReportState {
  databaseSchema: TableInfo[];
  selectedColumns: ReportColumnDto[];
  filters: FilterDto[];
  generatedReport: {
    groups: ReportGroup[];
    isGrouped: boolean;
    data: ReportData[];
  };
  templates: Template[];
  currentTemplate: Template | null;
  logicalOperators: ('AND' | 'OR')[];
  schedules: {
    list: { [templateId: number]: TemplateSchedule[] };
    selectedSchedule: TemplateSchedule | null;
    scheduleHistory: { [scheduleId: number]: ScheduleRun[] };
  };
}

const initialState: ReportState = {
  databaseSchema: [],
  selectedColumns: [],
  filters: [],
  generatedReport: {
    groups: [],
    isGrouped: false,
    data: [],
  },
  templates: [],
  currentTemplate: null,
  logicalOperators: [],
  schedules: {
    list: {},
    selectedSchedule: null,
    scheduleHistory: {},
  },
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setSelectedColumns: (state, action: PayloadAction<ReportColumnDto[]>) => {
      state.selectedColumns = action.payload;
    },
    setFilters: (state, action: PayloadAction<FilterDto[]>) => {
      state.filters = action.payload;
    },
    setLogicalOperators: (state, action: PayloadAction<('AND' | 'OR')[]>) => {
      state.logicalOperators = action.payload;
    },
    clearGeneratedReport: (state) => {
      state.generatedReport = {
        groups: [],
        isGrouped: false,
        data: [],
      };
    },
    setCurrentTemplate: (state, action: PayloadAction<Template | null>) => {
      state.currentTemplate = action.payload;
    },
    setSchedules: (state, action: PayloadAction<{ templateId: number; schedules: TemplateSchedule[] }>) => {
      state.schedules.list[action.payload.templateId] = action.payload.schedules;
    },
    setSelectedSchedule: (state, action: PayloadAction<TemplateSchedule | null>) => {
      state.schedules.selectedSchedule = action.payload;
    },
    setScheduleHistory: (state, action: PayloadAction<{ scheduleId: number; runs: ScheduleRun[] }>) => {
      state.schedules.scheduleHistory[action.payload.scheduleId] = action.payload.runs;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(reportApi.endpoints.getDatabaseSchema.matchFulfilled, (state, { payload }) => {
      state.databaseSchema = payload;
    });
    builder.addMatcher(reportApi.endpoints.getSchedulesByTemplate.matchFulfilled, (state, { payload, meta }) => {
      state.schedules.list[meta.arg.originalArgs] = payload;
    });
    builder.addMatcher(reportApi.endpoints.getScheduleRuns.matchFulfilled, (state, { payload, meta }) => {
      state.schedules.scheduleHistory[meta.arg.originalArgs.scheduleId] = payload;
    });
    builder.addMatcher(reportApi.endpoints.generateReport.matchFulfilled, (state, { payload }) => {
      if (payload.groups && payload.groups.length > 0) {
        state.generatedReport = {
          groups: payload.groups,
          isGrouped: true,
          data: payload.groups[0].data,
        };
      } else {
        state.generatedReport = {
          groups: [
            {
              tables: [],
              data: payload.data,
            },
          ],
          isGrouped: false,
          data: payload.data,
        };
      }
    });

    builder.addMatcher(reportApi.endpoints.getTemplates.matchFulfilled, (state, { payload }) => {
      state.templates = payload.filter((template) => !template.deleted).sort((a, b) => a.id - b.id);
    });

    builder.addMatcher(reportApi.endpoints.runTemplate.matchFulfilled, (state, { payload }) => {
      if (payload.groups && payload.groups.length > 0) {
        state.generatedReport = {
          groups: payload.groups,
          isGrouped: true,
          data: payload.groups[0].data,
        };
      } else {
        state.generatedReport = {
          groups: [
            {
              tables: [],
              data: payload.data,
            },
          ],
          isGrouped: false,
          data: payload.data,
        };
      }
    });
  },
});

export const {
  setSelectedColumns,
  clearGeneratedReport,
  setFilters,
  setLogicalOperators,
  setCurrentTemplate,
  setScheduleHistory,
  setSchedules,
  setSelectedSchedule,
} = reportSlice.actions;

export default reportSlice.reducer;
