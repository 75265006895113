import { useValidateTeamMemberMutation, useGetOwnerDataQuery, usePostTeamMutation } from '@apis';
import { RoutePath, TeamType } from '@constants';
import { useAppDispatch, useAppSelector, useForm } from '@hooks';
import { TeamAddForm } from '@interfaces';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { setChangeActiveTeamStatus, setOwnersData } from '@slices';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

type Props = {
  isDialogOpen: boolean;
  closeDialog: () => void;
  teamTypes?: string[];
};

const formInitialValues = {
  email: '',
  givenName: '',
  familyName: '',
  teamName: '',
  teamType: '',
  title: '',
} as TeamAddForm;

const AddTeamForm = ({ isDialogOpen, closeDialog, teamTypes }: Props) => {
  const { t } = useTranslation('pano');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { ownerData } = useAppSelector((x) => x.team);
  const { activeTeam, userRights } = useAppSelector((x) => x.app);
  const titleOptions = [
    t('TitleLabelDr'),
    t('TitleLabelMr'),
    t('TitleLabelMiss'),
    t('TitleLabelMrs'),
    t('TitleLabelMs'),
    t('TitleLabelMx'),
    t('TitleLabelProf'),
    t('TitleLabelSir'),
    t('TitleLabelDame'),
  ];
  const creatableTeamTypes = teamTypes ? teamTypes : userRights.isSysAdmin ? [TeamType.Customer, TeamType.Distributor, TeamType.Reseller] : ([] as string[]);
  const [validateMember, { isLoading: isValidateOwnerLoading }] = useValidateTeamMemberMutation();
  const [isOwnerValidated, setIsOwnerValidated] = useState<boolean>(false);
  const [addNewTeam, { isLoading }] = usePostTeamMutation();
  const parms = useParams();
  const teamId = parms?.teamId ?? undefined;

  const handleTeamSubmit = (): void => {
    addNewTeam({ newTeam: formData, teamId: teamId ? parseInt(teamId) : userRights.sysAdminTeams[0] })
      .unwrap()
      .then((data) => {
        if (!teamTypes) navigate(RoutePath.TeamPath.replace(':teamId', `${data}`).replace(':tabName', ''), { state: { addedTeamId: data } });
        else {
          dispatch(setChangeActiveTeamStatus(false));
          navigate(RoutePath.TeamPath.replace(':teamId', `${data}`).replace(':tabName', ''), { state: { addedTeamId: data } });
        }
        onCancelClick();
      })
      .catch((err) => {
        console.debug('Failed while attempting to create a new team', err);
        return;
      });
  };

  const { handleSubmit, handleChange, formData, setFormData, errors, resetForm } = useForm<TeamAddForm>({
    validations: {
      email: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
        pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: t('invalidEmail') },
      },
      givenName: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
      },
      familyName: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
      },
      teamName: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
      },
      teamType: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
      },
    },
    onSubmit: handleTeamSubmit,
    initialValues: { ...formInitialValues },
  });

  useGetOwnerDataQuery({ teamId: activeTeam?.id ?? 1, searchTerm: formData.email }, { refetchOnMountOrArgChange: true, skip: !formData.email });

  const onCancelClick = () => {
    closeDialog();
    resetForm({ ...formInitialValues });
  };

  useEffect(() => {
    dispatch(setOwnersData([]));
  }, []);

  const onListSelection = (value: string) => {
    setFormData({ ...formData, email: value });
    if (value) {
      validateMember({ email: value, teamId: activeTeam?.id ?? 1 })
        .unwrap()
        .then((data) => {
          setFormData({
            ...formData,
            email: data.email,
            givenName: data.givenName,
            familyName: data.familyName,
            title: data.title,
          });
          setIsOwnerValidated(true);
        })
        .catch((err) => {
          console.debug('Failed while attempting to validate a member', err);
          return;
        });
    }
  };

  const handleOnEmailChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    handleChange(e);
    if (isOwnerValidated) {
      if (e.currentTarget.value && ownerData.length === 1 && e.currentTarget.value.toLocaleLowerCase().trim() !== ownerData[0].toLocaleLowerCase())
        setIsOwnerValidated(false);
    }
  };

  const handleOnEmailBlur = (): void => {
    if (ownerData.length === 1 && formData.email.toLocaleLowerCase().trim() === ownerData[0].toLocaleLowerCase()) onListSelection(formData.email);
  };

  return (
    <Dialog open={isDialogOpen} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth={true}>
      <DialogTitle id="dialog-title">{t('addTeamFormPageTitle')}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Paper className="w-full p-5 space-y-4 mb-4">
            <Typography variant="subtitle1" gutterBottom>
              {t('addTeamFormPageTeamDetails')}
            </Typography>

            <TextField
              className="margin-top-bot"
              name="teamName"
              autoComplete="nope"
              label={t('name')}
              placeholder={t('name') as string}
              fullWidth
              value={formData.teamName}
              onChange={handleChange}
              variant="outlined"
              {...(errors?.teamName && { error: true, helperText: errors.teamName })}
            />

            <TextField
              id="addTeam-teamType"
              className="margin-top-bot"
              select
              name="teamType"
              label={t('teamType')}
              placeholder={t('teamType') as string}
              fullWidth
              variant="outlined"
              value={formData.teamType || ''}
              onChange={handleChange}
              disabled={!creatableTeamTypes.length}
              {...(errors?.teamType && { error: true, helperText: errors.teamType })}>
              {creatableTeamTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {t(type)}
                </MenuItem>
              ))}
            </TextField>
          </Paper>

          <Paper className="w-full p-5 space-y-4">
            <Typography variant="subtitle1" gutterBottom>
              {t('addTeamFormPageOwnerDetails')}
            </Typography>

            {userRights.isSysAdmin ? (
              <Autocomplete
                options={ownerData}
                getOptionLabel={(option) => option}
                onChange={(_event, value) => onListSelection(value ?? '')}
                disabled={isValidateOwnerLoading}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="email"
                    label={t('emailAddress')}
                    value={formData.email}
                    placeholder={t('emailAddress') as string}
                    variant="outlined"
                    autoComplete="emailaddress"
                    fullWidth={true}
                    onBlur={handleOnEmailBlur}
                    onChange={handleOnEmailChange}
                    {...(errors?.email && { error: true, helperText: errors.email })}
                  />
                )}
              />
            ) : (
              <TextField
                disabled={isValidateOwnerLoading}
                name="email"
                label={t('emailAddress')}
                value={formData.email}
                placeholder={t('emailAddress') as string}
                variant="outlined"
                autoComplete="emailaddress"
                fullWidth={true}
                onBlur={handleOnEmailBlur}
                onChange={handleOnEmailChange}
                {...(errors?.email && { error: true, helperText: errors.email })}
              />
            )}

            <Autocomplete
              freeSolo
              autoSelect
              options={titleOptions}
              disabled={isValidateOwnerLoading || isOwnerValidated}
              onChange={(event, newValue) => handleChange(event, false, newValue as string, 'title')}
              value={formData.title}
              renderInput={(params) => <TextField {...params} label={t('title')} variant="outlined" fullWidth />}
            />
            <TextField
              name="givenName"
              autoComplete="givenName"
              label={t('givenName')}
              placeholder={t('givenName') as string}
              fullWidth
              value={formData.givenName}
              onChange={handleChange}
              disabled={isValidateOwnerLoading || isOwnerValidated}
              variant="outlined"
              {...(errors?.givenName && { error: true, helperText: errors.givenName })}
            />

            <TextField
              name="familyName"
              autoComplete="familyName"
              label={t('familyName')}
              placeholder={t('familyName') as string}
              fullWidth
              value={formData.familyName}
              onChange={handleChange}
              disabled={isValidateOwnerLoading || isOwnerValidated}
              variant="outlined"
              {...(errors?.familyName && { error: true, helperText: errors.familyName })}
            />
          </Paper>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="secondary" onClick={onCancelClick} disabled={isLoading}>
            {t('cancelButtonText')}
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
            {isLoading && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
            {t('submitButtonText')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddTeamForm;
