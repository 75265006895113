import { TeamConfigurationLookupItem } from '@interfaces';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { Icons, TableIcons } from '../shared';
import MaterialTable, { MTableAction } from '@material-table/core';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { useAppDispatch } from '@hooks';
import { useTranslation } from 'react-i18next';
import { updateTeamConfigurationLookups } from '@slices';

type Props = {
  data: TeamConfigurationLookupItem;
  isLoading: boolean;
  validation: {
    validationErrorCheck: boolean;
    validationError: string;
    validationText: string;
  };
  setValidation: React.Dispatch<
    React.SetStateAction<{
      validationErrorCheck: boolean;
      validationError: string;
      validationText: string;
    }>
  >;
};

const LookupConfigMediaEditor = ({ data, isLoading, validation, setValidation }: Props) => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const tableRef = createRef() as React.RefObject<any> | React.MutableRefObject<undefined> | undefined;

  const [defaultValue, setDefaultValue] = useState<string>(data.defaultValue);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [tableData, setTableData] = useState<{ id: string; value: string; tableData?: { isDeletable?: boolean } }[]>(
    data.values.map((value) => {
      return {
        id: value,
        value: value,
        tableData: { isDeletable: value !== data.defaultValue },
      };
    }),
  );

  const handleIsStickyChange = (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    dispatch(updateTeamConfigurationLookups({ ...data, isSticky: checked }));
  };

  useEffect(() => {
    dispatch(updateTeamConfigurationLookups({ ...data, defaultValue: defaultValue }));
  }, [defaultValue]);

  useEffect(() => {
    setDefaultValue(data.defaultValue);
    setTableData([
      ...data.values.map((value) => {
        return {
          id: value,
          value: value,
          tableData: { isDeletable: value !== data.defaultValue },
        };
      }),
    ]);
  }, [data]);

  const tableComponents = {
    EditField: (props: any) => {
      return (
        <TextField
          variant="outlined"
          style={props.columnDef.type === 'numeric' ? { float: 'right' } : {}}
          type={props.columnDef.type === 'numeric' ? 'number' : 'text'}
          placeholder={props.columnDef.title}
          value={props.value === undefined ? '' : props.value}
          onChange={(event) => props.onChange(event.target.value)}
          error={validation.validationError == props.columnDef.field}
          helperText={validation.validationError == props.columnDef.field ? validation.validationText : ''}
        />
      );
    },
    Action: (props: any) => {
      if (props.action.icon === 'setDefault') {
        return props.data.value !== defaultValue ? (
          <Tooltip title={t('setAsDefaultValue')}>
            <IconButton className="!w-10 flex justify-center" onClick={() => setDefaultValue(props.data.value)}>
              <Icons.SetDefault />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        );
      }
      return <MTableAction {...props} />;
    },
  };
  const isHeaderAction = useRef(false);
  return (
    <Paper className="w-full pt-4 space-y-4">
      <Grid item xs={12} sm={12} md={12} lg={12} className="px-3 flex justify-between items-center space-x-2">
        <Typography variant="subtitle1">
          {t('defaultValue')} : <span className="font-bold">{defaultValue ?? ''}</span>
        </Typography>
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox checked={data.isSticky} onChange={(e, checked) => handleIsStickyChange(e, checked)} color="primary" name="isSticky" />}
            label={t('isSticky')}
          />
        </FormGroup>
      </Grid>

      {selectedRows.length > 0 && (
        <div className="px-3 flex justify-end">
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={() => {
              const selectedValues = selectedRows.map((row) => row.value);
              const updatedValues = data.values.filter((value) => !selectedValues.includes(value));
              dispatch(
                updateTeamConfigurationLookups({
                  ...data,
                  values: updatedValues,
                }),
              );
              setSelectedRows([]);
            }}>
            {t('deleteSelected')} ({selectedRows.length})
          </Button>
        </div>
      )}

      <MaterialTable
        icons={TableIcons}
        tableRef={tableRef}
        isLoading={isLoading}
        options={{
          sorting: false,
          draggable: false,
          pageSize: 5,
          pageSizeOptions: [5, 10],
          selection: true,
          actionsColumnIndex: 2,
          selectionProps: (rowData) => ({
            disabled: rowData.value === defaultValue,
          }),
        }}
        onSelectionChange={(rows, rowData) => {
          const selectableRows = tableData.filter((row) => row.value !== defaultValue);

          if (!rowData) {
            if (isHeaderAction.current) {
              isHeaderAction.current = false;
              return;
            }

            const shouldSelect = selectedRows.length !== selectableRows.length;
            isHeaderAction.current = true;

            if (shouldSelect) {
              setSelectedRows(selectableRows);
              tableRef?.current?.onAllSelected(true);
            } else {
              setSelectedRows([]);
              tableRef?.current?.onAllSelected(false);
            }
          } else {
            const validSelections = rows.filter((row) => row.value !== defaultValue);
            setSelectedRows(validSelections);
            if (validSelections.length === 0) {
              tableRef?.current?.onAllSelected(false);
            }
          }
        }}
        columns={[{ title: 'Value', field: 'value' }]}
        data={tableData}
        title={data.name}
        components={tableComponents}
        editable={{
          isDeleteHidden: (rowData) => !rowData.tableData?.isDeletable,
          onRowAdd: (newData) =>
            new Promise<void>((resolve, reject) => {
              if (!newData.value) {
                setValidation({
                  validationError: 'value',
                  validationText: t('valueEmptyError'),
                  validationErrorCheck: true,
                });

                reject();
                return;
              }

              dispatch(updateTeamConfigurationLookups({ ...data, values: [...data.values, newData.value] }));

              setValidation({
                validationError: '',
                validationText: '',
                validationErrorCheck: false,
              });

              resolve();
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise<void>((resolve, reject) => {
              if (!newData.value) {
                setValidation({
                  validationError: 'value',
                  validationText: t('valueEmptyError'),
                  validationErrorCheck: true,
                });

                reject();
                return;
              }

              const dataUpdate = [...data.values];
              dataUpdate[dataUpdate.indexOf(oldData?.value as string)] = newData.value;

              if (oldData?.value === defaultValue) {
                dispatch(
                  updateTeamConfigurationLookups({
                    ...data,
                    values: [...dataUpdate],
                  }),
                );
                setDefaultValue(newData.value);
              } else {
                dispatch(
                  updateTeamConfigurationLookups({
                    ...data,
                    values: [...dataUpdate],
                  }),
                );
              }

              setValidation({
                validationError: '',
                validationText: '',
                validationErrorCheck: false,
              });

              resolve();
            }),
          onRowDelete: (oldData) =>
            new Promise<void>((resolve) => {
              const dataDelete = [...data.values];
              const index = dataDelete.indexOf(oldData?.value as string);
              dataDelete.splice(index, 1);
              dispatch(
                updateTeamConfigurationLookups({
                  ...data,
                  values: [...dataDelete],
                }),
              );

              resolve();
            }),
        }}
        actions={[
          {
            icon: 'setDefault',
            tooltip: t('setAsDefaultValue') as string,
            position: 'row',
            //@ts-ignore
            onClick: (_event, rowData) => setDefaultValue(rowData.value),
          },
        ]}
      />
    </Paper>
  );
};

export default LookupConfigMediaEditor;
