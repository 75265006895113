import React, { useMemo } from 'react';
import { Box, Typography, Paper, TextField } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ReportColumnDto, QueryType } from '@interfaces';
import { useTranslation } from 'react-i18next';

interface ColumnNamingTabProps {
  selectedColumns: ReportColumnDto[];
  queryType: QueryType;
  onColumnsReorder: (reorderedColumns: ReportColumnDto[]) => void;
  onColumnAliasChange: (tableName: string, columnName: string, newAlias: string) => void;
}

const ColumnNamingTab: React.FC<ColumnNamingTabProps> = ({ selectedColumns, queryType, onColumnsReorder, onColumnAliasChange }) => {
  const { t } = useTranslation('pano');

  const isAnySameName = useMemo(() => {
    return selectedColumns.some((col) =>
      selectedColumns.some(
        (otherCol) => col !== otherCol && (col.alias || col.columnName).toLowerCase() === (otherCol.alias || otherCol.columnName).toLowerCase(),
      ),
    );
  }, [selectedColumns]);

  const formatAggregationFunction = (func: string | null) => {
    if (!func) return '';
    return func.charAt(0).toUpperCase() + func.slice(1).toLowerCase();
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(selectedColumns);
    const [removed] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, removed);

    onColumnsReorder(items);
  };

  if (selectedColumns.length === 0) {
    return (
      <Paper variant="outlined" className="p-8 text-center">
        <Typography color="textSecondary" className="mb-2">
          {t('noColumnsSelected')}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {t('selectColumnsPrompt')}
        </Typography>
      </Paper>
    );
  }

  return (
    <Box>
      <Box className="flex justify-between items-center mb-4">
        <Typography variant="h6">{t('columnDisplayNames')}</Typography>
        {isAnySameName && (
          <Typography color="error" variant="caption">
            {t('duplicateNameWarning')}
          </Typography>
        )}
      </Box>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="columns">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-2">
              {selectedColumns.map((col, index) => {
                const isDuplicate = selectedColumns.some(
                  (otherCol) => col !== otherCol && (col.alias || col.columnName).toLowerCase() === (otherCol.alias || otherCol.columnName).toLowerCase(),
                );

                const aggregation = queryType?.columnAggregations?.find((agg) => agg.tableName === col.tableName && agg.columnName === col.columnName);

                return (
                  <Draggable key={`${col.tableName}-${col.columnName}`} draggableId={`${col.tableName}-${col.columnName}`} index={index}>
                    {(provided, snapshot) => (
                      <Paper
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`p-4 ${snapshot.isDragging ? 'bg-gray-100' : ''}`}
                        elevation={snapshot.isDragging ? 3 : 1}>
                        <Box className="flex items-center gap-4">
                          <div {...provided.dragHandleProps}>
                            <DragIndicatorIcon color="action" />
                          </div>

                          <Box className="flex-1">
                            <Box className="flex items-center gap-2 mb-2">
                              <Typography variant="subtitle2" color="textSecondary">
                                {`${col.tableName}.${col.columnName}`}
                              </Typography>
                              {aggregation && (
                                <Typography color="primary" component="span">
                                  ({formatAggregationFunction(aggregation.aggregateFunction)})
                                </Typography>
                              )}
                            </Box>

                            <TextField
                              size="small"
                              label={t('displayName')}
                              fullWidth
                              value={col.alias || ''}
                              error={isDuplicate}
                              helperText={isDuplicate ? t('duplicateDisplayName') : ''}
                              onChange={(e) => {
                                const newValue = e.target.value.trim();
                                onColumnAliasChange(col.tableName, col.columnName, newValue);
                              }}
                            />
                          </Box>
                        </Box>
                      </Paper>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default ColumnNamingTab;
