import { teamApi } from '@apis';
import { TeamDetailedItem, TeamEventItem, TeamMemberItem, TeamNameDto, TeamResults, TeamRoleItem, TeamRoleTableData, TeamServiceItem } from '@interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type TeamState = {
  teams: TeamDetailedItem[];
  teamsSearch: TeamDetailedItem[];
  teamsSearchResults: TeamResults | null;
  teamsResults: TeamResults | null;
  pageCount: number;
  pageSize: number;
  totalResults: number;
  team: TeamDetailedItem | null;
  teamServices: TeamServiceItem[];
  teamMembers: TeamMemberItem[];
  ownerData: string[];
  teamRoles: TeamRoleItem[];
  teamRolesTableData: TeamRoleTableData[];
  teamEvents: TeamEventItem[];
  addedTeamId: number;
  teamNames: TeamNameDto[];
};

const initialState: TeamState = {
  teams: [],
  teamsSearch: [],
  teamsSearchResults: null,
  teamsResults: null,
  pageCount: 0,
  pageSize: 10,
  totalResults: 0,
  team: null,
  teamServices: [],
  ownerData: [],
  teamMembers: [],
  teamRoles: [],
  teamRolesTableData: [],
  teamEvents: [],
  addedTeamId: 0,
  teamNames: [],
};

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(teamApi.endpoints.getTeams.matchFulfilled, (state, { payload, meta }) => {
      const pageNo = meta.arg.originalArgs.pageNo || 0;
      state.teams = payload.results;

      if (pageNo === 0) {
        state.teamsResults = payload;
      } else {
        state.teamsResults = {
          ...payload,
          results: [...(state.teamsResults?.results || []), ...payload.results],
        };
      }

      state.totalResults = payload.totalResults;
      state.pageSize = payload.pageSize;
      state.pageCount = payload.pageCount;
    });

    builder.addMatcher(teamApi.endpoints.getOwnerTeams.matchFulfilled, (state, { payload, meta }) => {
      const pageNo = meta.arg.originalArgs.pageNo || 0;
      state.teams = payload.results;

      if (pageNo === 0) {
        state.teamsResults = payload;
      } else {
        state.teamsResults = {
          ...payload,
          results: [...(state.teamsResults?.results || []), ...payload.results],
        };
      }

      state.totalResults = payload.totalResults;
      state.pageSize = payload.pageSize;
      state.pageCount = payload.pageCount;
    });
    builder.addMatcher(teamApi.endpoints.getTeamsSearch.matchFulfilled, (state, { payload, meta }) => {
      const pageNo = meta.arg.originalArgs.pageNo || 0;
      if (pageNo === 0) {
        state.teamsSearchResults = payload;
        state.teamsSearch = payload.results;
      } else {
        state.teamsSearchResults = {
          ...payload,
          results: [...(state.teamsSearch || []), ...payload.results],
        };
        state.teamsSearch = [...(state.teamsSearch || []), ...payload.results];
      }
    });
    builder.addMatcher(teamApi.endpoints.getTeamServices.matchFulfilled, (state, { payload }) => {
      state.teamServices = payload;
    });
    builder.addMatcher(teamApi.endpoints.getTeam.matchFulfilled, (state, { payload }) => {
      state.team = payload;
    });
    builder.addMatcher(teamApi.endpoints.getTeamNames.matchFulfilled, (state, { payload }) => {
      state.teamNames = payload;
    });
    builder.addMatcher(teamApi.endpoints.getOwnerData.matchFulfilled, (state, { payload }) => {
      state.ownerData = payload;
    });
    builder.addMatcher(teamApi.endpoints.postTeam.matchFulfilled, (state, { payload }) => {
      state.addedTeamId = payload;
    });
    builder.addMatcher(teamApi.endpoints.getTeamRoles.matchFulfilled, (state, { payload }) => {
      state.teamRoles = payload;
      state.teamRolesTableData = payload.map((role) => {
        return {
          id: role.id,
          active: role.active,
          name: role.name,
          members: role.members.length,
          logo: role.serviceClient.logoUri,
          serviceId: role.serviceClient.id,
        };
      });
    });
    builder.addMatcher(teamApi.endpoints.getTeamMembersSearch.matchFulfilled, (state, { payload }) => {
      state.teamMembers = payload.map((value: TeamMemberItem) => {
        value.name = value.givenName + ' ' + value.familyName;
        return value;
      });
    });
    builder.addMatcher(teamApi.endpoints.getTeamEvents.matchFulfilled, (state, { payload }) => {
      state.teamEvents = payload.map((value: TeamEventItem) => {
        value.userName = value.givenName + ' ' + value.familyName;
        return value;
      });
    });
    builder.addMatcher(teamApi.endpoints.getTeamMembers.matchFulfilled, (state, { payload }) => {
      state.teamMembers = payload.map((value: TeamMemberItem) => {
        value.name = value.givenName + ' ' + value.familyName;
        return value;
      });
    });
  },
  reducers: {
    setOwnersData: (state, action: PayloadAction<string[]>) => {
      state.ownerData = action.payload;
    },
    addTeamName: (state, action: PayloadAction<TeamNameDto>) => {
      if (!state.teamNames.find((x) => x.id === action.payload.id)) state.teamNames.push(action.payload);
    },
    removeTeamNameById: (state, action: PayloadAction<number>) => {
      state.teamNames = [...state.teamNames.filter((x) => x.id !== action.payload)];
    },
    clearTeamSearch: (state) => {
      state.teamsSearch = [];
      state.teamsSearchResults = null;
    },
    clearTeams: (state) => {
      state.teamsResults = null;
    },
  },
});

export const { setOwnersData, addTeamName, removeTeamNameById, clearTeamSearch, clearTeams } = teamSlice.actions;
