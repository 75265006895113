import { useChangeUserPasswordMutation } from '@apis';
import { faUserShield } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useForm } from '@hooks';
import { ChangePasswordForm } from '@interfaces';
import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { openSnackbar, setHelmet } from '@slices';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'oidc-react';

const formInitialValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
} as ChangePasswordForm;

const ChangePassword = (): JSX.Element => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const { userData } = useAuth();
  const [changePassword, { isLoading: isSubmitting }] = useChangeUserPasswordMutation();
  const matchPassword = (value: string, matchValue?: string): boolean => {
    return value === matchValue;
  };

  const handlePasswordChangeSubmit = (): void => {
    changePassword({ userId: parseInt(userData?.profile.sub ?? '0'), data: formData })
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('passwordChangedSuccess'), severity: 'success', display: true }));
        resetForm();
      })
      .catch((err) => {
        console.debug('Failed while attempting to change password', err);
        return;
      });
  };

  const { handleSubmit, handleChange, formData, setFormData, errors, resetForm } = useForm<ChangePasswordForm>({
    validations: {
      currentPassword: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
      },
      newPassword: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
        custom: {
          isValid: matchPassword,
          matchKey: 'confirmPassword',
          message: t('passwordsDoNotMatch'),
          isReverseMatch: true,
        },
      },
      confirmPassword: {
        custom: {
          isValid: matchPassword,
          matchKey: 'newPassword',
          message: t('passwordsDoNotMatch'),
        },
      },
    },
    onSubmit: handlePasswordChangeSubmit,
    initialValues: { ...formInitialValues },
  });

  useEffect(() => {
    dispatch(setHelmet({ title: t('htmlTitleChangePassword') }));
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={0} sm={3} md={3} lg={3} className="flex justify-center items-center" />
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          sx={(theme) => ({
            backgroundColor: theme.custom.appBarBackground,
          })}
          className="flex justify-center items-center">
          <FontAwesomeIcon icon={faUserShield} className="my-0 mx-auto w-[72px] h-[72px] py-12" color="white" />
        </Grid>
        <Grid item xs={0} sm={3} md={3} lg={3} className="flex justify-center items-center" />

        <Grid item xs={0} sm={3} md={3} lg={3} className="flex justify-center items-center" />
        <Grid item xs={12} sm={6} md={6} lg={6} className="flex justify-center items-center">
          <form onSubmit={handleSubmit} className="w-full space-y-4">
            <Typography variant="h4" className="text-center">
              {t('changePasswordHeader')}
            </Typography>
            <TextField
              name="currentPassword"
              type="password"
              autoComplete="off"
              label={t('currentPassword')}
              placeholder={t('currentPassword') as string}
              fullWidth
              value={formData.currentPassword}
              onChange={handleChange}
              variant="outlined"
              {...(errors?.currentPassword && { error: true, helperText: errors?.currentPassword })}
            />

            <TextField
              name="newPassword"
              type="password"
              autoComplete="new-password"
              label={t('newPassword')}
              placeholder={t('newPassword') as string}
              fullWidth
              value={formData.newPassword}
              onChange={handleChange}
              onFocus={() => setReadOnly(false)}
              InputProps={{
                readOnly: readOnly,
              }}
              variant="outlined"
              {...(errors?.newPassword && { error: true, helperText: errors?.newPassword })}
            />

            <TextField
              className="margin-top-bot"
              name="confirmPassword"
              type="password"
              autoComplete="new-password"
              label={t('confirmPassword')}
              placeholder={t('confirmPassword') as string}
              fullWidth
              value={formData.confirmPassword}
              onChange={handleChange}
              variant="outlined"
              onFocus={() => setReadOnly(false)}
              InputProps={{
                readOnly: readOnly,
              }}
              {...(errors?.confirmPassword && { error: true, helperText: errors?.confirmPassword })}
            />

            <div className="justify-end pt-5 flex">
              <Button className="!mr-5" variant="contained" color="secondary" onClick={() => resetForm(formInitialValues)} disabled={isSubmitting}>
                {t('clearButtonText')}
              </Button>
              <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
                {t('submitButtonText')}
              </Button>
            </div>
          </form>
        </Grid>
        <Grid item xs={0} sm={3} md={3} lg={3} className="flex justify-center items-center" />
      </Grid>
    </React.Fragment>
  );
};

export default ChangePassword;
