import React, { useRef } from 'react';
import MaterialTable from '@material-table/core';
import { Template } from '@interfaces';
import { useTranslation } from 'react-i18next';
import { Icons, TemplateSchedules } from '@components';
import { Box } from '@mui/material';

interface TemplateManagementTableProps {
  templates: Template[];
  isLoading: boolean;
  onCreateTemplate: () => void;
  onEditTemplate: (template: Template) => void;
  onRunTemplate: (templateId: number) => void;
  onDeleteTemplate: (templateId: number) => void;
  schema: any;
  availableEmails: string[];
}

const TemplateManagementTable: React.FC<TemplateManagementTableProps> = ({
  templates,
  isLoading,
  onCreateTemplate,
  onEditTemplate,
  onRunTemplate,
  onDeleteTemplate,
  schema,
  availableEmails,
}) => {
  const { t } = useTranslation('pano');
  const tableRef = useRef(null);

  const actions = [
    {
      icon: Icons.Add,
      tooltip: t('createNewTemplate') || 'Create New Template',
      isFreeAction: true,
      onClick: () => onCreateTemplate(),
    },
    {
      icon: Icons.Edit,
      tooltip: t('editTemplate') || 'Edit Template',
      onClick: (event: any, rowData: Template | Template[]) => {
        if (!Array.isArray(rowData)) {
          onEditTemplate(rowData);
        }
      },
    },
    {
      icon: Icons.Run,
      tooltip: t('runTemplate') || 'Run Template',
      onClick: (event: any, rowData: Template | Template[]) => {
        if (!Array.isArray(rowData)) {
          onRunTemplate(rowData.id);
        }
      },
    },
    {
      icon: Icons.DeleteForeverIcon,
      tooltip: t('deleteTemplate') || 'Delete Template',
      onClick: (event: any, rowData: Template | Template[]) => {
        if (!Array.isArray(rowData)) {
          onDeleteTemplate(rowData.id);
        }
      },
    },
  ];

  return (
    <MaterialTable
      title={t('templates')}
      columns={[
        { title: t('id'), field: 'id' },
        { title: t('name'), field: 'name' },
        { title: t('version'), field: 'version' },
      ]}
      data={templates}
      actions={actions}
      isLoading={isLoading}
      tableRef={tableRef}
      detailPanel={[
        {
          tooltip: t('showSchedules') as string,
          render: (rowData) => {
            const template = rowData.rowData as Template;
            const availableColumns = template.query.tables.flatMap((table) =>
              table.columns
                .filter((col) => col.isFilterable)
                .map((col) => ({
                  tableName: table.tableName,
                  columnName: col.columnName,
                  dataType:
                    schema?.find((s: any) => s.tableName === table.tableName)?.columns.find((sc: any) => sc.columnName === col.columnName)?.dataType ||
                    'unknown',
                  filterType: col.filterType,
                  alias: col.alias,
                })),
            );

            return (
              <Box className="p-4">
                <TemplateSchedules templateId={template.id} availableColumns={availableColumns} availableEmails={availableEmails} />
              </Box>
            );
          },
        },
      ]}
      options={{
        actionsColumnIndex: -1,
        detailPanelType: 'single',
      }}
    />
  );
};

export default TemplateManagementTable;
