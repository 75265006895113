import { useForm } from '@hooks';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  isDialogOpen: boolean;
  closeDialog: () => void;
  title: string;
  text: string;
  action: (reason: string) => void;
};

const formInitialValues = {
  reason: '',
};

const ReasonDialog = ({ isDialogOpen, closeDialog, title, text, action }: Props): JSX.Element => {
  const { t } = useTranslation('pano');

  const handleDialogSubmit = (): void => {
    action(formData.reason);
    handleDialogCancel();
  };
  const { handleSubmit, handleChange, setFormData, formData, errors, resetForm } = useForm<{ reason: string }>({
    validations: {
      reason: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
      },
    },
    onSubmit: handleDialogSubmit,
    initialValues: { ...formInitialValues },
  });
  const handleDialogCancel = () => {
    closeDialog();
    resetForm({ reason: '' });
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleDialogCancel();
        }
      }}
      aria-labelledby="form-reason-dialog"
      fullWidth
      disableEscapeKeyDown>
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText className="pb-4">{text}</DialogContentText>

          <TextField
            required
            name="reason"
            label={t('reason')}
            value={formData.reason}
            inputProps={{ maxLength: 255 }}
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
            {...(errors?.reason && { error: true, helperText: errors?.reason })}
          />
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleDialogCancel}>
            {t('cancelButtonText')}
          </Button>

          <Button variant="contained" color="primary" type="submit">
            {t('submitButtonText')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ReasonDialog;
