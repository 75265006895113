import { useGetOwnerTeamsQuery, useGetTeamWorkFlowsQuery, useGetTeamsQuery, useGetWorkFlowTemplatesQuery, usePostTeamWorkflowMutation } from '@apis';
import { AssignWorkflowModal, TeamSelector, TeamWorkflowsTable, WorkflowTemplatesTable } from '@components';
import { RoutePath } from '@constants';
import { useAppSelector } from '@hooks';
import { TeamDetailedItem } from '@interfaces';
import { Grid, Paper } from '@mui/material';
import { setHelmet, setBreadcrumbs, setActiveTeam, openSnackbar, clearTeams } from '@slices';
import { useAuth } from 'oidc-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useDebounce } from 'use-debounce';

const WorkflowTemplates = (): JSX.Element => {
  const { t } = useTranslation('pano');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { workflowTemplates, workFlows } = useAppSelector((x) => x.mahon);
  const { teamsResults } = useAppSelector((x) => x.team);
  const { userData } = useAuth();
  const { userRights, activeTeam } = useAppSelector((state) => state.app);
  const [searchTeam, setSearchTeam] = useState<string>('');
  const [debouncedSearchTeam] = useDebounce(searchTeam, 200);
  const { teamMemberships } = useAppSelector((x) => x.user);
  const [selectedTeam, setSelectedTeam] = useState<TeamDetailedItem | null>(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number>();
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [postWorkflow] = usePostTeamWorkflowMutation();
  const [pageNo, setPageNo] = useState(0);

  const hasMore = teamsResults ? pageNo < teamsResults.pageCount - 1 : false;

  const handleLoadMore = () => {
    if (!isLoading && !isOwnerTeamsLoading && hasMore) {
      setPageNo((prev) => prev + 1);
    }
  };

  const { isLoading } = useGetTeamsQuery(
    { pageNo, pageSize: 20, searchTerm: debouncedSearchTeam, parentTeamId: userRights.sysAdminTeams[0] },
    {
      refetchOnMountOrArgChange: true,
      skip: !(userData && userRights.isSysAdmin),
    },
  );

  const { isLoading: isOwnerTeamsLoading } = useGetOwnerTeamsQuery(
    { pageNo, pageSize: 20, searchTerm: debouncedSearchTeam, parentTeamId: userRights.sysAdminTeams[0] },
    {
      refetchOnMountOrArgChange: true,
      skip: !(userData && !userRights.isSysAdmin),
    },
  );

  const {
    isLoading: isGettingWorkerFlows,
    isError,
    refetch,
  } = useGetWorkFlowTemplatesQuery(activeTeam?.id ?? 1, {
    refetchOnMountOrArgChange: true,
    skip: !activeTeam,
  });

  const { isLoading: isGettingAllWorkFlows, refetch: allWorkflowRefetch } = useGetTeamWorkFlowsQuery(selectedTeam?.id ?? 1, {
    refetchOnMountOrArgChange: true,
    skip: !selectedTeam,
  });

  useEffect(() => {
    dispatch(setHelmet({ title: t('workflowTemplatesTitle') }));
    dispatch(setBreadcrumbs([{ text: t('workflowTemplatesTitle') }]));
    dispatch(clearTeams());
  }, []);

  useEffect(() => {
    setPageNo(0);
    dispatch(clearTeams());
  }, [debouncedSearchTeam]);

  useEffect(() => {
    if (isError) {
      setTimeout(() => navigate(-1), 2500);
    }
  }, [isError]);

  useEffect(() => {
    if (!userRights.isSysAdmin && teamMemberships.length) {
      if (selectedTeam !== undefined) {
        if (selectedTeam && selectedTeam.id !== activeTeam?.id)
          dispatch(setActiveTeam({ id: selectedTeam.id, name: selectedTeam?.name, isSysAdmin: selectedTeam?.isSysAdmin }));
      } else {
        //@ts-ignore
        setSelectedTeam({ id: teamMemberships[0].id, name: teamMemberships[0].name });
      }
    }
  }, [selectedTeam, teamMemberships]);

  const handleRefetch = (): void => {
    refetch();
  };

  const handleAllWorkflowRefetch = (): void => {
    allWorkflowRefetch();
  };

  const handleAssignAction = (templateId: number): void => {
    setSelectedTemplateId(templateId);
    setIsAssignModalOpen(true);
  };

  const handleCreateTeamWorkflow = (workflowName: string, navigateAfter: boolean): void => {
    postWorkflow({
      data: {
        workerPoolGridColumnSettings: [],
        workflowStepFilters: [],
        workflowTemplateId: selectedTemplateId as number,
        targetTeamId: selectedTeam?.id as number,
        name: workflowName,
      },
      teamId: selectedTeam?.id as number,
    })
      .unwrap()
      .then((data) => {
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
        handleAllWorkflowRefetch();
        setIsAssignModalOpen(false);
        if (navigateAfter) {
          navigate(RoutePath.TeamWorkFlowPath.replace(':teamId', selectedTeam ? selectedTeam.id.toString() : '').replace(':workflowId', data.id.toString()));
        }
      })
      .catch((err) => {
        console.debug('Failed while attempting to add a new member', err);
        return;
      });
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <WorkflowTemplatesTable
          isLoading={isGettingWorkerFlows}
          workflowTemplates={workflowTemplates}
          refetch={handleRefetch}
          handleAssignAction={handleAssignAction}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} className="pt-5">
        <Paper className="p-5 w-full space-y-6">
          <TeamSelector
            teams={teamsResults?.results ?? []}
            isLoading={isLoading || isOwnerTeamsLoading}
            selectValue={selectedTeam}
            setSelectValue={setSelectedTeam}
            setSearchTeam={setSearchTeam}
            onLoadMore={handleLoadMore}
            hasMore={hasMore}
          />
          {selectedTeam && (
            <TeamWorkflowsTable
              isLoading={isGettingAllWorkFlows}
              workflows={workFlows.filter((x) => x.workflowTemplateState !== 0)}
              refetch={handleAllWorkflowRefetch}
              team={selectedTeam}
            />
          )}
        </Paper>
      </Grid>
      <AssignWorkflowModal
        isOpen={isAssignModalOpen}
        teams={teamsResults?.results ?? []}
        onClose={() => setIsAssignModalOpen(false)}
        isLoading={isLoading || isOwnerTeamsLoading}
        selectValue={selectedTeam}
        setSelectValue={setSelectedTeam}
        setSearchTeam={setSearchTeam}
        onSave={handleCreateTeamWorkflow}
        onLoadMore={handleLoadMore}
        hasMore={hasMore}
      />
    </Grid>
  );
};

export default WorkflowTemplates;
