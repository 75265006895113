import React from 'react';
import { Autocomplete, TextField, Chip, Grid, ToggleButton, ToggleButtonGroup, Typography, Box, Tooltip } from '@mui/material';
import { WorkflowTemplateDto } from '@interfaces';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPipe, faCircleAmpersand, faBan } from '@fortawesome/pro-regular-svg-icons';
import { blue, green } from '@mui/material/colors';

type LogicOperator = 'AND' | 'OR' | 'NOT';

interface WorkflowTemplatesFilterProps {
  workflows: WorkflowTemplateDto[];
  selectedLabels: string[];
  logicOperator: LogicOperator;
  onFilterChange: (newLabels: string[]) => void;
  onLogicChange: (newLogic: LogicOperator) => void;
}

const FaToggleButton = styled(ToggleButton)(({ theme }) => ({
  '& .MuiToggleButton-label': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .MuiIcon-root': {
    width: '100%',
    height: '100%',
  },
}));


const WorkflowTemplatesFilter = ({ workflows, selectedLabels, logicOperator, onFilterChange, onLogicChange }: WorkflowTemplatesFilterProps) => {
  const { t } = useTranslation('pano');

  const allLabels = Array.from(
    new Set(
      workflows.reduce((acc: string[], workflow) => {
        return [...acc, ...(workflow.labels || [])];
      }, []),
    ),
  ).sort();

  const handleLogicChange = (_event: React.MouseEvent<HTMLElement>, newLogic: LogicOperator) => {
    if (newLogic !== null) {
      onLogicChange(newLogic);
    }
  };

  const getChipColor = (label: string) => {
    if (logicOperator === 'NOT') return 'error';
    return 'default';
  };

  const getFilterDescription = () => {
    if (selectedLabels.length === 0) return '';

    switch (logicOperator) {
      case 'AND':
        return t('mustHaveAllLabels');
      case 'OR':
        return t('mustHaveAnyLabel');
      case 'NOT':
        return t('mustNotHaveLabels');
      default:
        return '';
    }
  };

  return (
    <Grid container item xs={12} md={6}>     
      <Grid item xs={12} className="!mb-3">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete 
          fullWidth
          multiple
          id="workflow-labels-filter"
          options={allLabels}
          value={selectedLabels}
          onChange={(_event, newValue) => onFilterChange(newValue)}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => {
              const { key, ...tagProps } = getTagProps({ index });
              return <Chip variant="filled" label={option} key={key} color={getChipColor(option)} {...tagProps} />;
            })
          }
          renderInput={(params) => <TextField {...params} variant="outlined" label={t('filterByLabels')} placeholder={t('selectLabelsToFilter') as string} />}
          clearOnBlur
          clearOnEscape
        />
        </Grid>
        <Grid item xs={6}>
          <ToggleButtonGroup value={logicOperator} 
            exclusive 
            onChange={handleLogicChange} 
            aria-label="label logic" 
            size="large"
            sx={{ height: '100%' }} >
          <Tooltip title= {t('mustHaveAllLabels')}> 
            <FaToggleButton value="AND" aria-label="AND logic">
              <FontAwesomeIcon
                icon={faCircleAmpersand} 
                style={{ color: logicOperator === 'AND' ? green[500] : blue[500], margin: 0, padding:0, height:26, width:26, }} 
              />
            </FaToggleButton>
            </Tooltip>
            <Tooltip title= {t('mustHaveAnyLabel')}> 
            <FaToggleButton value="OR" aria-label="OR logic">             
            <FontAwesomeIcon 
                icon={faPipe} 
                style={{ color: logicOperator === 'OR' ? green[500] : blue[500], margin: 0, padding:0, height:26, width:26, }} 
              />
            </FaToggleButton>
            </Tooltip>
            <Tooltip title= {t('mustNotHaveLabels')}> 
            <FaToggleButton value="NOT" aria-label="NOT logic">
            <FontAwesomeIcon 
                icon={faBan} 
                style={{ color: logicOperator === 'NOT' ? green[500] : blue[500], margin: 0, padding:0, height:26, width:26, }} 
              />
            </FaToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
          </Grid>
        </Grid>
      </Grid> 
      <Grid item xs={12} className="!mb-3">               
          <Typography variant="body2" color="textSecondary">
            {getFilterDescription()}
          </Typography>        
      </Grid> 
    </Grid>
  );
};

export default WorkflowTemplatesFilter;
