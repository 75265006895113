import { useDeleteWorkflowTemplateMutation, usePutWorkflowTemplateLabelsMutation } from '@apis';
import { Icons, TableIcons, WorkflowTemplatesFilter } from '@components';
import { RoutePath } from '@constants';
import { useAppSelector } from '@hooks';
import { WorkflowTemplateDto } from '@interfaces';
import MaterialTable, { Action } from '@material-table/core';
import { Grid, Autocomplete, Paper, Chip, TextField } from '@mui/material';
import { openSnackbar, updateTemplateLabels } from '@slices';
import React, { createRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

type Props = {
  isLoading: boolean;
  workflowTemplates: WorkflowTemplateDto[];
  refetch: () => void;
  handleAssignAction: (templateId: number) => void;
};

const WorkflowTemplatesTable = ({ isLoading, workflowTemplates, refetch, handleAssignAction }: Props) => {
  const tableRef = createRef() as React.RefObject<any> | React.MutableRefObject<undefined> | undefined;
  const { t } = useTranslation('pano');
  const { activeTeam } = useAppSelector((x) => x.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [putWorkflowTemplateLabels] = usePutWorkflowTemplateLabelsMutation();
  const [deleteWorkflowTemplate] = useDeleteWorkflowTemplateMutation();

  const [logicOperator, setLogicOperator] = useState<'AND' | 'OR' | 'NOT'>('OR');

  const filteredWorkflows = workflowTemplates.filter((workflow) => {
    if (selectedLabels.length === 0) return true;

    switch (logicOperator) {
      case 'AND':
        return selectedLabels.every((label) => workflow.labels?.includes(label));
      case 'OR':
        return selectedLabels.some((label) => workflow.labels?.includes(label));
      case 'NOT':
        return !selectedLabels.some((label) => workflow.labels?.includes(label));
      default:
        return true;
    }
  });

  const navigateToWorkflow = (id: number): void => {
    navigate(RoutePath.WorkflowTemplatePath.replace(':templateId', id.toString()));
  };

  const handleLabelsChange = (_event: React.ChangeEvent<{}>, value: string[], workflowTemplateId: number) => {
    putWorkflowTemplateLabels({ labels: value, templateId: workflowTemplateId, teamId: activeTeam?.id ?? 1 })
      .unwrap()
      .then(() => {
        dispatch(updateTemplateLabels({ labels: value, templateId: workflowTemplateId }));
      })
      .catch((err) => {
        console.debug('Failed while attempting to update labels', err);
        return;
      });
  };

  const handleDelete = (id: number): void => {
    deleteWorkflowTemplate({ teamId: activeTeam?.id ?? 1, templateId: id })
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('workflowTemplateDeletedSuccessfully'), severity: 'success', display: true }));
        refetch();
      })
      .catch((err) => {
        console.debug('Failed while attempting to delete workflow template', err);
        return;
      });
  };

  const tableActions = useMemo(() => {
    const actions: (Action<WorkflowTemplateDto> | ((rowData: WorkflowTemplateDto) => Action<WorkflowTemplateDto>))[] = [
      {
        icon: Icons.KeyboardArrowRight,
        tooltip: t('navigateToWorkflowTemplate') as string,
        onClick: (_e, data) => {
          if (data && !Array.isArray(data)) {
            navigateToWorkflow(data.id);
          }
        },
      },
      {
        icon: Icons.Add,
        tooltip: t('addWorkflowTemplate') as string,
        isFreeAction: true,
        onClick: () => {
          navigateToWorkflow(0);
        },
      },
      (rowData: WorkflowTemplateDto) => {
        if (rowData.workflowDto.workflowTemplateState === 1) {
          return {
            icon: Icons.AssignIcon,
            tooltip: t('createTeamWorkflowFromTemplate') as string,
            onClick: (_e, data) => {
              if (data && !Array.isArray(data)) {
                handleAssignAction(data.id);
              }
            },
          };
        }
        return {
          icon: Icons.DeleteForeverIcon,
          tooltip: t('deleteWorkflowTemplate') as string,
          onClick: (_e, data) => {
            if (data && !Array.isArray(data)) {
              handleDelete(data.id);
            }
          },
        };
      },
    ];

    return actions;
  }, [t, handleAssignAction]);

  return (
    <>
      <WorkflowTemplatesFilter
        workflows={workflowTemplates}
        selectedLabels={selectedLabels}
        logicOperator={logicOperator}
        onFilterChange={setSelectedLabels}
        onLogicChange={setLogicOperator}
      />
      <MaterialTable
        icons={TableIcons}
        tableRef={tableRef}
        isLoading={isLoading}
        options={{
          tableLayout: 'auto',
          addRowPosition: 'first',
          actionsColumnIndex: -1,
          actionsCellStyle: { padding: '0px 20px', textAlign: 'center' },
        }}
        actions={tableActions}
        columns={[
          { title: t('templateId'), field: 'id' },
          { title: t('name'), render: (data) => <>{data.workflowDto.name}</> },
          { title: t('version'), field: 'version' },
        ]}
        data={filteredWorkflows}
        title={t('workflowTemplatesTooltip')}
        detailPanel={(rowData) => {
          return (
            <Paper className="p-5 w-full">
              <Grid item xs={12}>
                <Autocomplete
                  className="py-4"
                  multiple
                  id="tags-filled"
                  options={[] as string[]}
                  freeSolo
                  value={rowData?.rowData.labels || []}
                  onChange={(e, value) => handleLabelsChange(e, value, rowData.rowData.id)}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return <Chip variant="filled" label={option} key={key} {...tagProps} />;
                    })
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={t('typeAndPressEnterToAddLabels')} placeholder={t('typeAndPressEnterToAdd') as string} />
                  )}
                />
              </Grid>
            </Paper>
          );
        }}
        onRowClick={(_event, _rowData, togglePanel) => {
          if (togglePanel) return togglePanel.toggleDetailPanel();
        }}
      />
    </>
  );
};

export default WorkflowTemplatesTable;
