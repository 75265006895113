import React, { useMemo, useState } from 'react';
import { FilterableColumnInfo, TemplateSchedule, FilterGroup, TimeRange, ScheduleType } from '@interfaces';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks';
import {
  useGetSchedulesByTemplateQuery,
  useCreateScheduleMutation,
  useUpdateScheduleMutation,
  useDeleteScheduleMutation,
  useUpdateScheduleStatusMutation,
  useGetScheduleRunsQuery,
} from '@apis';
import { setSelectedSchedule } from '@slices';

import TemplateSchedulesTable from './components/table/TemplateSchedulesTable';
import ScheduleEditDialog from './components/dialogs/ScheduleEditDialog';
import ScheduleHistoryDialog from './components/dialogs/ScheduleHistoryDialog';

interface ScheduleManagementProps {
  templateId: number;
  availableColumns: FilterableColumnInfo[];
  availableEmails: string[];
}

const ScheduleManagement: React.FC<ScheduleManagementProps> = ({ templateId, availableColumns, availableEmails }) => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();

  const selectedSchedule = useAppSelector((state) => state.report.schedules.selectedSchedule);
  const schedules = useAppSelector((state) => state.report.schedules.list[templateId] || []);
  const { templates, databaseSchema } = useAppSelector((state) => state.report);

  const [scheduleDialog, setScheduleDialog] = useState(false);
  const [historyDialog, setHistoryDialog] = useState(false);
  const [scheduleName, setScheduleName] = useState('');
  const [scheduleType, setScheduleType] = useState<ScheduleType>('DAILY');
  const [timeOfDay, setTimeOfDay] = useState<Date>(new Date());
  const [daysOfWeek, setDaysOfWeek] = useState<string[]>([]);
  const [dayOfMonth, setDayOfMonth] = useState(1);
  const [emails, setEmails] = useState<string[]>([]);
  const [filterGroups, setFilterGroups] = useState<FilterGroup[]>([]);
  const [logicalOperators, setLogicalOperators] = useState<('AND' | 'OR')[]>([]);
  const [timeRange, setTimeRange] = useState<TimeRange>({ type: 'no' });

  const { refetch: refetchSchedules } = useGetSchedulesByTemplateQuery(templateId);
  const [createSchedule] = useCreateScheduleMutation();
  const [updateSchedule] = useUpdateScheduleMutation();
  const [deleteSchedule] = useDeleteScheduleMutation();
  const [updateScheduleStatus] = useUpdateScheduleStatusMutation();

  const { data: scheduleHistory = [] } = useGetScheduleRunsQuery({ scheduleId: selectedSchedule?.id ?? 0 }, { skip: !selectedSchedule || !historyDialog });

  const timestampColumns = useMemo(() => {
    const currentTemplate = templates.find((x) => x.id === templateId);
    return (
      currentTemplate?.query.tables.flatMap((table) => {
        const schemaTable = databaseSchema?.find((t) => t.tableName === table.tableName);
        if (!schemaTable) return [];

        return schemaTable.columns
          .filter(
            (col) =>
              col.dataType.toLowerCase().includes('timestamp') ||
              col.columnName.toLowerCase().includes('date') ||
              col.columnName.toLowerCase().includes('time'),
          )
          .map((col) => ({
            tableName: table.tableName,
            columnName: col.columnName,
            dataType: col.dataType,
            alias: table.columns.find((tc) => tc.columnName === col.columnName)?.alias,
          }));
      }) || []
    );
  }, [templates, databaseSchema, templateId]);

  // Helper Functions
  const resetForm = () => {
    setScheduleName('');
    setScheduleType('DAILY');
    setTimeOfDay(new Date());
    setDaysOfWeek([]);
    setDayOfMonth(1);
    setEmails([]);
    setFilterGroups([]);
    setLogicalOperators([]);
    setTimeRange({ type: 'no' });
  };

  const formatTimeToTimeSpan = (date: Date): string => {
    return `${date.getHours()}:00:00`;
  };

  // Event Handlers
  const handleAdd = () => {
    dispatch(setSelectedSchedule(null));
    resetForm();
    setScheduleDialog(true);
  };

  const handleEdit = (schedule: TemplateSchedule) => {
    dispatch(setSelectedSchedule(schedule));
    setScheduleName(schedule.name);
    setScheduleType(schedule.scheduleType);
    setTimeOfDay(new Date(`2000-01-01T${schedule.timeOfDay}`));
    setDaysOfWeek(schedule.daysOfWeek || []);
    setDayOfMonth(schedule.dayOfMonth || 1);
    setEmails(schedule.emailRecipients);
    setFilterGroups(schedule.filters.groups);
    setLogicalOperators(schedule.filters.interGroupOperators);
    setTimeRange(schedule.timeRange || { type: 'no' });
    setScheduleDialog(true);
  };

  const handleSave = async () => {
    const scheduleData = {
      templateId,
      name: scheduleName,
      scheduleType,
      timeOfDay: formatTimeToTimeSpan(timeOfDay),
      daysOfWeek: scheduleType === 'WEEKLY' ? daysOfWeek : [],
      dayOfMonth: scheduleType === 'MONTHLY' ? dayOfMonth : undefined,
      emailRecipients: emails,
      filters: {
        groups: filterGroups,
        interGroupOperators: logicalOperators,
      },
      timeRange: timeRange,
    };

    try {
      if (selectedSchedule) {
        await updateSchedule({ id: selectedSchedule.id, schedule: { ...scheduleData } }).unwrap();
      } else {
        await createSchedule(scheduleData).unwrap();
      }
      await refetchSchedules();
      setScheduleDialog(false);
    } catch (error) {
      console.error('Failed to save schedule:', error);
    }
  };

  const handleToggleActive = async (schedule: TemplateSchedule) => {
    try {
      await updateScheduleStatus({ id: schedule.id, isActive: !schedule.isActive }).unwrap();
      await refetchSchedules();
    } catch (error) {
      console.error('Failed to toggle schedule status:', error);
    }
  };

  const handleDelete = async (schedule: TemplateSchedule) => {
    try {
      await deleteSchedule(schedule.id).unwrap();
      await refetchSchedules();
    } catch (error) {
      console.error('Failed to delete schedule:', error);
    }
  };

  const handleViewHistory = (schedule: TemplateSchedule) => {
    dispatch(setSelectedSchedule(schedule));
    setHistoryDialog(true);
  };

  return (
    <>
      <TemplateSchedulesTable
        schedules={schedules}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onToggleActive={handleToggleActive}
        onViewHistory={handleViewHistory}
      />

      <ScheduleEditDialog
        open={scheduleDialog}
        onClose={() => setScheduleDialog(false)}
        onSave={handleSave}
        schedule={selectedSchedule}
        availableColumns={availableColumns}
        scheduleName={scheduleName}
        onScheduleNameChange={setScheduleName}
        scheduleType={scheduleType}
        onScheduleTypeChange={setScheduleType}
        timeOfDay={timeOfDay}
        onTimeOfDayChange={(date) => date && setTimeOfDay(date)}
        daysOfWeek={daysOfWeek}
        onDaysOfWeekChange={setDaysOfWeek}
        dayOfMonth={dayOfMonth}
        onDayOfMonthChange={setDayOfMonth}
        emails={emails}
        onEmailsChange={setEmails}
        filterGroups={filterGroups}
        onFilterGroupsChange={setFilterGroups}
        logicalOperators={logicalOperators}
        onLogicalOperatorsChange={setLogicalOperators}
        timeRange={timeRange}
        onTimeRangeChange={setTimeRange}
        timestampColumns={timestampColumns}
      />

      <ScheduleHistoryDialog open={historyDialog} onClose={() => setHistoryDialog(false)} scheduleHistory={scheduleHistory} />
    </>
  );
};

export default ScheduleManagement;
