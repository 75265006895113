import React, { useState } from 'react';
import { Box, Typography, Paper, Select, MenuItem, FormControl, InputLabel, IconButton, Button, FormHelperText } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import { JoinDefinition, TableInfo } from '@interfaces';
import { useTranslation } from 'react-i18next';

interface PotentialJoin {
  leftTable: string;
  rightTable: string;
  leftColumn: string;
  rightColumn: string;
}

interface JoinConfigurationTabProps {
  selectedColumns: Array<{ tableName: string; columnName: string }>;
  joins: JoinDefinition[];
  onJoinChange: (index: number, field: keyof JoinDefinition, value: string) => void;
  onRemoveJoin: (index: number) => void;
  onAddJoin: (join: PotentialJoin) => void;
  unusedPotentialJoins: PotentialJoin[];
  selectedTables: string[];
  schema: TableInfo[] | undefined;
}

const JoinConfigurationTab: React.FC<JoinConfigurationTabProps> = ({
  selectedColumns,
  joins,
  onJoinChange,
  onRemoveJoin,
  onAddJoin,
  unusedPotentialJoins,
  selectedTables,
  schema,
}) => {
  const { t } = useTranslation('pano');
  const [customJoin, setCustomJoin] = useState({
    leftTable: '',
    leftColumn: '',
    rightTable: '',
    rightColumn: '',
    joinType: 'INNER',
  });

  const getColumnsForTable = (tableName: string) => {
    const tableInfo = schema?.find((t) => t.tableName === tableName);
    return tableInfo?.columns.map((c) => c.columnName) || [];
  };

  const isCustomJoinValid = () => {
    return customJoin.leftTable && customJoin.leftColumn && customJoin.rightTable && customJoin.rightColumn;
  };

  const handleAddCustomJoin = () => {
    if (isCustomJoinValid()) {
      onAddJoin(customJoin);
      setCustomJoin({
        leftTable: '',
        leftColumn: '',
        rightTable: '',
        rightColumn: '',
        joinType: 'INNER',
      });
    }
  };

  const renderExistingJoins = () => (
    <Box className="space-y-3">
      {joins.map((join, index) => (
        <Paper key={index} className="p-4 border border-gray-200" variant="outlined">
          <Box className="flex items-center justify-between">
            <Box className="flex-1">
              <Box className="flex items-center gap-2 mb-2">
                <Typography className="font-medium text-sm">{`${join.leftTable}.${join.leftColumn}`}</Typography>
                <Typography color="textSecondary" className="text-sm">
                  =
                </Typography>
                <Typography className="font-medium text-sm">{`${join.rightTable}.${join.rightColumn}`}</Typography>
              </Box>
              <FormControl size="small" className="min-w-[120px]">
                <Select value={join.joinType} onChange={(e) => onJoinChange(index, 'joinType', e.target.value as string)}>
                  <MenuItem value="INNER">{t('innerJoin')}</MenuItem>
                  <MenuItem value="LEFT">{t('leftJoin')}</MenuItem>
                  <MenuItem value="RIGHT">{t('rightJoin')}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <IconButton onClick={() => onRemoveJoin(index)} color="error" size="small" aria-label={t('removeJoin') as string}>
              <DeleteOutlineIcon fontSize="small" />
            </IconButton>
          </Box>
        </Paper>
      ))}
    </Box>
  );

  const renderSuggestedJoins = () =>
    unusedPotentialJoins.length > 0 && (
      <Box className="mt-6">
        <Typography variant="subtitle1" className="mb-3 font-medium">
          {t('suggestedJoins')}
        </Typography>
        <Box className="grid grid-cols-1 md:grid-cols-2 gap-3">
          {unusedPotentialJoins.map((join, index) => (
            <Paper
              key={index}
              variant="outlined"
              className="p-3 border border-gray-200 hover:border-blue-500 transition-colors cursor-pointer"
              onClick={() => onAddJoin(join)}>
              <Box className="flex items-center justify-between">
                <Box>
                  <Typography className="text-sm">
                    {join.leftTable}
                    <span className="text-gray-500 mx-1">→</span>
                    {join.rightTable}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {`${join.leftColumn} = ${join.rightColumn}`}
                  </Typography>
                </Box>
                <AddIcon color="primary" fontSize="small" />
              </Box>
            </Paper>
          ))}
        </Box>
      </Box>
    );

  const renderCustomJoinSection = () => {
    const availableRightTables = selectedTables.filter((table) => table !== customJoin.leftTable);
    const canCreateJoins = selectedTables.length >= 2;

    if (!canCreateJoins) {
      return (
        <Paper className="mt-6 p-4" variant="outlined">
          <Typography variant="subtitle1" className="!mb-4 font-medium">
            {t('addCustomJoin')}
          </Typography>
          <Typography color="textSecondary">{t('noJoinsAvailable')}</Typography>
        </Paper>
      );
    }

    return (
      <Paper className="mt-6 p-4" variant="outlined">
        <Typography variant="subtitle1" className="!mb-4 font-medium">
          {t('addCustomJoin')}
        </Typography>
        <Box className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <FormControl fullWidth size="small">
            <InputLabel>{t('leftTable')}</InputLabel>
            <Select
              value={customJoin.leftTable || ''}
              onChange={(e) => {
                const newLeftTable = e.target.value;
                setCustomJoin((prev) => ({
                  ...prev,
                  leftTable: newLeftTable,
                  leftColumn: '',
                  rightTable: prev.rightTable === newLeftTable ? '' : prev.rightTable,
                }));
              }}
              label={t('leftTable')}>
              {selectedTables.map((table) => (
                <MenuItem key={table} value={table}>
                  {table}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth size="small">
            <InputLabel>{t('leftColumn')}</InputLabel>
            <Select
              value={customJoin.leftColumn || ''}
              onChange={(e) => setCustomJoin((prev) => ({ ...prev, leftColumn: e.target.value }))}
              label={t('leftColumn')}
              disabled={!customJoin.leftTable}>
              {getColumnsForTable(customJoin.leftTable).map((col) => (
                <MenuItem key={col} value={col}>
                  {col}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth size="small">
            <InputLabel>{t('rightTable')}</InputLabel>
            <Select
              value={customJoin.rightTable || ''}
              onChange={(e) => setCustomJoin((prev) => ({ ...prev, rightTable: e.target.value, rightColumn: '' }))}
              label={t('rightTable')}
              disabled={!customJoin.leftTable}>
              {availableRightTables.map((table) => (
                <MenuItem key={table} value={table}>
                  {table}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth size="small">
            <InputLabel>{t('rightColumn')}</InputLabel>
            <Select
              value={customJoin.rightColumn || ''}
              onChange={(e) => setCustomJoin((prev) => ({ ...prev, rightColumn: e.target.value }))}
              label={t('rightColumn')}
              disabled={!customJoin.rightTable}>
              {getColumnsForTable(customJoin.rightTable).map((col) => (
                <MenuItem key={col} value={col}>
                  {col}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box className="mt-4 flex justify-end">
          <Button onClick={handleAddCustomJoin} disabled={!isCustomJoinValid()} variant="contained" startIcon={<AddIcon />}>
            {t('addJoin')}
          </Button>
        </Box>
      </Paper>
    );
  };

  return (
    <Box className="space-y-6">
      <Typography variant="h6" className="mb-4">
        {t('configureTableJoins')}
      </Typography>

      {selectedColumns.length === 0 ? (
        <Paper variant="outlined" className="p-8 text-center">
          <Typography color="textSecondary" className="mb-2">
            {t('noColumnsSelected')}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {t('selectColumnsPrompt')}
          </Typography>
        </Paper>
      ) : (
        <>
          {renderExistingJoins()}
          {renderSuggestedJoins()}
          {renderCustomJoinSection()}
        </>
      )}
    </Box>
  );
};

export default JoinConfigurationTab;
