import { useGetCurrentUserQuery } from '@apis';
import { UserAppList, UserEditForm, UserTeamList } from '@components';
import { RoutePath, white } from '@constants';
import { faCogs, faShield, faShieldCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '@hooks';
import { CurrentUserItem, NavContext } from '@interfaces';
import { CircularProgress, Fade, FormControlLabel, FormGroup, Grid, Paper, Switch, Typography } from '@mui/material';
import { changeTheme, setBreadcrumbs, setHelmet, setNavigationContext } from '@slices';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'oidc-react';

const UserDashboard = (): JSX.Element => {
    const { t } = useTranslation('pano');
    const dispatch = useAppDispatch();
    const { currentUserData } = useAppSelector((x) => x.user);
    const { userRights, isDarkMode } = useAppSelector((x) => x.app);

    const { userData } = useAuth();
    const { isLoading, isSuccess } = useGetCurrentUserQuery(undefined, { refetchOnMountOrArgChange: true, skip: !userData });

    useEffect(() => {
        dispatch(setHelmet({ title: t('htmlTitleUserDashboard') }));
        dispatch(setBreadcrumbs([{ text: t('userDashboardPageTitle') }]));
    }, []);

    useEffect(() => {
        let navContext = [] as NavContext[];

        if (userRights.isEchoUser) {
            navContext.push({
                header: t('echoServiceTitle'),
                links: [
                    {
                        key: 'echoCommands',
                        text: t('echoCommandsButtonUser'),
                        link: RoutePath.UserCommandsPath,
                        icon: <FontAwesomeIcon icon={faCogs} fixedWidth size="lg" style={{ color: white[500] }} />,
                        tooltip: t('echoCommandsPersonalDescription'),
                    },
                ],
            });
        }

        if (isSuccess) {
            if (!currentUserData?.mfaEnabled) {
                navContext.push({
                    header: t('security'),
                    links: [
                        {
                            key: 'mfaSetup',
                            text: t('mfaTwoStepText'),
                            link: RoutePath.UserMfaPath,
                            icon: <FontAwesomeIcon icon={faShield} fixedWidth size="lg" style={{ color: white[500] }} />,
                            tooltip: t('mfaPageTitle'),
                        },
                    ],
                });
            } else {
                navContext.push({
                    header: t('security'),
                    links: [
                        {
                            key: 'mfaRecoveryKeysRenew',
                            text: t('mfaRecoveryKeysRenew'),
                            link: RoutePath.UserMfaRecoveryPath,
                            icon: <FontAwesomeIcon icon={faShieldCheck} fixedWidth size="lg" style={{ color: white[500] }} />,
                            tooltip: t('mfaRecoveryKeysRenew'),
                        },
                    ],
                });
            }
        }
        dispatch(setNavigationContext({ navContext }));
    }, [isSuccess]);

    return (
        <>
            {isLoading ? (
                <Fade in={isLoading} style={{ transitionDelay: isLoading ? '800ms' : '0ms' }} unmountOnExit>
                    <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />
                </Fade>
            ) : (
                <Grid container spacing={2}>
                    <Grid container item xs={12} sm={12} md={12} lg={12}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                {t('userDashPageSubtitle')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} className="flex justify-end">
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Switch checked={isDarkMode} onChange={(_e, checked) => dispatch(changeTheme(checked))} color="primary" name="isDarkMode" />}
                                    label={t('Dark Mode')}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Paper className="w-full p-5 min-h-[64px]">
                            <UserEditForm editUserData={currentUserData as CurrentUserItem} isMyAccountPage={true} userId={parseInt(userData?.profile.sub ?? '0')} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Paper className="w-full p-5 min-h-[64px]">
                                    <UserTeamList />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Paper className="w-full p-5 min-h-[64px]">
                                    <UserAppList />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default UserDashboard;
