import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Checkbox, Chip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TableInfo, ColumnInfo, ReportColumnDto } from '@interfaces';
import { useTranslation } from 'react-i18next';

interface ColumnSelectionTabProps {
  schema: TableInfo[] | undefined;
  selectedColumns: ReportColumnDto[];
  expandedTables: string[];
  onTableExpand: (tableName: string) => void;
  onColumnSelect: (tableName: string, columnName: string) => void;
}

const ColumnSelectionTab: React.FC<ColumnSelectionTabProps> = ({ schema, selectedColumns, expandedTables, onTableExpand, onColumnSelect }) => {
  const { t } = useTranslation('pano');

  const getSelectedColumnCount = (tableName: string) => {
    return selectedColumns.filter((col) => col.tableName === tableName).length;
  };

  const getColumnLabel = (column: ColumnInfo) => {
    return `${column.columnName} (${column.dataType})`;
  };

  return (
    <Box>
      <Typography variant="h6" className="mb-2">
        {t('selectColumns')}
      </Typography>
      {schema?.map((table: TableInfo) => (
        <Accordion key={table.tableName} expanded={expandedTables.includes(table.tableName)} onChange={() => onTableExpand(table.tableName)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${table.tableName}-content`} id={`${table.tableName}-header`}>
            <Typography>{table.tableName}</Typography>
            <Chip label={`${getSelectedColumnCount(table.tableName)} ${t('selected')}`} size="small" color="primary" className="ml-2" />
          </AccordionSummary>
          <AccordionDetails>
            <Box className="ml-4">
              {table.columns.map((column: ColumnInfo) => (
                <FormControlLabel
                  key={`${table.tableName}-${column.columnName}`}
                  control={
                    <Checkbox
                      checked={selectedColumns.some((col) => col.tableName === table.tableName && col.columnName === column.columnName)}
                      onChange={() => onColumnSelect(table.tableName, column.columnName)}
                    />
                  }
                  label={getColumnLabel(column)}
                />
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default ColumnSelectionTab;
