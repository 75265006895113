import { blue, red } from '@constants';
import { faClone, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '@hooks';
import { WorkflowStepNodeItem } from '@interfaces';
import { Button, Checkbox, FormControlLabel, FormGroup, IconButton, Paper, TextField, Tooltip } from '@mui/material';
import { addOrUpdateStepLayout, addTemplateStep, removeTemplateStep } from '@slices';
import { encodeAndCompress } from '@utils';
import { Handle, NodeProps, Position } from '@xyflow/react';
import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import WorkflowServiceNode from './WorkflowServiceNode';

const WorkflowStepNode: React.FC<NodeProps<WorkflowStepNodeItem>> = ({ data, id }) => {
  const { t } = useTranslation('pano');
  const inputRef = useRef<HTMLInputElement>(null);
  const cursorPositionRef = useRef<number | null>(null);
  const { currentTemplate, workflowStepNewId } = useAppSelector((x) => x.mahon);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (inputRef.current && cursorPositionRef.current !== null) {
      inputRef.current.setSelectionRange(cursorPositionRef.current, cursorPositionRef.current);
    }
  }, [data.label]);

  const handleClick = () => {
    if (data.onClick) {
      data.onClick();
    }
  };

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const newValue = e.target.value;
    cursorPositionRef.current = e.target.selectionStart;
    if (data.onLabelChange) {
      data.onLabelChange(newValue, false);
    }
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(removeTemplateStep(parseInt(id)));
  };

  const handleCloneStep = () => {
    const currentStep = currentTemplate?.steps.find((x) => x.id === parseInt(id));
    if (currentStep) {
      const newStep = {
        id: workflowStepNewId,
        name: 'New Step',
        authorOnly: currentStep.authorOnly,
        stepConfigurations: currentStep.stepConfigurations,
        stepIndex: currentTemplate?.steps ? currentTemplate?.steps?.length + 1 : 1,
        workflowId: currentTemplate?.id === 0 ? -1 : currentTemplate?.id || 0,
        version: currentTemplate?.id === 0 ? -1 : 1,
        isService: false,
        workflowServiceId: null,
        service: null,
      };
      dispatch(addTemplateStep(newStep));
      dispatch(
        addOrUpdateStepLayout({
          stepId: newStep.id,
          layoutType: 'DataView',
          description: '<EmptyLayout>',
          xml: encodeAndCompress(''),
        }),
      );
    }
  };

  return data.type === 'service' ? (
    <WorkflowServiceNode data={data} stepId={parseInt(id)} />
  ) : (
    <Paper className="space-y-4 p-2.5 border rounded cursor-pointer !w-64 relative" onClick={handleClick}>
      <div className="!absolute w-[94%] !top-1 flex items-center justify-between">
        <div>{data.index}.</div>
        <IconButton
          size="small"
          className="nodrag"
          onClick={handleDelete}
          sx={{
            width: 20,
            height: 20,
          }}>
          <FontAwesomeIcon icon={faXmark} style={{ color: red[500], fontSize: 14 }} />
        </IconButton>
      </div>

      <Handle type="target" position={Position.Left} style={{ width: 12, height: 12 }} />
      <div className="flex w-full items-center space-x-1 !mt-8">
        <TextField
          inputRef={inputRef}
          className="nodrag"
          name="name"
          autoComplete="off"
          label={t('name')}
          placeholder={t('name') as string}
          fullWidth
          value={data.label}
          onChange={handleLabelChange}
          onBlur={() => {
            if (data.onLabelChange) data.onLabelChange(data.label as string, true);
          }}
          onMouseDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
          variant="outlined"
        />
        <Tooltip title={t('cloneThisStep')}>
          <IconButton
            className="!w-8 !h-8 flex justify-center nodrag"
            onClick={(e) => {
              e.stopPropagation();
              handleCloneStep();
            }}>
            <FontAwesomeIcon icon={faClone} color={blue[500]} size="xs" />
          </IconButton>
        </Tooltip>
      </div>
      <div className="flex w-full justify-between nodrag">
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={data.authorOnly}
                onChange={(e) => {
                  e.stopPropagation();
                  if (data.onAuthorOnlyChange) {
                    data.onAuthorOnlyChange(e.target.checked);
                  }
                }}
                onClick={(e) => e.stopPropagation()}
                color="primary"
                name="authorOnly"
              />
            }
            label={t('authorOnly')}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
          />
        </FormGroup>
        <Button
          className="nodrag"
          type="button"
          variant="contained"
          color="info"
          onClick={(e) => {
            e.stopPropagation();
            if (data.onMediaClick) data.onMediaClick();
          }}>
          {t('media')}
        </Button>
      </div>
      <Handle type="source" position={Position.Right} style={{ width: 12, height: 12 }} />
    </Paper>
  );
};

export default WorkflowStepNode;
