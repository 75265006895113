import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks';
import {
  useGetTemplatesQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useRunTemplateMutation,
  useGetDatabaseSchemaQuery,
  useDeleteTemplateMutation,
} from '@apis';
import { QueryType, FilterGroup, Template, JoinDefinition } from '@interfaces';
import { setSelectedColumns, clearGeneratedReport, openSnackbar } from '@slices';
import { useTranslation } from 'react-i18next';
import TemplateManagementTable from './components/table/TemplateManagementTable';
import TemplateEditDialog from './components/dialogs/TemplateEditDialog';
import RunTemplateDialog from './components/dialogs/RunTemplateDialog';
import TemplateTableLayout from './components/table/TemplateTableLayout';

const TemplateManagement: React.FC = () => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();

  const { isLoading: templatesLoading, refetch } = useGetTemplatesQuery();
  const { data: schema, isLoading: schemaLoading } = useGetDatabaseSchemaQuery();
  const [createTemplate] = useCreateTemplateMutation();
  const [updateTemplate] = useUpdateTemplateMutation();
  const [runTemplate] = useRunTemplateMutation();
  const [deleteTemplate] = useDeleteTemplateMutation();

  const { selectedColumns, templates } = useAppSelector((state) => state.report);

  const [openDialog, setOpenDialog] = useState(false);
  const [openRunDialog, setOpenRunDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState<'create' | 'edit'>('create');
  const [currentTemplate, setCurrentTemplate] = useState<Template | null>(null);
  const [templateName, setTemplateName] = useState('');
  const [joins, setJoins] = useState<JoinDefinition[]>([]);
  const [filterableColumns, setFilterableColumns] = useState<string[]>([]);
  const [runFilters, setRunFilters] = useState<FilterGroup[]>([]);
  const [logicalOperators, setLogicalOperators] = useState<('AND' | 'OR')[]>([]);
  const [expandedTables, setExpandedTables] = useState<string[]>([]);
  const [queryType, setQueryType] = useState<QueryType>({ type: 'SELECT' });

  const handleCreateTemplate = () => {
    setDialogMode('create');
    setCurrentTemplate(null);
    setTemplateName('');
    setJoins([]);
    setFilterableColumns([]);
    dispatch(setSelectedColumns([]));
    setQueryType({ type: 'SELECT' });
    setOpenDialog(true);
  };

  const handleEditTemplate = (template: Template) => {
    setDialogMode('edit');
    setCurrentTemplate(template);
    setTemplateName(template.name);
    setJoins(template.query.joins);
    setQueryType(template.query.queryType || { type: 'SELECT' });
    setFilterableColumns(
      template.query.tables.flatMap((table) => table.columns.filter((col) => col.isFilterable).map((col) => `${table.tableName}.${col.columnName}`)),
    );
    dispatch(
      setSelectedColumns(
        template.query.tables.flatMap((table) =>
          table.columns.map((column) => ({
            tableName: table.tableName,
            columnName: column.columnName,
            alias: column.alias,
          })),
        ),
      ),
    );
    setOpenDialog(true);
  };

  const handleSaveTemplate = async () => {
    const queryDefinition = {
      tables: selectedColumns.reduce((acc, col) => {
        const tableIndex = acc.findIndex((t) => t.tableName === col.tableName);
        if (tableIndex === -1) {
          acc.push({
            tableName: col.tableName,
            columns: [
              {
                columnName: col.columnName,
                isFilterable: filterableColumns.includes(`${col.tableName}.${col.columnName}`),
                filterType: 'Equals',
                alias: col.alias,
              },
            ],
          });
        } else {
          acc[tableIndex].columns.push({
            columnName: col.columnName,
            isFilterable: filterableColumns.includes(`${col.tableName}.${col.columnName}`),
            filterType: 'Equals',
            alias: col.alias,
          });
        }
        return acc;
      }, [] as any[]),
      joins,
      queryType,
    };

    try {
      if (dialogMode === 'create') {
        await createTemplate({
          name: templateName,
          version: 1,
          query: queryDefinition,
        }).unwrap();
        dispatch(openSnackbar({ message: t('templateCreatedSuccess'), severity: 'success', display: true }));
      } else if (currentTemplate) {
        await updateTemplate({
          id: currentTemplate.id,
          name: templateName,
          version: currentTemplate.version + 1,
          query: queryDefinition,
        }).unwrap();
        dispatch(openSnackbar({ message: t('templateUpdatedSuccess'), severity: 'success', display: true }));
      }
      refetch();
      setOpenDialog(false);
    } catch (error) {
      dispatch(openSnackbar({ message: t('templateSaveError'), severity: 'error', display: true }));
    }
  };

  const handleRunTemplate = async (templateId: number) => {
    const templateToRun = templates?.find((t) => t.id === templateId);
    if (templateToRun) {
      setCurrentTemplate(templateToRun);
      setRunFilters([]);
      setLogicalOperators([]);
      dispatch(clearGeneratedReport());
      setOpenRunDialog(true);
    }
  };

  const handleDeleteTemplate = async (templateId: number) => {
    try {
      await deleteTemplate(templateId).unwrap();
      dispatch(openSnackbar({ message: t('templateDeletedSuccess'), severity: 'success', display: true }));
      refetch();
    } catch (error) {
      dispatch(openSnackbar({ message: t('templateDeleteError'), severity: 'error', display: true }));
    }
  };

  const executeRunTemplate = async () => {
    if (currentTemplate) {
      try {
        await runTemplate({
          templateId: currentTemplate.id,
          filterInfo: {
            groups: runFilters,
            interGroupOperators: logicalOperators,
          },
        }).unwrap();
        setOpenRunDialog(false);
      } catch (error) {
        dispatch(openSnackbar({ message: t('templateRunError'), severity: 'error', display: true }));
      }
    }
  };

  return (
    <TemplateTableLayout isLoading={templatesLoading || schemaLoading} error={undefined}>
      <TemplateManagementTable
        templates={templates || []}
        isLoading={templatesLoading}
        onCreateTemplate={handleCreateTemplate}
        onEditTemplate={handleEditTemplate}
        onRunTemplate={handleRunTemplate}
        onDeleteTemplate={handleDeleteTemplate}
        schema={schema}
        availableEmails={[]}
      />

      <TemplateEditDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSave={handleSaveTemplate}
        mode={dialogMode}
        schema={schema || []}
        templateName={templateName}
        onTemplateNameChange={setTemplateName}
        selectedColumns={selectedColumns}
        onSelectedColumnsChange={(columns) => dispatch(setSelectedColumns(columns))}
        joins={joins}
        onJoinsChange={setJoins}
        queryType={queryType}
        onQueryTypeChange={setQueryType}
        filterableColumns={filterableColumns}
        onFilterableColumnsChange={setFilterableColumns}
        expandedTables={expandedTables}
        onTableExpand={(tableName) => {
          setExpandedTables((prev) => (prev.includes(tableName) ? prev.filter((t) => t !== tableName) : [...prev, tableName]));
        }}
      />

      <RunTemplateDialog
        open={openRunDialog}
        onClose={() => setOpenRunDialog(false)}
        onRun={executeRunTemplate}
        template={currentTemplate}
        filterGroups={runFilters}
        onFilterGroupsChange={setRunFilters}
        logicalOperators={logicalOperators}
        onLogicalOperatorsChange={setLogicalOperators}
        availableColumns={
          currentTemplate?.query.tables.flatMap((table) =>
            table.columns
              .filter((col) => col.isFilterable)
              .map((col) => ({
                tableName: table.tableName,
                columnName: col.columnName,
                dataType: schema?.find((s) => s.tableName === table.tableName)?.columns.find((sc) => sc.columnName === col.columnName)?.dataType || 'unknown',
                filterType: col.filterType,
                alias: col.alias,
              })),
          ) || []
        }
      />
    </TemplateTableLayout>
  );
};

export default TemplateManagement;
