import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ChangePasswordForm,
  ConfirmAccountForm,
  CurrentUserItem,
  GetUsersSearchParams,
  TeamMembershipItem,
  UserAppItem,
  UserForm,
  UserItem,
  UserResults,
  UserValidationItem,
  UserWorkerItem,
} from '@interfaces';
import { baseQueryHandler } from './base.api';

export const userApi = createApi({
  baseQuery: baseQueryHandler,
  reducerPath: 'userApi',
  endpoints: (builder) => ({
    getUserPermissions: builder.query<any, void>({
      query: () => ({
        url: `/api/v1/user/permissions`,
        method: 'GET',
      }),
    }),
    getTeamMemberships: builder.query<TeamMembershipItem[], void>({
      query: () => ({
        url: `/api/v1/user/teams`,
        method: 'GET',
      }),
    }),
    getUserRoles: builder.query<any, { teamId: number; userId: number }>({
      query: (payload) => ({
        url: `/api/v1/user/${payload.userId}/roles`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
      }),
    }),
    getUsers: builder.query<UserItem[], string>({
      query: (payload) => ({
        url: `/api/v1/user/information?userIds=${payload}`,
        method: 'GET',
      }),
    }),
    getUser: builder.query<UserWorkerItem[], number>({
      query: (payload) => ({
        url: `/api/v1/user?workerIds=${payload}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': '1' },
      }),
    }),
    getCurrentUser: builder.query<CurrentUserItem, void>({
      query: () => ({
        url: `/api/v1/user/current`,
        method: 'GET',
      }),
    }),
    getUserApp: builder.query<UserAppItem[], number>({
      query: (payload) => ({
        url: `/api/v2/user/apps/${payload}`,
        method: 'GET',
      }),
    }),
    getEmailConfirmationStatus: builder.query<boolean, number>({
      query: (payload) => ({
        url: `/api/v1/user/${payload}/status/`,
        method: 'GET',
      }),
    }),
    getUsersSearch: builder.query<UserResults, GetUsersSearchParams>({
      query: (payload) => {
        const params = new URLSearchParams({
          searchTerm: payload.searchTerm,
          pageNo: payload.pageNo.toString(),
          pageSize: payload.pageSize.toString(),
          ...(payload.sortField && { sortField: payload.sortField }),
          ...(payload.sortDirection && { sortDirection: payload.sortDirection }),
        });

        return {
          url: `/api/v1/user/search?${params.toString()}`,
          method: 'GET',
          headers: { 'X-PANOPTICON-TEAM': '1' },
        };
      },
    }),
    sendAccountInvite: builder.mutation<void, number>({
      query: (payload) => ({
        url: `/api/v1/user/account/invite/${payload}`,
        method: 'POST',
      }),
    }),
    updateUser: builder.mutation<void, { newData: UserForm; userId?: number }>({
      query: (payload) => ({
        url: payload.userId ? `/api/v1/user/${payload.userId}` : `/api/v1/user`,
        method: 'PUT',
        body: payload.newData,
      }),
    }),
    userForceResetPassword: builder.mutation<void, number>({
      query: (payload) => ({
        url: `/api/v1/user/${payload}/password/forcereset`,
        method: 'PUT',
      }),
    }),
    resetUserPassword: builder.mutation<string, number>({
      query: (payload) => ({
        url: `/api/v1/user/${payload}/password/reset`,
        method: 'PUT',
      }),
    }),
    acceptTeamInvite: builder.mutation<string, { teamId: number; userId: number; accepted: boolean }>({
      query: (payload) => ({
        url: `/api/v2/team/member/${payload.userId}/${payload.accepted}`,
        method: 'PUT',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
      }),
    }),
    validatePassword: builder.mutation<UserValidationItem, string>({
      query: (payload) => ({
        url: `/api/v1/authenticate/stepup`,
        method: 'POST',
        body: JSON.stringify(payload),
      }),
    }),
    confirmAccount: builder.mutation<void, { userId: number; code: string; data: ConfirmAccountForm }>({
      query: (payload) => ({
        url: `/api/v1/user/${payload.userId}/confirm/`,
        method: 'POST',
        body: {
          Code: payload.code,
          NewPassword: payload.data.newPassword,
          ConfirmPassword: payload.data.confirmPassword,
        },
      }),
    }),
    changeUserPassword: builder.mutation<void, { userId: number; data: ChangePasswordForm }>({
      query: (payload) => ({
        url: `/api/v1/user/${payload.userId}/password`,
        method: 'PUT',
        body: { CurrentPassword: payload.data.currentPassword, NewPassword: payload.data.newPassword },
      }),
    }),
  }),
});

export const {
  useGetUserPermissionsQuery,
  useGetUsersQuery,
  useGetTeamMembershipsQuery,
  useGetUserRolesQuery,
  useGetUsersSearchQuery,
  useSendAccountInviteMutation,
  useGetUserQuery,
  useGetCurrentUserQuery,
  useGetUserAppQuery,
  useUpdateUserMutation,
  useUserForceResetPasswordMutation,
  useResetUserPasswordMutation,
  useValidatePasswordMutation,
  useChangeUserPasswordMutation,
  useGetEmailConfirmationStatusQuery,
  useConfirmAccountMutation,
  useAcceptTeamInviteMutation,
} = userApi;
