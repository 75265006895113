import React from 'react';
import { Box, Button, TextField, Select, MenuItem, FormControl, InputLabel, Typography, IconButton } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FilterableColumnInfo, FilterDto, CompoundFilterInfo, Operator, FilterGroup } from '@interfaces';
import { isValid } from 'date-fns';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';

interface Props {
  filterGroups: FilterGroup[];
  onFilterGroupsChange: (groups: FilterGroup[]) => void;
  interGroupOperators: ('AND' | 'OR')[];
  onInterGroupOperatorsChange: (operators: ('AND' | 'OR')[]) => void;
  availableColumns: FilterableColumnInfo[];
}

const COMPOUND_FILTERS: Record<string, CompoundFilterInfo> = {
  timestamp: {
    dataType: 'timestamp',
    options: {
      label: 'Date Range',
      operators: [
        { value: 'eq', label: 'On' },
        { value: 'gt', label: 'After' },
        { value: 'lt', label: 'Before' },
        { value: 'between', label: 'Between' },
        { value: 'isnull', label: 'Is Null' },
        { value: 'isnotnull', label: 'Is Not Null' },
      ],
      allowMultiple: true,
      compoundType: 'range',
    },
  },
  text: {
    dataType: 'text',
    options: {
      label: 'Text Search',
      operators: [
        { value: 'like', label: 'Contains' },
        { value: 'eq', label: 'Equals' },
      ],
      allowMultiple: true,
      compoundType: 'or',
    },
  },
  numeric: {
    dataType: 'numeric',
    options: {
      label: 'Number Range',
      operators: [
        { value: 'eq', label: 'Equals' },
        { value: 'between', label: 'Between' },
        { value: 'in', label: 'Any of' },
        { value: 'gt', label: 'Greater than' },
        { value: 'lt', label: 'Less than' },
      ],
      allowMultiple: true,
      compoundType: 'range',
    },
  },
  boolean: {
    dataType: 'boolean',
    options: {
      label: 'Boolean Filter',
      operators: [{ value: 'eq', label: 'Equals' }],
      allowMultiple: false,
      compoundType: 'or',
    },
  },
  uuid: {
    dataType: 'uuid',
    options: {
      label: 'UUID Filter',
      operators: [
        { value: 'eq', label: 'Equals' },
        { value: 'like', label: 'Contains' },
      ],
      allowMultiple: true,
      compoundType: 'or',
    },
  },
};

const FilterCreation: React.FC<Props> = ({ filterGroups, onFilterGroupsChange, interGroupOperators, onInterGroupOperatorsChange, availableColumns }) => {
  const { t } = useTranslation('pano');

  const buildLogicString = (groups: FilterGroup[], operators: ('AND' | 'OR')[]): string => {
    return groups
      .map((group, idx) => {
        const groupString = buildGroupString(group);
        if (idx > 0) {
          return `${operators[idx - 1]} (${groupString})`;
        }
        return `(${groupString})`;
      })
      .join(' ');
  };

  const buildGroupString = (group: FilterGroup): string => {
    const items = [
      ...group.filters.map((filter) => {
        const columnLabel = filter.columnName;
        return `${columnLabel} ${operatorToSymbol(filter.operator)} ${filter.value}`;
      }),
      ...(group.subGroups || []).map((subGroup) => `(${buildGroupString(subGroup)})`),
    ];

    return items.join(` ${group.intraGroupOperator} `);
  };

  const operatorToSymbol = (operator: Operator): string => {
    const mapping: Record<Operator, string> = {
      eq: '=',
      neq: '!=',
      gt: '>',
      gte: '>=',
      lt: '<',
      lte: '<=',
      like: 'LIKE',
      between: 'BETWEEN',
      in: 'IN',
      isnull: 'IS NULL',
      isnotnull: 'IS NOT NULL',
    };
    return mapping[operator] || operator;
  };

  const updateGroups = (groups: FilterGroup[], path: number[], updater: (group: FilterGroup) => FilterGroup | null): FilterGroup[] => {
    if (path.length === 0) {
      return groups;
    }
    const [index, ...rest] = path;
    return groups.map((group, idx) => {
      if (idx === index) {
        if (rest.length === 0) {
          const updatedGroup = updater(group);
          return updatedGroup ? updatedGroup : group;
        } else {
          const subGroups = group.subGroups || [];
          const updatedSubGroups = updateGroups(subGroups, rest, updater);
          return { ...group, subGroups: updatedSubGroups };
        }
      }
      return group;
    });
  };

  const handleAddNewGroup = (path: number[]) => {
    const newGroup: FilterGroup = {
      filters: [],
      intraGroupOperator: 'AND',
      subGroups: [],
    };

    if (path.length === 0) {
      onFilterGroupsChange([...filterGroups, newGroup]);
      if (filterGroups.length > 0) {
        onInterGroupOperatorsChange([...interGroupOperators, 'AND']);
      }
    } else {
      const updatedGroups = updateGroups(filterGroups, path, (group) => ({
        ...group,
        subGroups: [...(group.subGroups || []), newGroup],
      }));
      onFilterGroupsChange(updatedGroups);
    }
  };

  const handleRemoveGroup = (path: number[]) => {
    if (path.length === 1) {
      const groupIndex = path[0];
      const newFilterGroups = filterGroups.filter((_, idx) => idx !== groupIndex);
      onFilterGroupsChange(newFilterGroups);

      const newOperators = [...interGroupOperators];
      if (groupIndex > 0) {
        newOperators.splice(groupIndex - 1, 1);
      } else if (newOperators.length > 0) {
        newOperators.shift();
      }
      onInterGroupOperatorsChange(newOperators);
    } else {
      const updatedGroups = updateGroups(filterGroups, path.slice(0, -1), (group) => ({
        ...group,
        subGroups: (group.subGroups || []).filter((_, idx) => idx !== path[path.length - 1]),
      }));
      onFilterGroupsChange(updatedGroups);
    }
  };

  const handleAddFilterToGroup = (path: number[], columnKey: string) => {
    const [tableName, columnName] = columnKey.split('.');
    const column = availableColumns.find((col) => col.tableName === tableName && col.columnName === columnName);

    if (!column) return;

    const getDataType = (columnType: string): string => {
      const type = columnType.toLowerCase();
      if (type.includes('timestamp')) return 'timestamp';
      if (type.includes('text') || type.includes('char') || type.includes('varchar')) return 'text';
      if (type.includes('int') || type.includes('decimal') || type.includes('numeric')) return 'numeric';
      if (type.includes('bool')) return 'boolean';
      return type;
    };

    const dataType = getDataType(column.dataType);
    const compoundInfo = COMPOUND_FILTERS[dataType];
    const defaultOperator = compoundInfo?.options.operators[0].value || 'eq';

    const newFilter: FilterDto = {
      tableName,
      columnName,
      operator: defaultOperator as Operator,
      value: '',
      valueType: dataType,
    };

    const updatedGroups = updateGroups(filterGroups, path, (group) => ({
      ...group,
      filters: [...group.filters, newFilter],
    }));
    onFilterGroupsChange(updatedGroups);
  };

  const handleIntraGroupOperatorChange = (path: number[], operator: 'AND' | 'OR') => {
    const updatedGroups = updateGroups(filterGroups, path, (group) => ({
      ...group,
      intraGroupOperator: operator,
    }));
    onFilterGroupsChange(updatedGroups);
  };

  const handleFilterChange = (path: number[], filterIndex: number, field: keyof FilterDto, value: any) => {
    const updatedGroups = updateGroups(filterGroups, path, (group) => {
      if (field === 'operator' && value === 'between') {
        const filter = group.filters[filterIndex];
        const secondFilter: FilterDto = {
          ...filter,
          operator: 'lt' as Operator,
          value: '',
        };
        filter.operator = 'gt' as Operator;

        const newFilters = [...group.filters];
        newFilters.splice(filterIndex + 1, 0, secondFilter);

        return {
          ...group,
          filters: newFilters,
          intraGroupOperator: 'AND',
        };
      } else {
        return {
          ...group,
          filters: group.filters.map((filter, idx) => {
            if (idx === filterIndex) {
              return { ...filter, [field]: value };
            }
            return filter;
          }),
        };
      }
    });
    onFilterGroupsChange(updatedGroups);
  };

  const handleRemoveFilter = (path: number[], filterIndex: number) => {
    const updatedGroups = updateGroups(filterGroups, path, (group) => {
      const newFilters = group.filters.filter((_, idx) => idx !== filterIndex);
      return { ...group, filters: newFilters };
    });
    onFilterGroupsChange(updatedGroups);
  };

  const handleInterGroupOperatorChange = (index: number, operator: 'AND' | 'OR') => {
    const newOperators = [...interGroupOperators];
    newOperators[index] = operator;
    onInterGroupOperatorsChange(newOperators);
  };

  const isValidNumberList = (value: string): boolean => {
    if (!value) return true;
    return value
      .split(',')
      .map((v) => v.trim())
      .every((v) => !isNaN(Number(v)));
  };

  const renderFilterInput = (filter: FilterDto, path: number[], filterIndex: number) => {
    const column = availableColumns.find((col) => col.tableName === filter.tableName && col.columnName === filter.columnName);

    if (!column) return null;

    const getDataType = (columnType: string): string => {
      const type = columnType.toLowerCase();
      if (type.includes('timestamp')) return 'timestamp';
      if (type.includes('text') || type.includes('char') || type.includes('varchar')) return 'text';
      if (type.includes('int') || type.includes('decimal') || type.includes('numeric')) return 'numeric';
      if (type.includes('bool')) return 'boolean';
      return type;
    };

    const dataType = getDataType(column.dataType);

    if (filter.operator === 'isnull' || filter.operator === 'isnotnull') {
      return null;
    }

    const handleValueChange = (value: any) => {
      handleFilterChange(path, filterIndex, 'value', value);
    };

    switch (dataType) {
      case 'timestamp':
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              value={filter.value ? new Date(filter.value) : null}
              onChange={(newValue) => {
                const isoString = newValue?.toISOString();
                handleFilterChange(path, filterIndex, 'value', isoString);
              }}
              slotProps={{
                textField: {
                  variant: 'outlined',
                  size: 'small',
                  error: filter.value && !isValid(new Date(filter.value)),
                  helperText: filter.value && !isValid(new Date(filter.value)) ? t('invalidDate') : undefined,
                },
                layout: {
                  sx: {
                    '.MuiPickersLayout-contentWrapper': {
                      alignItems: 'center',
                    },
                  },
                },
              }}
              views={['year', 'month', 'day', 'hours', 'minutes']}
              format="yyyy-MM-dd HH:mm"
              ampm={false}
            />
          </LocalizationProvider>
        );

      case 'numeric':
        if (filter.operator === 'in') {
          return (
            <FormControl fullWidth size="small">
              <TextField
                value={filter.value}
                onChange={(e) => handleValueChange(e.target.value)}
                placeholder={t('numericListPlaceholder') as string}
                helperText={t('commaNumbersHelp')}
                error={!isValidNumberList(filter.value)}
                size="small"
                InputProps={{
                  endAdornment: filter.value && (
                    <IconButton size="small" onClick={() => handleValueChange('')} edge="end">
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
              />
            </FormControl>
          );
        }

        return (
          <FormControl fullWidth size="small">
            <TextField
              type="number"
              value={filter.value}
              onChange={(e) => handleValueChange(e.target.value)}
              inputProps={{
                step: 'any',
              }}
              size="small"
              placeholder={(filter.operator === 'between' ? (filterIndex % 2 === 0 ? t('enterMinValue') : t('enterMaxValue')) : t('enterValue')) as string}
            />
          </FormControl>
        );

      case 'boolean':
        return (
          <FormControl fullWidth size="small">
            <Select value={filter.value ?? ''} onChange={(e) => handleValueChange(e.target.value)} displayEmpty>
              <MenuItem value="">
                <em>{t('selectValue')}</em>
              </MenuItem>
              <MenuItem value="true">{t('true')}</MenuItem>
              <MenuItem value="false">{t('false')}</MenuItem>
            </Select>
          </FormControl>
        );

      case 'text':
        return (
          <FormControl fullWidth size="small">
            <TextField
              value={filter.value ?? ''}
              onChange={(e) => handleValueChange(e.target.value)}
              placeholder={(filter.operator === 'like' ? t('enterSearchText') : t('enterExactValue')) as string}
              size="small"
              InputProps={{
                endAdornment: filter.value && (
                  <IconButton size="small" onClick={() => handleValueChange('')} edge="end">
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </FormControl>
        );

      default:
        return (
          <FormControl fullWidth size="small">
            <TextField value={filter.value ?? ''} onChange={(e) => handleValueChange(e.target.value)} placeholder={t('enterValue') as string} size="small" />
          </FormControl>
        );
    }
  };

  const renderFilterRow = (filter: FilterDto, path: number[], filterIndex: number) => {
    const column = availableColumns.find((col) => col.tableName === filter.tableName && col.columnName === filter.columnName);

    if (!column) return null;

    const getDataType = (columnType: string): string => {
      const type = columnType.toLowerCase();
      if (type.includes('timestamp')) return 'timestamp';
      if (type.includes('text') || type.includes('char') || type.includes('varchar')) return 'text';
      if (type.includes('int') || type.includes('decimal') || type.includes('numeric')) return 'numeric';
      if (type.includes('bool')) return 'boolean';
      return type;
    };

    const dataType = getDataType(column.dataType);
    const compoundInfo = COMPOUND_FILTERS[dataType];

    return (
      <Box className="flex items-center gap-2" key={`${path.join('-')}-filter-${filterIndex}`}>
        <Typography variant="body2">{column.alias || `${column.tableName}.${column.columnName}`}</Typography>
        <FormControl size="small" className="min-w-[150px]">
          <Select value={filter.operator} onChange={(e) => handleFilterChange(path, filterIndex, 'operator', e.target.value as Operator)}>
            {compoundInfo?.options.operators.map((op) => (
              <MenuItem key={op.value} value={op.value}>
                {t(op.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {renderFilterInput(filter, path, filterIndex)}

        <IconButton size="small" onClick={() => handleRemoveFilter(path, filterIndex)} color="error">
          <DeleteOutlineIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const renderGroup = (group: FilterGroup, path: number[] = []) => {
    const hasMultipleItems = (group.filters.length || 0) + (group.subGroups?.length || 0) > 1;

    return (
      <Box key={path.join('-')} className="border rounded p-4 ml-4 mt-2" style={{ borderLeft: '2px solid #ccc', marginLeft: path.length * 20 }}>
        <Box className="flex justify-between items-center mb-3 space-x-3">
          {hasMultipleItems && (
            <FormControl size="small" className="w-24">
              <Select value={group.intraGroupOperator} onChange={(e) => handleIntraGroupOperatorChange(path, e.target.value as 'AND' | 'OR')}>
                <MenuItem value="AND">{t('and')}</MenuItem>
                <MenuItem value="OR">{t('or')}</MenuItem>
              </Select>
            </FormControl>
          )}
          <Box className="flex items-center justify-between w-full">
            <FormControl size="small" className="ml-2">
              <InputLabel>{t('addFilter')}</InputLabel>
              <Select value="" className="w-32" label={t('addFilter')} onChange={(e) => handleAddFilterToGroup(path, e.target.value)}>
                {availableColumns.map((col) => (
                  <MenuItem key={`${col.tableName}.${col.columnName}`} value={`${col.tableName}.${col.columnName}`}>
                    {col.alias || `${col.tableName}.${col.columnName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box className="flex items-center">
              <Button size="small" color="info" onClick={() => handleAddNewGroup(path)} startIcon={<AddIcon />}>
                {t('addGroup')}
              </Button>

              {path.length > 0 && (
                <IconButton size="small" onClick={() => handleRemoveGroup(path)} color="error">
                  <DeleteOutlineIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          </Box>
        </Box>

        {group.filters.map((filter, index) => (
          <Box key={index}>
            {index > 0 && (
              <Typography variant="body2" color="textSecondary" className="!my-2">
                {group.intraGroupOperator}
              </Typography>
            )}
            {renderFilterRow(filter, path, index)}
          </Box>
        ))}

        {group.subGroups?.map((subGroup, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <Typography variant="body2" color="textSecondary" className="!my-2">
                {group.intraGroupOperator}
              </Typography>
            )}
            {renderGroup(subGroup, [...path, index])}
          </React.Fragment>
        ))}
      </Box>
    );
  };

  return (
    <Box className="space-y-4">
      {filterGroups.length > 0 && (
        <Box>
          <Typography variant="h6">{t('filterLogic')}</Typography>
          <Typography variant="body1">{buildLogicString(filterGroups, interGroupOperators)}</Typography>
        </Box>
      )}
      {filterGroups.map((group, index) => (
        <Box key={index}>
          {index > 0 && (
            <FormControl size="small" className="!mb-4">
              <Select value={interGroupOperators[index - 1]} onChange={(e) => handleInterGroupOperatorChange(index - 1, e.target.value as 'AND' | 'OR')}>
                <MenuItem value="AND">{t('and')}</MenuItem>
                <MenuItem value="OR">{t('or')}</MenuItem>
              </Select>
            </FormControl>
          )}
          {renderGroup(group, [index])}
        </Box>
      ))}

      {availableColumns.length > 0 ? (
        <Box className="mt-4">
          <Button variant="contained" color="info" startIcon={<AddIcon />} onClick={() => handleAddNewGroup([])}>
            {t('addNewFilterGroup')}
          </Button>
        </Box>
      ) : (
        <Box className="mt-4">{t('noFilterableColumns')}</Box>
      )}
    </Box>
  );
};

export default FilterCreation;
