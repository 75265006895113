import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../store';
import { createContext, useState } from 'react';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export interface UnsavedChangesContextType {
  isDirty: boolean;
  setIsDirty: (dirty: boolean) => void;
  pendingPath: string | null;
  setPendingPath: (path: string | null) => void;
  showPrompt: boolean;
  setShowPrompt: (show: boolean) => void;
}

export const useUnsavedChangesContext = () => {
  const [isDirty, setIsDirty] = useState(false);
  const [pendingPath, setPendingPath] = useState<string | null>(null);
  const [showPrompt, setShowPrompt] = useState(false);

  return {
    isDirty,
    setIsDirty,
    pendingPath,
    setPendingPath,
    showPrompt,
    setShowPrompt,
  };
};

export const UnsavedChangesContext = createContext<UnsavedChangesContextType | null>(null);
