import { useAppDispatch, useAppSelector } from '@hooks';
import MaterialTable, { OrderByCollection } from '@material-table/core';
import { Button, Fab, Grid, IconButton, InputAdornment, Paper, TablePagination, TextField, Tooltip, Typography } from '@mui/material';
import React, { createRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TableIcons } from '../shared';
import { useState } from 'react';
import { useGetTeamsQuery, useGetOwnerTeamsQuery, useGetTeamRolesQuery } from '@apis';
import { tableConstants, RoutePath, SearchType } from '@constants';
import { useNavigate } from 'react-router';
import { useDebounce } from 'use-debounce';
import AddTeamForm from './AddTeamForm';
import { SortConfig, TeamDetailedItem } from '@interfaces';
import { setChangeActiveTeamStatus } from '@slices';
import { useAuth } from 'oidc-react';
import { AssignManagedTeamModal } from '@components';

type Props = {
  teamId: number;
  currentTeam: TeamDetailedItem;
};

const TeamManagedTeams = ({ teamId, currentTeam }: Props): JSX.Element => {
  const { t } = useTranslation('pano');
  const creatableTeamTypes = currentTeam ? (currentTeam.creatableTeamTypes ? currentTeam.creatableTeamTypes.map((value) => value) : []) : [];
  const tableRef = createRef();
  const [rowsPerPage, setRowsPerPage] = useState<number>(tableConstants.pageRowSize);
  const [page, setPage] = useState<number>(0);
  const [dialogTeam, setDialogTeam] = useState<boolean>(false);
  const [searchTeam, setSearchTeam] = useState<string>('');
  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);
  const [debouncedSearchTeam] = useDebounce(searchTeam, 200);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { totalResults, teams, teamRoles } = useAppSelector((state) => state.team);
  const { userRights, activeTeam } = useAppSelector((state) => state.app);
  const { userData } = useAuth();
  const { teamMemberships } = useAppSelector((state) => state.user);
  const [showManagedAddButton, setShowManagedAddButton] = useState<boolean>(false);
  const [assignModalOpen, setAssignModalOpen] = useState<boolean>(false);
  const shouldFetch = !userData && userRights.isSysAdmin;
  const { isLoading, refetch } = useGetTeamsQuery(
    {
      pageNo: page,
      pageSize: rowsPerPage,
      searchTerm: debouncedSearchTeam,
      parentTeamId: teamId,
      searchType: SearchType.MANAGED_TEAMS,
      sortField: sortConfig?.field,
      sortDirection: sortConfig?.direction,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !shouldFetch,
    },
  );
  const { isLoading: isOwnerTeamsLoading } = useGetOwnerTeamsQuery(
    {
      pageNo: page,
      pageSize: rowsPerPage,
      searchTerm: debouncedSearchTeam,
      parentTeamId: teamId,
      searchType: SearchType.MANAGED_TEAMS,
      sortField: sortConfig?.field,
      sortDirection: sortConfig?.direction,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: shouldFetch,
    },
  );
  const { isSuccess, refetch: refetchOwnerTeams } = useGetTeamRolesQuery({ teamId }, { refetchOnMountOrArgChange: true });

  const handleTeamsRefetch = (): void => {
    if (shouldFetch) {
      refetch();
    } else {
      refetchOwnerTeams();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const role = teamRoles.find((r) => r.name === 'Managed Team Creator');
      if (role) {
        const member = role.members.find((m) => m.id === parseInt(userData?.profile.sub ?? '0'));
        if (member) {
          setShowManagedAddButton(true);
        }
      }
    }
  }, [isSuccess, teamRoles]);

  const navigateToTeam = (id: number, _name: string): void => {
    dispatch(setChangeActiveTeamStatus(false));
    navigate(RoutePath.TeamPath.replace(':teamId', id.toString()).replace(':tabName', ''), { state: { changeActiveTeam: false } });
  };

  useEffect(() => {
    if (debouncedSearchTeam !== null || debouncedSearchTeam !== undefined || debouncedSearchTeam !== '') {
      setPage(0);
    }
  }, [debouncedSearchTeam]);

  const handleChangePage = (newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value: number): void => {
    setRowsPerPage(value);
    setPage(0);
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearchTeam(e.target.value);
  };

  const closeTeam = (): void => {
    setDialogTeam(false);
  };

  const isDisabled = (): boolean | undefined => {
    if (currentTeam) {
      let enabled = true;

      if (currentTeam.id !== activeTeam?.id) {
        enabled = userRights.isSysAdmin || (!userRights.isSysAdmin && currentTeam.parentCanAdministerChildren);
      }

      return !enabled;
    }
  };

  const buildTableAction = (rowData: any) => {
    const teamMembership = teamMemberships.find((x) => x.id == rowData.id);

    if (userRights.isSysAdmin || !teamMembership || !teamMembership.userMustEnableMfa) {
      return {
        icon: TableIcons.KeyboardArrowRight,
        tooltip: t('navigateToTeam'),
        onClick: (_event: any, rowData: any) => {
          navigateToTeam(rowData.id, rowData.name);
        },
        disabled: false,
      };
    }

    return {
      icon: TableIcons.LockIcon,
      tooltip: t('EnableMfaTooltip'),
      onClick: () => {
        navigate(RoutePath.UserMfaPath);
      },
      disabled: isDisabled(),
    };
  };

  const tableComponents = {
    Groupbar: () => <div></div>,
    Pagination: (props: any) => (
      <TablePagination
        {...props}
        rowsPerPageOptions={tableConstants.rowsPerPageArrayOptions}
        rowsPerPage={rowsPerPage}
        count={totalResults}
        page={page}
        onPageChange={(_e, page) => handleChangePage(page)}
      />
    ),

    Action: (props: any) => {
      return (
        <Tooltip title={t('navigateToTeam')}>
          <IconButton className="!w-20 flex justify-center" onClick={() => navigateToTeam(props.data.id, props.data.name)}>
            <TableIcons.KeyboardArrowRight />
          </IconButton>
        </Tooltip>
      );
    },
  };

  const handleSort = (orderByCollection: OrderByCollection[]): void => {
    if (orderByCollection && orderByCollection.length > 0) {
      const primarySort = orderByCollection[0];
      setSortConfig({
        field: primarySort.orderByField,
        direction: primarySort.orderDirection.toLowerCase() as 'asc' | 'desc',
      });
    } else {
      setSortConfig(null);
    }
    setPage(0);
  };

  return (
    <Paper className="w-full p-5" square>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom>
              {t('managedTeamsTitle')}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {t('managedTeamsSubText')}
            </Typography>
          </Grid>
          {userRights.isSysAdmin && (
            <Grid item xs={4} className="flex justify-end items-start">
              <Button variant="contained" color="info" onClick={() => setAssignModalOpen(true)} disabled={isLoading || isOwnerTeamsLoading}>
                {t('assignExistingTeam')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container item xs={12} sm={12} md={12} lg={12}>
        <Paper className="w-full py-2 px-4 !rounded-none">
          <Grid container item xs={12} className="items-center min-h-[40px]">
            <Grid item xs={4}>
              <Typography variant="h5">{t('teamsText')}</Typography>
            </Grid>
            <Grid item xs={8} className="flex justify-end space-x-2 items-center">
              <TextField
                className="max-w-xs"
                variant="standard"
                id="search-teams-input"
                fullWidth={true}
                placeholder={t('searchTeams') as string}
                onChange={(e) => handleSearchInput(e)}
                value={searchTeam}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TableIcons.Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="clear" disabled={!searchTeam} onClick={() => setSearchTeam('')} edge="end">
                        <TableIcons.ResetSearch />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {(userRights.isSysAdmin || showManagedAddButton) && (
                <Fab
                  className="shrink-0 !z-0"
                  color="primary"
                  size="medium"
                  aria-label="add"
                  onClick={() => setDialogTeam(true)}
                  disabled={isLoading || isOwnerTeamsLoading}>
                  <TableIcons.Add />
                </Fab>
              )}
            </Grid>
          </Grid>
        </Paper>
        <Grid item xs={12}>
          <MaterialTable
            icons={TableIcons}
            tableRef={tableRef}
            isLoading={isLoading || isOwnerTeamsLoading}
            options={{
              sorting: false,
              actionsColumnIndex: -1,
              search: false,
              grouping: false,
              pageSize: rowsPerPage,
              pageSizeOptions: tableConstants.rowsPerPageArrayOptions,
              draggable: false,
              showTitle: false,
              toolbar: false,
            }}
            onOrderCollectionChange={handleSort}
            columns={[
              { title: t('id'), field: 'id' },
              { title: t('name'), field: 'name' },
              { title: t('owner'), field: 'owner' },
              {
                title: t('active'),
                field: 'active',
                type: 'boolean',
                align: 'center',
                render: (rowData) => (rowData.active ? <TableIcons.Tick /> : <TableIcons.Cross />),
              },
              { title: t('type'), field: 'type', defaultGroupOrder: -1 },
            ]}
            data={teams}
            //@ts-ignore
            actions={[(rowData) => buildTableAction(rowData)]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            components={tableComponents}
          />
        </Grid>
      </Grid>
      <AddTeamForm closeDialog={closeTeam} isDialogOpen={dialogTeam} teamTypes={creatableTeamTypes} />
      <AssignManagedTeamModal isOpen={assignModalOpen} currentTeam={currentTeam} onClose={() => setAssignModalOpen(false)} refetch={handleTeamsRefetch} />
    </Paper>
  );
};

export default TeamManagedTeams;
