import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Chip } from '@mui/material';
import MaterialTable from '@material-table/core';
import { ScheduleRun } from '@interfaces';
import { useTranslation } from 'react-i18next';

interface ScheduleHistoryDialogProps {
  open: boolean;
  onClose: () => void;
  scheduleHistory: ScheduleRun[];
}

const ScheduleHistoryDialog: React.FC<ScheduleHistoryDialogProps> = ({ open, onClose, scheduleHistory }) => {
  const { t } = useTranslation('pano');

  const calculateDuration = (startTime: string, endTime?: string): string => {
    const start = new Date(startTime);
    const end = endTime ? new Date(endTime) : new Date();
    const diff = Math.round((end.getTime() - start.getTime()) / 1000);
    return diff + ' ' + t('durationSeconds');
  };

  return (
    <Dialog
      open={open}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose();
        }
      }}
      maxWidth="md"
      fullWidth>
      <DialogTitle>{t('scheduleHistory')}</DialogTitle>
      <DialogContent>
        <MaterialTable
          columns={[
            {
              title: t('runTime'),
              render: (rowData: ScheduleRun) => new Date(rowData.startTime).toLocaleString(),
            },
            {
              title: t('status'),
              render: (rowData: ScheduleRun) => (
                <Chip label={t(rowData.status.toLowerCase())} color={rowData.status === 'SUCCESS' ? 'success' : 'error'} size="small" />
              ),
            },
            {
              title: t('records'),
              field: 'recordCount',
            },
            {
              title: t('emailSent'),
              field: 'emailSent',
              type: 'boolean',
            },
            {
              title: t('duration'),
              render: (rowData: ScheduleRun) => calculateDuration(rowData.startTime, rowData.endTime),
            },
          ]}
          data={scheduleHistory}
          options={{
            search: false,
            paging: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 25],
            padding: 'dense',
            headerStyle: {
              fontWeight: 'bold',
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: t('noHistoryAvailable'),
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleHistoryDialog;
