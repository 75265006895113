import React from 'react';
import { Box, Typography, Paper, FormControl, Select, MenuItem, FormHelperText, IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import { QueryType, ReportColumnDto } from '@interfaces';
import { useTranslation } from 'react-i18next';

interface QueryTypeTabProps {
  queryType: QueryType;
  selectedColumns: ReportColumnDto[];
  onQueryTypeChange: (newQueryType: QueryType) => void;
}

const QueryTypeTab: React.FC<QueryTypeTabProps> = ({ queryType, selectedColumns, onQueryTypeChange }) => {
  const { t } = useTranslation('pano');

  const handleMainTypeChange = (value: 'SELECT' | 'COUNT') => {
    onQueryTypeChange({ type: value });
  };

  const handleAggregationChange = (columnIndex: number, value: string) => {
    const newAggs = [...(queryType.columnAggregations || [])];
    const column = selectedColumns[columnIndex];
    const otherColumns = selectedColumns.filter((c, i) => i !== columnIndex).map((c) => `${c.tableName}.${c.columnName}`);

    if (value === 'NONE') {
      const existingIndex = newAggs.findIndex((a) => a.tableName === column.tableName && a.columnName === column.columnName);
      if (existingIndex > -1) {
        newAggs.splice(existingIndex, 1);
      }
    } else {
      const agg = {
        tableName: column.tableName,
        columnName: column.columnName,
        aggregateFunction: value as 'COUNT' | 'SUM' | 'AVG' | 'MIN' | 'MAX',
      };
      const existingIndex = newAggs.findIndex((a) => a.tableName === column.tableName && a.columnName === column.columnName);
      if (existingIndex > -1) {
        newAggs[existingIndex] = agg;
      } else {
        newAggs.push(agg);
      }
    }

    const updatedGroupBy = newAggs.length > 0 ? otherColumns : [];

    onQueryTypeChange({
      ...queryType,
      columnAggregations: newAggs,
      groupByColumns: updatedGroupBy,
    });
  };

  const handleAddOrderBy = () => {
    if (selectedColumns.length === 0) return;

    onQueryTypeChange({
      ...queryType,
      orderBy: [
        ...(queryType.orderBy || []),
        {
          tableName: selectedColumns[0].tableName,
          columnName: selectedColumns[0].columnName,
          direction: 'ASC',
        },
      ],
    });
  };

  const handleRemoveOrderBy = (index: number) => {
    const newOrderBy = queryType?.orderBy?.filter((_, i) => i !== index) || [];
    onQueryTypeChange({ ...queryType, orderBy: newOrderBy });
  };

  const handleOrderByChange = (index: number, field: 'column' | 'direction', value: string) => {
    const newOrderBy = [...(queryType.orderBy || [])];
    if (field === 'column') {
      const [tableName, columnName] = value.split('.');
      newOrderBy[index] = { ...newOrderBy[index], tableName, columnName };
    } else {
      newOrderBy[index] = { ...newOrderBy[index], direction: value as 'ASC' | 'DESC' };
    }
    onQueryTypeChange({ ...queryType, orderBy: newOrderBy });
  };

  if (selectedColumns.length === 0) {
    return (
      <Paper variant="outlined" className="p-8 text-center">
        <Typography color="textSecondary" className="mb-2">
          {t('noColumnsSelected')}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {t('selectColumnsPrompt')}
        </Typography>
      </Paper>
    );
  }

  const renderMainQueryType = () => (
    <Paper variant="outlined" className="p-4">
      <Typography variant="subtitle1" className="mb-3">
        {t('mainQueryType')}
      </Typography>
      <FormControl fullWidth size="small">
        <Select value={queryType?.type || 'SELECT'} onChange={(e) => handleMainTypeChange(e.target.value as 'SELECT' | 'COUNT')}>
          <MenuItem value="SELECT">{t('selectWithAggregations')}</MenuItem>
          <MenuItem value="COUNT">{t('countAllRecords')}</MenuItem>
        </Select>
        <FormHelperText>{t('chooseQueryType')}</FormHelperText>
      </FormControl>
    </Paper>
  );

  const renderColumnSettings = () =>
    queryType?.type === 'SELECT' && (
      <Paper variant="outlined" className="p-4">
        <Typography variant="subtitle1" className="mb-3">
          {t('columnSettings')}
        </Typography>
        {selectedColumns.map((col, index) => (
          <Box key={`${col.tableName}-${col.columnName}`} className="flex items-center justify-between gap-2 mb-2">
            <Typography className="min-w-[200px]">{col.alias || `${col.tableName}.${col.columnName}`}</Typography>
            <FormControl size="small" className="min-w-[150px]">
              <Select
                value={
                  queryType?.columnAggregations?.find((a) => a.tableName === col.tableName && a.columnName === col.columnName)?.aggregateFunction || 'NONE'
                }
                onChange={(e) => handleAggregationChange(index, e.target.value)}>
                <MenuItem value="NONE">{t('noAggregation')}</MenuItem>
                <MenuItem value="COUNT">{t('count')}</MenuItem>
                <MenuItem value="SUM">{t('sum')}</MenuItem>
                <MenuItem value="AVG">{t('average')}</MenuItem>
                <MenuItem value="MIN">{t('minimum')}</MenuItem>
                <MenuItem value="MAX">{t('maximum')}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        ))}
      </Paper>
    );

  const renderOrderBySection = () => (
    <Paper variant="outlined" className="p-4">
      <Box className="flex justify-between items-center mb-3">
        <Typography variant="subtitle1">{t('orderBy')}</Typography>
        <IconButton size="small" onClick={handleAddOrderBy}>
          <AddIcon />
        </IconButton>
      </Box>

      {queryType?.orderBy?.map((orderBy, index) => (
        <Box key={index} className="flex items-center gap-2 mb-2">
          <FormControl size="small" fullWidth>
            <Select value={`${orderBy.tableName}.${orderBy.columnName}`} onChange={(e) => handleOrderByChange(index, 'column', e.target.value)}>
              {selectedColumns.map((col) => (
                <MenuItem key={`${col.tableName}.${col.columnName}`} value={`${col.tableName}.${col.columnName}`}>
                  {col.alias || `${col.tableName}.${col.columnName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl size="small">
            <Select value={orderBy.direction} onChange={(e) => handleOrderByChange(index, 'direction', e.target.value)}>
              <MenuItem value="ASC">{t('ascending')}</MenuItem>
              <MenuItem value="DESC">{t('descending')}</MenuItem>
            </Select>
          </FormControl>

          <IconButton size="small" onClick={() => handleRemoveOrderBy(index)}>
            <DeleteOutlineIcon />
          </IconButton>
        </Box>
      ))}
    </Paper>
  );

  const renderQueryPreview = () => {
    const previewText = generateQueryPreview(queryType, selectedColumns);

    return (
      <Paper variant="outlined" className="p-4">
        <Box>
          <Typography variant="subtitle1" className="mb-2">
            {t('queryPreview')}
          </Typography>
          <Typography variant="body2" className="font-mono p-2 rounded">
            {previewText}
          </Typography>
        </Box>
      </Paper>
    );
  };

  return (
    <Box className="space-y-4">
      {renderMainQueryType()}
      {renderColumnSettings()}
      {renderOrderBySection()}
      {renderQueryPreview()}
    </Box>
  );
};

const generateQueryPreview = (queryType: QueryType, selectedColumns: ReportColumnDto[]): string => {
  if (queryType.type === 'COUNT') {
    return 'SELECT COUNT(*) FROM ...';
  }

  let preview = 'SELECT ';
  const selectParts = [] as any;

  selectedColumns.forEach((col) => {
    const agg = queryType.columnAggregations?.find((a) => a.tableName === col.tableName && a.columnName === col.columnName);
    if (!agg) {
      selectParts.push(`${col.tableName}.${col.columnName}`);
    }
  });

  queryType.columnAggregations?.forEach((agg) => {
    selectParts.push(`${agg.aggregateFunction}(${agg.tableName}.${agg.columnName})`);
  });

  preview += selectParts.join(', ');

  if (queryType.groupByColumns?.length) {
    preview += '\nGROUP BY ' + queryType.groupByColumns.join(', ');
  }

  if (queryType.orderBy?.length) {
    preview += '\nORDER BY ' + queryType.orderBy.map((o) => `${o.tableName}.${o.columnName} ${o.direction}`).join(', ');
  }

  return preview;
};

export default QueryTypeTab;
