import React, { useEffect, useMemo, useState } from 'react';
import { useGetStepLayoutQuery } from '@apis';
import { useAppDispatch, useAppSelector } from '@hooks';
import { ViewLayoutPutDto, ViewModelType, WorkflowStepDto } from '@interfaces';
import { Grid, Button, Paper, TextField, IconButton } from '@mui/material';
import GridLayout, { WidthProvider, Layout } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { xml2js, js2xml } from 'xml-js';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { red } from '@constants';
import { addOrUpdateStepLayout, clearPendingAction, updateSaveStepLayout } from '@slices';
import { decompressAndDecode, encodeAndCompress } from '@utils';

type Props = {
  step: WorkflowStepDto;
  isTabOpen: boolean;
  existingLayout?: ViewLayoutPutDto;
  onUnsavedChanges: (haschanges: boolean) => void;
};

type CustomLayout = Layout & {
  section: string;
};

const MAX_COLS = 6;

const StepLayout = ({ step, isTabOpen, existingLayout, onUnsavedChanges }: Props) => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const { activeTeam } = useAppSelector((x) => x.app);

  const ResponsiveGridLayout = useMemo(() => WidthProvider(GridLayout), []);

  const { isSuccess } = useGetStepLayoutQuery(
    { layoutType: 'DataView', stepId: step.id, stepVersion: step.version, teamId: activeTeam?.id ?? 1 },
    {
      refetchOnMountOrArgChange: true,
      skip: !isTabOpen || !!existingLayout,
    },
  );

  const embededMedia = step.stepConfigurations
    .filter((x) => x.mediaConfiguration.viewModelType === ViewModelType.Embedded)
    .sort((a, b) => a.mediaConfiguration.ascendingDisplayOrderScore - b.mediaConfiguration.ascendingDisplayOrderScore);
  const emptyLayout = embededMedia.map((item, index) => ({
    i: `EI${item.mediaConfiguration.visualId.replace(/-/g, '')}`,
    x: 0,
    y: index,
    w: 6,
    h: 1,
    section: 'default',
  })) as CustomLayout[];

  const [layout, setLayout] = useState<CustomLayout[]>([]);
  const [description, setDescription] = useState<string>('<Empty Layout>');
  const [sections, setSections] = useState<string[]>(['default']);
  const [newSectionName, setNewSectionName] = useState<string>('');
  const [changeCounter, setChangeCounter] = useState<number>(0);

  const { stepLayout, saveStepLayout } = useAppSelector((x) => x.mahon);

  useEffect(() => {
    if (stepLayout && isSuccess) {
      try {
        const decodedXml = decompressAndDecode(stepLayout.xml);
        const parsedLayout = parseXmlToLayout(decodedXml);
        setLayout(parsedLayout);
        setDescription(stepLayout.description ?? '<Empty Layout>');
        setSections(processSectionsWithDefaults(parsedLayout));
      } catch (error) {
        console.error('Error decoding and parsing layout:', error);
        setLayout(emptyLayout);
      }
    } else if (existingLayout) {
      try {
        const decodedXml = decompressAndDecode(existingLayout.xml);
        const parsedLayout = parseXmlToLayout(decodedXml);
        setLayout(parsedLayout);
        setDescription(existingLayout.description ?? '<Empty Layout>');
        setSections(processSectionsWithDefaults(parsedLayout));
      } catch (error) {
        console.error('Error decoding and parsing layout:', error);
        setLayout(emptyLayout);
      }
    }
    setChangeCounter(0);
  }, [stepLayout, isSuccess, step]);

  useEffect(() => {
    if (saveStepLayout) {
      handleSaveLayout();
      dispatch(updateSaveStepLayout(false));
    }
  }, [saveStepLayout]);

  const processSectionsWithDefaults = (parsedLayout: CustomLayout[]): string[] => {
    const uniqueSections = Array.from(new Set(parsedLayout.map((item) => item.section)));

    const processedSections: string[] = [];
    let defaultCounter = 0;

    uniqueSections.forEach((section, index) => {
      if (!section.startsWith('default')) {
        if (index === 0 || !processedSections[processedSections.length - 1].startsWith('default')) {
          processedSections.push(`default-${defaultCounter}-top`);
          defaultCounter++;
        }
        processedSections.push(section);
        if (index === uniqueSections.length - 1 || !uniqueSections[index + 1].startsWith('default')) {
          processedSections.push(`default-${defaultCounter}-bottom`);
          defaultCounter++;
        }
      } else {
        processedSections.push(section);
      }
    });
    if (processedSections.length === 0) {
      processedSections.push('default');
    }

    return processedSections;
  };

  const parseXmlToLayout = (xml: string): CustomLayout[] => {
    try {
      const jsonObj = xml2js(xml, { compact: true }) as any;
      const sections = Array.isArray(jsonObj.Layout.Section) ? jsonObj.Layout.Section : [jsonObj.Layout.Section];
      const elements = sections.flatMap((section: any, sectionIndex: number) => {
        const sectionName = section._attributes?.Header ?? `default-${sectionIndex}`;
        return (Array.isArray(section.Element) ? section.Element : [section.Element]).map((element: any, index: number) => ({
          i: element._attributes.Id,
          x: parseInt(element._attributes.Column || '0', 10),
          y: parseInt(element._attributes.Row || '0', 10),
          w: Math.min(parseInt(element._attributes.ColumnSpan || '1', 10), MAX_COLS),
          h: 1,
          section: sectionName,
        }));
      });
      return elements;
    } catch (error) {
      console.log(error);
      return emptyLayout;
    }
  };

  const convertLayoutToXml = (layout: CustomLayout[]): string => {
    const groupedLayout = layout.reduce((acc, item) => {
      if (!acc[item.section]) acc[item.section] = [];
      acc[item.section].push(item);
      return acc;
    }, {} as Record<string, CustomLayout[]>);

    const sections = Object.entries(groupedLayout).map(([sectionKey, items]) => {
      const isDefault = sectionKey.startsWith('default');

      const columns = Array(MAX_COLS).fill('*').join(', ');
      const maxRow = Math.max(...items.map((item) => (item.y || 0) + 1));
      const rows = Array(maxRow).fill('Auto').join(', ');

      if (isDefault) {
        return {
          Section: {
            ...(items.length > 1 ? { _attributes: { Columns: columns, Rows: rows } } : {}),
            Element: items.map((item) => ({
              _attributes: {
                Id: item.i,
                Row: item.y.toString(),
                Column: item.x.toString(),
                ColumnSpan: item.w.toString(),
              },
            })),
          },
        };
      } else {
        return {
          Section: {
            _attributes: {
              Type: 'Group',
              Header: sectionKey,
              Columns: columns,
              Rows: rows,
            },
            Element: items.map((item) => ({
              _attributes: {
                Id: item.i,
                Row: item.y.toString(),
                Column: item.x.toString(),
                ColumnSpan: item.w.toString(),
              },
            })),
          },
        };
      }
    });

    const xmlObj = {
      Layout: {
        _attributes: { Version: '2' },
        Section: sections.length === 1 ? sections[0].Section : sections.map((s) => s.Section),
      },
    };

    try {
      const xml = js2xml(xmlObj, { compact: true, spaces: 2 });
      return xml;
    } catch (error) {
      console.error('Error converting to XML:', error);
      return '';
    }
  };

  const handleSaveLayout = () => {
    if (layout.length > 0) {
      const xml = convertLayoutToXml(layout);
      const compressedLayout = encodeAndCompress(xml);
      dispatch(
        addOrUpdateStepLayout({
          stepId: step.id,
          layoutType: 'DataView',
          description: description,
          xml: compressedLayout,
        }),
      );
      onUnsavedChanges(false);
      dispatch(clearPendingAction());
    }
  };

  const handleLayoutChange = (newLayout: Layout[], sectionName: string) => {
    setLayout((prevLayout) => {
      const updatedLayout = prevLayout.filter((item) => item.section !== sectionName);
      const sectionItems = newLayout.map((item) => ({
        ...item,
        section: sectionName,
      }));

      const sectionIndex = prevLayout.findIndex((item) => item.section === sectionName);
      if (sectionIndex === -1) {
        return [...updatedLayout, ...sectionItems];
      } else {
        return [...updatedLayout.slice(0, sectionIndex), ...sectionItems, ...updatedLayout.slice(sectionIndex)];
      }
    });
    if (changeCounter === 0) setChangeCounter(changeCounter + 1);
    else onUnsavedChanges(true);
  };

  const addDefaultSections = (sections: string[]): string[] => {
    let newSections: string[] = [];
    sections.forEach((section, index) => {
      if (!section.startsWith('default') && (index === 0 || !sections[index - 1].startsWith('default'))) {
        newSections.push(`default-${index}-top`);
      }
      newSections.push(section);
      if (!section.startsWith('default') && (index === sections.length - 1 || !sections[index + 1].startsWith('default'))) {
        newSections.push(`default-${index}-bottom`);
      }
    });
    return newSections;
  };

  const handleAddSection = () => {
    if (newSectionName && !sections.includes(newSectionName)) {
      setSections((prevSections) => {
        const updatedSections = [...prevSections, newSectionName];
        return addDefaultSections(updatedSections);
      });
      setNewSectionName('');
      onUnsavedChanges(true);
    }
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const { source, destination } = result;

    setLayout((prevLayout) => {
      const newLayout = Array.from(prevLayout);
      const sourceIndex = newLayout.findIndex((item) => item.i === result.draggableId);
      const [movedItem] = newLayout.splice(sourceIndex, 1);

      if (source.droppableId !== destination.droppableId) {
        movedItem.section = destination.droppableId;
      }

      let insertIndex = 0;
      let itemsInDestinationSection = 0;
      for (let i = 0; i < newLayout.length; i++) {
        if (newLayout[i].section === destination.droppableId) {
          if (itemsInDestinationSection === destination.index) {
            insertIndex = i;
            break;
          }
          itemsInDestinationSection++;
        }
        if (i === newLayout.length - 1) {
          insertIndex = newLayout.length;
        }
      }

      newLayout.splice(insertIndex, 0, movedItem);

      return newLayout;
    });
  };

  const handleDeleteSection = (sectionToDelete: string): void => {
    const newDefaultName = `default-${sectionToDelete.toLowerCase()}-deleted`;
    const newSections = sections.reduce((acc, section, index) => {
      if (index > 0 && sections[index - 1].startsWith('default') && section === sectionToDelete) {
        acc[acc.length - 1] = newDefaultName;
      } else if (section === sectionToDelete) {
        acc.push(newDefaultName);
      } else if (sections[index - 1] === sectionToDelete && section.startsWith('default')) {
      } else {
        acc.push(section);
      }
      return acc;
    }, [] as string[]);

    setLayout((prevLayout) => {
      return prevLayout.map((item) => {
        if (item.section === sectionToDelete || !newSections.includes(item.section)) {
          return { ...item, section: newDefaultName };
        }
        return item;
      });
    });

    setSections(newSections);
  };

  return (
    <Grid container>
      <Grid container item xs={12} className="flex items-center">
        <Grid item xs={8} className="pr-2">
          <TextField
            name="description"
            autoComplete="nope"
            label={t('description')}
            placeholder={t('description') as string}
            fullWidth
            value={description}
            onChange={(e) => {
              onUnsavedChanges(true);
              setDescription(e.currentTarget.value);
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={4} className="flex justify-end">
          <Button variant="contained" color="primary" onClick={handleSaveLayout}>
            {t('submitButtonText')}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} className="py-6">
        <DragDropContext onDragEnd={handleDragEnd}>
          {sections.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              <Droppable droppableId={section} direction="vertical">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} className={section.startsWith('default') ? '' : 'border p-2'}>
                    {!section.startsWith('default') && (
                      <div className="flex items-center justify-between">
                        <h3>{section}</h3>
                        <IconButton size="small" className="flex-shrink-0" onClick={() => handleDeleteSection(section)}>
                          <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} size="1x" />
                        </IconButton>
                      </div>
                    )}
                    <ResponsiveGridLayout
                      className="layout min-h-[30px]"
                      layout={layout.filter((item) => item.section === section)}
                      cols={MAX_COLS}
                      rowHeight={50}
                      onLayoutChange={(newLayout) => handleLayoutChange(newLayout, section)}
                      resizeHandles={['e', 'w']}>
                      {layout
                        .filter((item) => item.section === section)
                        .map((element, index) => (
                          <Paper className="border flex items-center" key={element.i}>
                            <Draggable key={element.i} draggableId={element.i} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="w-full px-6 h-12 absolute"></div>
                              )}
                            </Draggable>
                            <div className="flex-grow min-w-0 px-4">
                              <div className="truncate text-xs">
                                {
                                  step.stepConfigurations.find((x) => `EI${x.mediaConfiguration.visualId.replace(/-/g, '')}` === element.i)?.mediaConfiguration
                                    .description
                                }
                              </div>
                            </div>
                          </Paper>
                        ))}
                    </ResponsiveGridLayout>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </DragDropContext>
      </Grid>
      <Grid container item xs={12} className="flex items-center">
        <Grid item xs={8} className="pr-2">
          <TextField
            label={t('newGroupName') as string}
            value={newSectionName}
            onChange={(e) => setNewSectionName(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={4} className="flex justify-end">
          <Button variant="contained" color="info" onClick={handleAddSection} disabled={!newSectionName}>
            {t('add')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StepLayout;
