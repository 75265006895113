import React, { useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Tabs, Tab, Box, TablePagination } from '@mui/material';
import { useAppSelector, useAppDispatch } from '@hooks';
import { clearGeneratedReport } from '@slices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/pro-regular-svg-icons';

const ReportDisplay: React.FC = () => {
  const dispatch = useAppDispatch();
  const { generatedReport } = useAppSelector((state) => state.report);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleClearReport = () => {
    dispatch(clearGeneratedReport());
    setSelectedGroupIndex(0);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const exportToCSV = (data: any[]) => {
    if (data.length === 0) return;

    const headers = Object.keys(data[0]);

    const csvData = [
      headers.join(','),
      ...data.map((row) =>
        headers
          .map((header) => {
            const value = row[header];
            if (value === null || value === undefined) return '';
            if (typeof value === 'object') return JSON.stringify(value);
            if (typeof value === 'string') return `"${value.replace(/"/g, '""')}"`;
            return value;
          })
          .join(','),
      ),
    ].join('\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'report_export.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formatCellValue = (value: any): React.ReactNode => {
    if (value === null || value === undefined) {
      return <em>N/A</em>;
    }

    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }

    if (value instanceof Date) {
      return value.toLocaleString();
    }

    if (typeof value === 'object') {
      return JSON.stringify(value);
    }

    return String(value);
  };

  const renderTable = (data: Record<string, any>[]) => {
    if (!data || data.length === 0) return null;

    const currentPageData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {Object.keys(data[0]).map((key) => (
                  <TableCell key={key}>{key}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageData.map((row, index) => (
                <TableRow key={index}>
                  {Object.values(row).map((value, cellIndex) => (
                    <TableCell key={cellIndex}>{formatCellValue(value)}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );
  };

  const hasData = generatedReport.groups.length > 0 && generatedReport.groups[0].data.length > 0;
  const currentGroup = generatedReport.groups[selectedGroupIndex];

  return (
    <Paper className="mt-4 p-4">
      <Box className="flex justify-between items-center mb-4">
        <Typography variant="h6">Generated Report</Typography>
        {hasData && (
          <Box className="space-x-2">
            <Button variant="contained" color="info" onClick={() => exportToCSV(currentGroup?.data || [])} startIcon={<FontAwesomeIcon icon={faFileExport} />}>
              Export to CSV
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClearReport}>
              Clear Report
            </Button>
          </Box>
        )}
      </Box>

      {generatedReport.isGrouped && hasData && (
        <Box className="mb-4">
          <Tabs value={selectedGroupIndex} onChange={(_, newValue) => setSelectedGroupIndex(newValue)} variant="scrollable" scrollButtons="auto">
            {generatedReport.groups.map((group, index) => (
              <Tab
                key={index}
                label={
                  <Typography variant="body2" component="span">
                    Group {index + 1}: {group.tables.join(', ')}
                  </Typography>
                }
              />
            ))}
          </Tabs>
        </Box>
      )}

      {hasData ? (
        <>
          {currentGroup && renderTable(currentGroup.data)}
          <Typography variant="caption" color="textSecondary" className="mt-2 block">
            Showing {currentGroup?.data.length} records
            {currentGroup?.tables.length > 0 && ` from ${currentGroup.tables.join(', ')}`}
          </Typography>
        </>
      ) : (
        <Typography variant="body2" color="textSecondary" className="py-4 text-center">
          No data to display. Generate a report to see results.
        </Typography>
      )}
    </Paper>
  );
};

export default ReportDisplay;
