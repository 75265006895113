import {
  useDeleteTeamEchoConfigurationMutation,
  useGetTeamEchoConfigurationQuery,
  useGetTeamQuery,
  useGetTeamServicesQuery,
  usePutTeamEchoConfigurationMutation,
} from '@apis';
import { TeamEchoConfigurationClinicalSystemForm } from '@interfaces';
import {
  Button,
  Checkbox,
  CircularProgress,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { openSnackbar, setBreadcrumbs, setHelmet, setTeamNavigationContext } from '@slices';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector, useForm } from '@hooks';

const formInitialValues = {
  type: 'SystmOne',
  uri: '',
  customerNumber: '',
  isTestSystem: false,
};

const TeamEchoConfiguration = (): JSX.Element => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const parms = useParams();
  const teamId = parms?.teamId ?? '';
  const { team, teamServices } = useAppSelector((x) => x.team);
  const { teamEchoConfiguration } = useAppSelector((x) => x.echo);

  useGetTeamQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });
  useGetTeamServicesQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });
  const {
    refetch,
    isLoading: isGettingTeamConfiguration,
    isFetching: isFetchingTeamConfiguration,
    isSuccess,
    isError,
  } = useGetTeamEchoConfigurationQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });
  const [putTeamConfiguration, { isLoading: isUpdatingConfiguration }] = usePutTeamEchoConfigurationMutation();
  const [deleteTeamConfiguration] = useDeleteTeamEchoConfigurationMutation();

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [createStatus, setCreateStatus] = useState(false);

  const handleConfigSubmit = (): void => {
    putTeamConfiguration({ data: formData, teamId: parseInt(teamId) })
      .unwrap()
      .then(() => {
        refetch();
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
      })
      .catch((err) => {
        console.debug('Failed while attempting to update team config echo', err);
        return;
      });
  };

  const { handleSubmit, handleChange, formData, setFormData, errors, resetForm } = useForm<TeamEchoConfigurationClinicalSystemForm>({
    validations: {
      customerNumber: {
        custom: {
          isValid: (value) => {
            if (formData.type === 'EmisWeb') return value ? true : false;
            else return true;
          },
          message: t('fieldIsRequired'),
        },
      },
      uri: {
        custom: {
          isValid: (value) => {
            if (formData.type === 'EmisWeb') return value ? true : false;
            else return true;
          },
          message: t('fieldIsRequired'),
        },
      },
    },
    onSubmit: handleConfigSubmit,
    initialValues: { ...formInitialValues },
  });

  const onReset = (): void => {
    if (teamEchoConfiguration && Object.entries(teamEchoConfiguration).length !== 0) {
      resetForm({
        ...teamEchoConfiguration,
        uri: teamEchoConfiguration.uri ?? '',
        customerNumber: teamEchoConfiguration.customerNumber ?? '',
      });
      setCreateStatus(false);
      setIsCreate(false);
      setIsEdit(false);
    } else {
      resetForm(formInitialValues);
      setCreateStatus(true);
      setIsCreate(false);
      setIsEdit(false);
    }
  };

  const onCreate = (): void => {
    setIsCreate(true);
  };

  const onEdit = (): void => {
    setIsEdit(true);
  };

  const onDelete = () => {
    deleteTeamConfiguration(parseInt(teamId))
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
        setFormData(formInitialValues);
        setCreateStatus(true);
        setIsCreate(false);
        setIsEdit(false);
      })
      .catch((err) => {
        console.debug('Failed while attempting to delete team config eco', err);
        return;
      });
  };

  useEffect(() => {
    if (isSuccess) {
      if (teamEchoConfiguration && Object.entries(teamEchoConfiguration).length !== 0) {
        setFormData({
          ...teamEchoConfiguration,
          uri: teamEchoConfiguration.uri ?? '',
          customerNumber: teamEchoConfiguration.customerNumber ?? '',
        });
        setCreateStatus(false);
        setIsCreate(false);
        setIsEdit(false);
      } else {
        setCreateStatus(true);
        setIsCreate(false);
        setIsEdit(false);
      }
    }
  }, [isSuccess, teamEchoConfiguration]);
  useEffect(() => {
    if (team) {
      dispatch(setBreadcrumbs([{ text: t('teamPageTitle'), link: '/teams' }, { text: team.name, link: `/teams/${teamId}` }, { text: t('configuration') }]));
    }
    dispatch(setHelmet({ title: t('htmlTitleEchoConfiguration') }));
  }, [team]);

  useEffect(() => {
    if (isError) {
      setTimeout(() => navigate(-1), 2500);
    }
  }, [isError]);

  useEffect(() => {
    if (teamServices) {
      dispatch(setTeamNavigationContext({ teamServices, teamId, t }));
    }
  }, [teamServices]);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" gutterBottom>
            {t('teamEditPageTitle')}
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            {t('teamEchoConfigurationPageSubtitle')}
          </Typography>
        </Grid>

        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <Paper className="p-5 w-full space-y-4">
            <Grid container item xs={12} sm={12} md={12} lg={12}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography gutterBottom variant="h6">
                  {t('teamConfigurationClinicalSystemsHeader')}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} className="justify-end flex">
                {isGettingTeamConfiguration ? (
                  <React.Fragment />
                ) : createStatus ? (
                  !isCreate ? (
                    <Button variant="contained" color="primary" disabled={isUpdatingConfiguration} onClick={onCreate}>
                      {t('createText')}
                    </Button>
                  ) : (
                    <React.Fragment />
                  )
                ) : (
                  <React.Fragment>
                    <Button variant="contained" className="!mr-5" color="secondary" type="submit" disabled={isUpdatingConfiguration} onClick={onDelete}>
                      {isUpdatingConfiguration && <CircularProgress size={24} className="circular-button-loader" />}
                      {t('deleteText')}
                    </Button>
                    <Button variant="contained" color="primary" disabled={isUpdatingConfiguration || isEdit} onClick={onEdit}>
                      {isUpdatingConfiguration && <CircularProgress size={24} className="circular-button-loader" />}
                      {t('editText')}
                    </Button>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
            <Grid item className="padding-area-top" xs={12} sm={12} md={12} lg={12}>
              {!createStatus || isCreate ? (
                isGettingTeamConfiguration || isFetchingTeamConfiguration ? (
                  <Fade in={isGettingTeamConfiguration} style={{ transitionDelay: isGettingTeamConfiguration ? '800ms' : '0ms' }} unmountOnExit>
                    <CircularProgress className="circular-button-loader" id="loadingProgress" />
                  </Fade>
                ) : (
                  <form id="echo-config" onSubmit={handleSubmit}>
                    <div>
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="configuration-clinical-type">{t('type')}</InputLabel>
                        <Select
                          id="configuration-clinical-type"
                          label={t('type')}
                          disabled={!(isEdit || isCreate)}
                          value={formData.type}
                          onChange={handleChange}
                          name="type">
                          <MenuItem value={'SystmOne'}>{t('systmOne')}</MenuItem>
                          <MenuItem value={'EmisWeb'}>{t('emis')}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="pt-6">
                      <TextField
                        fullWidth={true}
                        label={t('teamConfigurationClinicalSystemsIP')}
                        disabled={formData.type !== 'EmisWeb' || !(isEdit || isCreate)}
                        variant="outlined"
                        name="uri"
                        value={formData.uri}
                        onChange={handleChange}
                        {...(errors?.uri && { error: true, helperText: errors.uri })}
                      />
                    </div>

                    <div className="pt-6">
                      <TextField
                        fullWidth={true}
                        label={t('teamConfigurationClinicalSystemsNumber')}
                        disabled={formData.type !== 'EmisWeb' || !(isEdit || isCreate)}
                        variant="outlined"
                        name="customerNumber"
                        value={formData.customerNumber}
                        onChange={handleChange}
                        {...(errors?.customerNumber && { error: true, helperText: errors.customerNumber })}
                      />
                    </div>

                    <div className="pt-6">
                      {formData.type === 'EmisWeb' ? (
                        <FormControlLabel
                          control={<Checkbox name="isTestSystem" checked={formData.isTestSystem} onChange={handleChange} disabled={!(isEdit || isCreate)} />}
                          label={t('isTestSystemCheckboxLabel')}
                        />
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </div>
                  </form>
                )
              ) : (
                <React.Fragment />
              )}
            </Grid>
          </Paper>
        </Grid>

        {(isCreate || isEdit) && !isFetchingTeamConfiguration && !isGettingTeamConfiguration && (
          <Grid container item xs={12} sm={12} md={12} lg={12} className="justify-end pt-5">
            <Button className="!mr-5" variant="contained" color="secondary" onClick={onReset} disabled={isUpdatingConfiguration}>
              {isUpdatingConfiguration && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
              {t('cancelButtonText')}
            </Button>
            <Button variant="contained" color="primary" form="echo-config" type="submit" disabled={isUpdatingConfiguration}>
              {isUpdatingConfiguration && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
              {t('submitButtonText')}
            </Button>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default TeamEchoConfiguration;
