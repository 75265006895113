import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onContinue: () => void;
  onCancel: () => void;
}

export const GlobalUnsavedChangesDialog = ({ open, onContinue, onCancel }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{t('unsavedChanges')}</DialogTitle>
      <DialogContent>
        <Typography>{t('unsavedChangesWarning')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary" variant="contained">
          {t('stayOnPage')}
        </Button>
        <Button onClick={onContinue} color="primary" variant="contained">
          {t('leaveWithoutSaving')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
