import {
  useGetApplicationsQuery,
  useGetSubstitutionQuery,
  useGetSubstitutionTypesQuery,
  useGetTeamNamesQuery,
  useGetTeamsQuery,
  usePostSubstitutionMutation,
  usePutSubstitutionMutation,
} from '@apis';
import { RoutePath } from '@constants';
import { useAppDispatch, useAppSelector, useForm } from '@hooks';
import { Button, CircularProgress, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material';
import { addTeamName, openSnackbar, setBreadcrumbs, setHelmet } from '@slices';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Application, ApplicationItem, ReplacementKeycode, SubstitutionForm, TeamDetailedItem, TeamIdDto } from '@interfaces';
import { KeyCodeEditor, SubstitutionApplicationsEditor, SubstitutionTeamsEditor, TableIcons, ValidateSubstitutionModal } from '@components';
import { useDebounce } from 'use-debounce';
import { transformSubstitutionDto } from '@utils';

const formInitialValues = {
  id: 0,
  name: '',
  description: '',
  subTypeId: 0,
  type: '',
  subType: '',
  replacement: '',
  applications: [],
  teamIds: [],
  pattern: '',
  typeName: '',
  replacementKeycodes: { replacementKeycodes: [] },
} as SubstitutionForm;

const Substitution = () => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { substitutionId } = useParams();
  useGetSubstitutionTypesQuery(undefined, { refetchOnMountOrArgChange: true });
  const { refetch, isSuccess, isLoading } = useGetSubstitutionQuery(parseInt(substitutionId ?? ''), {
    refetchOnMountOrArgChange: true,
    skip: substitutionId === '0',
  });

  const [substitutionTeams, setSubstitutionTeams] = useState<TeamIdDto[]>([]);
  const [substitutionApps, setSubstitutionApps] = useState<Application[]>([]);
  const { userRights, activeTeam } = useAppSelector((state) => state.app);

  const [teamIds, setTeamIds] = useState<string>('');
  useGetTeamNamesQuery({ teamIds, activeTeamId: activeTeam?.id ?? 1 }, { refetchOnMountOrArgChange: true, skip: !teamIds });
  const { isLoading: isLoadingApps } = useGetApplicationsQuery(undefined, { refetchOnMountOrArgChange: true });
  const { substitution, substitutionTypes } = useAppSelector((x) => x.substitution);
  const { teamNames, teams } = useAppSelector((x) => x.team);
  const { applications } = useAppSelector((x) => x.echo);
  const [postSubstitution, { isLoading: isAddingSubstitution }] = usePostSubstitutionMutation();
  const [putSubstitution, { isLoading: isUpdatingSubstitution }] = usePutSubstitutionMutation();
  const [searchTeam, setSearchTeam] = useState<string>('');
  const [debouncedSearchTeam] = useDebounce(searchTeam, 200);

  const [selectAppValue, setSelectAppValue] = useState<ApplicationItem[]>([]);
  const [selectValue, setSelectValue] = useState<TeamDetailedItem[]>([]);

  const { isLoading: isLoadingTeams } = useGetTeamsQuery(
    { pageNo: 0, pageSize: 40, searchTerm: debouncedSearchTeam, parentTeamId: userRights.sysAdminTeams[0] },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    },
  );

  const [replacementKeycodes, setReplacementKeycodes] = useState<ReplacementKeycode[]>([]);

  useEffect(() => {
    dispatch(setHelmet({ title: t('substitutionsTitle') }));
    dispatch(setBreadcrumbs([{ text: t('substitutionsTitle'), link: `/substitutions` }, { text: 'New' }]));
  }, []);

  useEffect(() => {
    if (isSuccess) {
      const breadcrumbs = [{ text: t('substitutionsTitle'), link: `/substitutions` }, { text: substitution?.name }];
      dispatch(setBreadcrumbs(breadcrumbs));
    }
  }, [isSuccess, substitution]);

  useEffect(() => {
    if (isSuccess && substitutionId !== '0') {
      if (substitution != null) {
        setFormData({
          id: substitution.id,
          name: substitution.name,
          description: substitution.description ?? '',
          subTypeId: substitution.subTypeId,
          type: substitution.type,
          subType: substitution.subType,
          replacement: substitution.replacement,
          applications: substitution.applications,
          teamIds: substitution.teamIds,
          pattern: substitution.pattern,
          typeName: substitution.typeName,
          replacementKeycodes: substitution.replacementKeycodes,
        });
        setReplacementKeycodes([...substitution.replacementKeycodes.replacementKeycodes]);
        setSubstitutionApps([...substitution.applications]);
        setSubstitutionTeams([...substitution.teamIds]);
        if (substitution.teamIds.length > 0) {
          setTeamIds(substitution.teamIds.map((item) => item.teamId).toString());
        }
      }
    }
  }, [isSuccess, substitution]);

  const handleReset = (): void => {
    resetForm(
      substitution && substitutionId !== '0'
        ? {
            id: substitution.id,
            name: substitution.name,
            description: substitution.description ?? '',
            subTypeId: substitution.subTypeId,
            type: substitution.type,
            subType: substitution.subType,
            replacement: substitution.replacement,
            applications: substitution.applications,
            teamIds: substitution.teamIds,
            pattern: substitution.pattern,
            typeName: substitution.typeName,
            replacementKeycodes: substitution.replacementKeycodes,
          }
        : { ...formInitialValues },
    );
    setReplacementKeycodes([]);
    setSelectValue([]);
    setSelectAppValue([]);
    setSubstitutionApps([]);
    setSubstitutionTeams([]);
  };

  useEffect(() => {
    handleReset();
  }, [substitutionId]);

  const handleSubstitutionSubmit = (): void => {
    if (substitutionId !== '0') {
      putSubstitution(
        transformSubstitutionDto({
          ...formData,
          replacementKeycodes: { replacementKeycodes },
          applications: substitutionApps,
          teamIds: substitutionTeams,
          subType: substitutionTypes.find((x) => x.id === formData.subTypeId)?.name ?? null,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(openSnackbar({ message: t('substitutionUpdateMessage'), severity: 'success', display: true }));
          refetch();
        })
        .catch((err) => {
          console.debug('Failed while attempting to update a substitution', err);
          return;
        });
    } else {
      postSubstitution(
        transformSubstitutionDto({
          ...formData,
          replacementKeycodes: { replacementKeycodes },
          applications: substitutionApps,
          teamIds: substitutionTeams,
          subType: substitutionTypes.find((x) => x.id === formData.subTypeId)?.name ?? null,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(openSnackbar({ message: t('substitutionAddedMessage'), severity: 'success', display: true }));
          setTimeout(() => navigate(RoutePath.SubstitutionsPath), 1000);
        })
        .catch((err) => {
          console.debug('Failed while attempting to add a substitution', err);
          return;
        });
    }
  };

  const { handleSubmit, handleChange, formData, setFormData, errors, resetForm } = useForm<SubstitutionForm>({
    validations: {
      name: {
        required: {
          value: true,
          message: t('fieldRequired'),
        },
      },
      subTypeId: {
        required: {
          value: true,
          message: t('fieldRequired'),
        },
      },
      pattern: {
        custom: {
          isValid: (value) => {
            try {
              new RegExp(value);
              return true;
            } catch (e) {
              return false;
            }
          },
          message: t('regExpNotValid'),
        },
        required: {
          value: true,
          message: t('fieldRequired'),
        },
      },
      replacement: {
        required: {
          value: true,
          message: t('fieldRequired'),
        },
      },
    },
    onSubmit: handleSubstitutionSubmit,
    initialValues: formInitialValues,
  });

  const handleAddTeam = (_event: SyntheticEvent<Element, Event>, value: TeamDetailedItem[]): void => {
    const data = [...substitutionTeams];
    value
      .filter((x) => !substitutionTeams.map((item) => item.teamId).includes(x.id))
      .map((x) => {
        data.push({ teamId: x.id });
        dispatch(addTeamName({ id: x.id, name: x.name }));
      });

    setSubstitutionTeams([...data]);
    setSelectValue(value);
  };

  const handleAddApplication = (_event: SyntheticEvent<Element, Event>, value: ApplicationItem[]): void => {
    const data = [...substitutionApps];
    value
      .filter((x) => !substitutionApps.map((item) => item.id).includes(x.id))
      .map((x) => {
        data.push(x);
      });

    setSubstitutionApps([...data]);
    setSelectAppValue(value);
  };

  const handleRemoveApplication = (value: number): void => {
    const data = [...substitutionApps.filter((x) => x.id !== value)];
    setSubstitutionApps([...data]);
    setSelectAppValue([]);
  };

  const handleRemoveTeam = (value: number): void => {
    const data = [...substitutionTeams.filter((x) => x.teamId !== value)];
    setSubstitutionTeams([...data]);
    setSelectValue([]);
  };

  return (
    <Grid>
      <form id="substitution-form" onSubmit={handleSubmit}>
        <Paper className="space-y-3 p-10">
          {substitutionId !== '0' ? (
            <Grid container item xs={12} sm={12} md={12} lg={12} className="justify-between items-center">
              <Typography>Id: {substitutionId}</Typography>
              <KeyCodeEditor keycodes={replacementKeycodes} setKeycodes={setReplacementKeycodes} isLoading={isLoading} formData={formData} />
            </Grid>
          ) : (
            <Grid container item xs={12} sm={12} md={12} lg={12} className="justify-end">
              <KeyCodeEditor keycodes={replacementKeycodes} setKeycodes={setReplacementKeycodes} isLoading={isLoading} formData={formData} />
            </Grid>
          )}

          <Grid container item xs={12} sm={12} md={12} lg={12} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                name="name"
                label={t('name')}
                value={formData.name}
                inputProps={{ maxLength: 255 }}
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                {...(errors?.name && { error: true, helperText: errors.name })}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                name="description"
                label={t('description')}
                value={formData.description}
                inputProps={{ maxLength: 255 }}
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                {...(errors?.description && { error: true, helperText: errors.description })}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} md={12} lg={12} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                name="pattern"
                label={t('pattern')}
                value={formData.pattern}
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                {...(errors?.pattern && { error: true, helperText: errors.pattern })}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                name="replacement"
                label={t('replacement')}
                value={formData.replacement}
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                {...(errors?.replacement && { error: true, helperText: errors.replacement })}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} sm={12} md={12} lg={12} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                variant="outlined"
                name="type"
                label={t('type')}
                placeholder={t('type') as string}
                fullWidth
                select
                onChange={handleChange}
                value={formData.type}
                {...(errors?.type && { error: true, helperText: errors?.type })}>
                <MenuItem value="replacement">{t('replacement')}</MenuItem>
                <MenuItem value="notification">{t('notification')}</MenuItem>
                <MenuItem value="snomed">{t('snomed')}</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                variant="outlined"
                name="subTypeId"
                placeholder={t('subtype') as string}
                label={t('subtype')}
                fullWidth
                select
                onChange={handleChange}
                value={formData.subTypeId}
                {...(errors?.subTypeId && { error: true, helperText: errors?.subTypeId })}>
                {substitutionTypes.map((item, index) => {
                  return (
                    <MenuItem key={`${index}`} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>

          <Grid container item xs={12} sm={12} md={12} lg={12} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <SubstitutionApplicationsEditor
                selectAppValue={selectAppValue}
                handleAddApplication={handleAddApplication}
                tableData={substitutionApps}
                handleRemoveApplication={handleRemoveApplication}
                isLoadingApps={isLoadingApps}
                applications={applications}
                isLoading={isLoading}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <SubstitutionTeamsEditor
                selectValue={selectValue}
                handleAddTeam={handleAddTeam}
                tableData={substitutionTeams}
                handleRemoveTeam={handleRemoveTeam}
                isLoadingTeams={isLoadingTeams}
                teams={teams}
                setSearchTeam={setSearchTeam}
                isLoading={isLoading}
                teamNames={teamNames}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} sm={12} md={12} lg={12} className="justify-end pt-5">
            <ValidateSubstitutionModal
              disabled={formData.pattern !== '' && errors?.pattern !== undefined}
              handleChange={handleChange}
              formData={formData}
              errors={errors}
            />
            <Button className="!mr-5" variant="contained" color="secondary" onClick={handleReset} disabled={isUpdatingSubstitution || isAddingSubstitution}>
              {t('clearButtonText')}
            </Button>
            <Button variant="contained" color="primary" type="submit" disabled={isUpdatingSubstitution || isAddingSubstitution}>
              <div className="relative">
                {(isUpdatingSubstitution || isAddingSubstitution) && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
                {t('submitButtonText')}
              </div>
            </Button>
          </Grid>
        </Paper>
      </form>
    </Grid>
  );
};

export default Substitution;
