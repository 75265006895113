const sysConfig = {
  lexacomServiceId: '6',
  echoServiceId: '13',
  echoUri: 'https://lexprd-vnext-echo-api-uk-staging.azurewebsites.net',
  panoUri: 'https://id-staging.lexacom.com',
  mahonUri: 'https://lexprd-vnext-mahon-staging.azurewebsites.net',
  horreumUri: 'https://VLX-SRV-008:44312',
};

export default sysConfig;
