import React, { useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ColumnSelectionTab from '../tabs/ColumnSelectionTab';
import QueryTypeTab from '../tabs/QueryTypeTab';
import ColumnNamingTab from '../tabs/ColumnNamingTab';
import JoinConfigurationTab from '../tabs/JoinConfigurationTab';
import FilterableColumnTab from '../tabs/FilterableColumnTab';
import { useState } from 'react';

interface TemplateEditDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  mode: 'create' | 'edit';
  schema: any[];
  templateName: string;
  onTemplateNameChange: (name: string) => void;
  selectedColumns: any[];
  onSelectedColumnsChange: (columns: any[]) => void;
  joins: any[];
  onJoinsChange: (joins: any[]) => void;
  queryType: any;
  onQueryTypeChange: (queryType: any) => void;
  filterableColumns: string[];
  onFilterableColumnsChange: (columns: string[]) => void;
  expandedTables: string[];
  onTableExpand: (tableName: string) => void;
}

const TemplateEditDialog: React.FC<TemplateEditDialogProps> = ({
  open,
  onClose,
  onSave,
  mode,
  schema,
  templateName,
  onTemplateNameChange,
  selectedColumns,
  onSelectedColumnsChange,
  joins,
  onJoinsChange,
  queryType,
  onQueryTypeChange,
  filterableColumns,
  onFilterableColumnsChange,
  expandedTables,
  onTableExpand,
}) => {
  const { t } = useTranslation('pano');
  const [tabValue, setTabValue] = useState(0);

  const selectedTables = useMemo(() => [...new Set(selectedColumns.map((col) => col.tableName))], [selectedColumns]);

  const handleFilterableChange = (columnFullName: string) => {
    const newFilterableColumns = filterableColumns.includes(columnFullName)
      ? filterableColumns.filter((col) => col !== columnFullName)
      : [...filterableColumns, columnFullName];

    onFilterableColumnsChange(newFilterableColumns);
  };

  const handleColumnSelect = (tableName: string, columnName: string) => {
    const isSelected = selectedColumns.some((col) => col.tableName === tableName && col.columnName === columnName);
    const newSelectedColumns = isSelected
      ? selectedColumns.filter((col) => !(col.tableName === tableName && col.columnName === columnName))
      : [...selectedColumns, { tableName, columnName }];

    if (isSelected) {
      onQueryTypeChange({
        ...queryType,
        columnAggregations: queryType.columnAggregations?.filter((agg: any) => !(agg.tableName === tableName && agg.columnName === columnName)),
        orderBy: queryType.orderBy?.filter((order: any) => !(order.tableName === tableName && order.columnName === columnName)),
        groupByColumns: queryType.groupByColumns?.filter((col: string) => !col.includes(`${tableName}.${columnName}`)),
      });
    }

    onSelectedColumnsChange(newSelectedColumns);
  };

  const handleColumnAliasChange = (tableName: string, columnName: string, newAlias: string) => {
    const newSelectedColumns = selectedColumns.map((col) => {
      if (col.tableName === tableName && col.columnName === columnName) {
        return {
          ...col,
          alias: newAlias.trim() === '' ? undefined : newAlias.trim(),
        };
      }
      return col;
    });
    onSelectedColumnsChange(newSelectedColumns);
  };

  return (
    <Dialog
      open={open}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose();
        }
      }}
      maxWidth="md"
      fullWidth
      TransitionProps={{
        onExited: () => setTabValue(0),
      }}>
      <DialogTitle>{mode === 'create' ? t('createNewTemplate') : t('editTemplate')}</DialogTitle>
      <DialogContent>
        <TextField autoFocus margin="dense" label={t('templateName')} fullWidth value={templateName} onChange={(e) => onTemplateNameChange(e.target.value)} />
        <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} className="mt-4">
          <Tab label={t('columns')} />
          <Tab label={t('queryType')} />
          <Tab label={t('columnNames')} />
          <Tab label={t('joins')} />
          <Tab label={t('filters')} />
        </Tabs>
        <Box className="mt-2">
          {tabValue === 0 && (
            <ColumnSelectionTab
              schema={schema}
              selectedColumns={selectedColumns}
              expandedTables={expandedTables}
              onTableExpand={onTableExpand}
              onColumnSelect={handleColumnSelect}
            />
          )}
          {tabValue === 1 && <QueryTypeTab queryType={queryType} selectedColumns={selectedColumns} onQueryTypeChange={onQueryTypeChange} />}
          {tabValue === 2 && (
            <ColumnNamingTab
              selectedColumns={selectedColumns}
              queryType={queryType}
              onColumnsReorder={onSelectedColumnsChange}
              onColumnAliasChange={handleColumnAliasChange}
            />
          )}
          {tabValue === 3 && (
            <JoinConfigurationTab
              selectedColumns={selectedColumns}
              joins={joins}
              onJoinChange={(index, field, value) => {
                const newJoins = [...joins];
                newJoins[index] = { ...newJoins[index], [field]: value };
                onJoinsChange(newJoins);
              }}
              onRemoveJoin={(index) => {
                onJoinsChange(joins.filter((_, i) => i !== index));
              }}
              onAddJoin={(join) => {
                onJoinsChange([...joins, { ...join, joinType: 'INNER' }]);
              }}
              selectedTables={selectedTables}
              schema={schema}
              unusedPotentialJoins={[]}
            />
          )}
          {tabValue === 4 && (
            <FilterableColumnTab selectedColumns={selectedColumns} filterableColumns={filterableColumns} onFilterableChange={handleFilterableChange} />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>
        <Button onClick={onSave}>{t('save')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TemplateEditDialog;
