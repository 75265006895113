import { createApi } from '@reduxjs/toolkit/query/react';
import { ListRolesResponseItem, NewRoleForm, RoleItem, UpdateRoleForm } from '@interfaces';
import { baseQueryHandler } from './base.api';

export const roleApi = createApi({
  baseQuery: baseQueryHandler,
  reducerPath: 'roleApi',
  endpoints: (builder) => ({
    getRole: builder.query<RoleItem, { teamId: number; roleId: number }>({
      query: (payload) => ({
        url: `/api/v1/role/${payload.roleId}/${payload.teamId}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
      }),
    }),
    getRoles: builder.query<ListRolesResponseItem[], void>({
      query: () => ({
        url: `/api/v2/role/`,
        method: 'GET',
      }),
    }),
    checkUsersAffected: builder.mutation<number, { data: UpdateRoleForm; roleId: number }>({
      query: (payload) => ({
        url: `/api/v2/role/${payload.roleId}/check`,
        method: 'POST',
        body: payload.data,
      }),
    }),
    putRole: builder.mutation<void, { teamId: number; roleId: number; memberIds: number[] }>({
      query: (payload) => ({
        url: `/api/v2/team/role/${payload.roleId}`,
        method: 'PUT',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
        body: { MemberIds: payload.memberIds },
      }),
    }),
    postRole: builder.mutation<void, NewRoleForm>({
      query: (payload) => ({
        url: `/api/v2/role`,
        method: 'POST',
        body: payload,
      }),
    }),
    putGlobalRole: builder.mutation<void, { data: UpdateRoleForm; roleId: number }>({
      query: (payload) => ({
        url: `/api/v2/role/${payload.roleId}`,
        method: 'PUT',
        body: payload.data,
      }),
    }),
  }),
});

export const { useGetRoleQuery, usePutRoleMutation, useGetRolesQuery, usePostRoleMutation, usePutGlobalRoleMutation, useCheckUsersAffectedMutation } = roleApi;
