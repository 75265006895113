import React from 'react';
import { Box, Typography, Paper, FormControlLabel, Checkbox } from '@mui/material';
import { ReportColumnDto } from '@interfaces';
import { useTranslation } from 'react-i18next';

interface FilterableColumnTabProps {
  selectedColumns: ReportColumnDto[];
  filterableColumns: string[];
  onFilterableChange: (columnFullName: string) => void;
}

const FilterableColumnTab: React.FC<FilterableColumnTabProps> = ({ selectedColumns, filterableColumns, onFilterableChange }) => {
  const { t } = useTranslation('pano');

  if (selectedColumns.length === 0) {
    return (
      <Paper variant="outlined" className="p-8 text-center">
        <Typography color="textSecondary" className="mb-2">
          {t('noColumnsSelected')}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {t('selectColumnsPrompt')}
        </Typography>
      </Paper>
    );
  }

  return (
    <Box>
      <Typography variant="h6" className="mb-4">
        {t('filterableColumns')}
      </Typography>
      <Paper variant="outlined" className="p-4">
        <Box className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {selectedColumns.map((col) => {
            const columnFullName = `${col.tableName}.${col.columnName}`;
            return (
              <FormControlLabel
                key={columnFullName}
                control={<Checkbox checked={filterableColumns.includes(columnFullName)} onChange={() => onFilterableChange(columnFullName)} color="primary" />}
                label={
                  <Box>
                    <Typography variant="body2">{col.alias || columnFullName}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      {col.tableName}
                    </Typography>
                  </Box>
                }
                className="m-0"
              />
            );
          })}
        </Box>
      </Paper>
      <Typography variant="caption" color="textSecondary" className="mt-2 block">
        {t('filterableColumnsHelp')}
      </Typography>
    </Box>
  );
};

export default FilterableColumnTab;
