import { TpdTreeNode } from '@interfaces';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { faFile, faFolder, faChevronRight, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { blue } from '@constants';

interface TpdTreeSelectorDialogProps {
  open: boolean;
  onClose: () => void;
  onSelect: (value: string) => void;
  tpdTree: TpdTreeNode[];
}

const TpdTreeSelectorDialog = ({ open, onClose, onSelect, tpdTree }: TpdTreeSelectorDialogProps) => {
  const { t } = useTranslation('pano');
  const [selectedNode, setSelectedNode] = useState<string>('');

  const renderTreeItems = (nodes: TpdTreeNode[]) => {
    return nodes.map((node) => (
      <TreeItem
        key={node.value || node.label}
        itemId={node.value || node.label}
        label={
          <div className="flex items-center py-1">
            <FontAwesomeIcon icon={node.type === 'property' ? faFile : faFolder} size="sm" className="mr-2" style={{ color: blue[500] }} />
            <span>{node.label}</span>
          </div>
        }
        className={node.value ? 'cursor-pointer' : ''}>
        {node.children && renderTreeItems(node.children)}
      </TreeItem>
    ));
  };

  const handleSelect = (_event: SyntheticEvent<Element, Event>, itemIds: string | null) => {
    if (itemIds) setSelectedNode(itemIds);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          minHeight: '60vh',
          maxHeight: '80vh',
        },
      }}>
      <DialogTitle>{t('selectTpdBinding')}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            minHeight: 300,
            width: '100%',
            '& .MuiTreeItem-content': {
              padding: '4px 0',
            },
            '& .MuiTreeItem-label': {
              width: '100%',
            },
          }}>
          <SimpleTreeView
            aria-label="tpd tree"
            defaultExpandedItems={['Third Party Data']}
            slots={{
              expandIcon: () => <FontAwesomeIcon icon={faChevronRight} style={{ color: blue[500] }} />,
              collapseIcon: () => <FontAwesomeIcon icon={faChevronDown} style={{ color: blue[500] }} />,
            }}
            selectedItems={selectedNode ? selectedNode : ''}
            onSelectedItemsChange={handleSelect}>
            {renderTreeItems(tpdTree)}
          </SimpleTreeView>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('cancel')}
        </Button>
        <Button
          onClick={() => {
            if (selectedNode) {
              onSelect(selectedNode);
              onClose();
              setSelectedNode('');
            }
          }}
          color="primary"
          variant="contained"
          disabled={!selectedNode}>
          {t('select')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TpdTreeSelectorDialog;
