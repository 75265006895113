import { green, red } from '@constants';
import { faCheck, faPlus, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector, useAppDispatch } from '@hooks';
import { WorkflowStepDto, WorkflowStepMediaConfigurationDto, IMediaConfigurationDto, PostMediaConfigurationDto } from '@interfaces';
import MaterialTable from '@material-table/core';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Checkbox,
  Typography,
  Tabs,
  Tab,
  Tooltip,
  IconButton,
} from '@mui/material';
import React, { Dispatch, SetStateAction, createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { openSnackbar, updateTemplateStep } from '@slices';
import AddMediaConfigurationDialog from './AddMediaConfigurationDialog';
import { usePostMediaConfigMutation } from '@apis';

type Props = {
  step: WorkflowStepDto;
  isLoading: boolean;
  isDialogOpen: boolean;
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
  refetchGlobalMedia: () => void;
};

type TableData = {
  id: number;
  name: string;
  label: string;
  readOnly: boolean;
  canBeGridColumn: boolean;
  tableData: {
    checked: boolean;
  };
};

const StepMediaConfigurationDialog = ({ isDialogOpen, setIsDialogOpen, isLoading, step, refetchGlobalMedia }: Props) => {
  const [isAddingMedia, setIsAddingMedia] = useState(false);
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const { mediaConfiguration, globalMediaConfiguration, mediaConfigurationTypes } = useAppSelector((x) => x.mahon);
  const { activeTeam } = useAppSelector((x) => x.app);
  const tableRef = createRef() as React.RefObject<any> | React.MutableRefObject<undefined> | undefined;
  const [normalTableData, setNormalTableData] = useState<TableData[]>([]);
  const [globalTableData, setGlobalTableData] = useState<TableData[]>([]);
  const [activeTab, setActiveTab] = useState(0);

  const [createMediaConfig] = usePostMediaConfigMutation();

  const handleCreateMediaConfig = (data: PostMediaConfigurationDto): void => {
    createMediaConfig({ data, teamId: activeTeam?.id ?? 1 })
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
        refetchGlobalMedia();
        setIsAddingMedia(false);
      })
      .catch((err) => {
        console.debug('Failed while attempting to create media', err);
        dispatch(openSnackbar({ message: t('failCheckDetails'), severity: 'error', display: true }));
        return;
      });
  };

  useEffect(() => {
    if (step && mediaConfiguration.length > 0) {
      const normalMediaTableData = mediaConfiguration
        .filter((mc) => !globalMediaConfiguration.some((gmc) => gmc.visualId === mc.visualId))
        .map(createTableData);
      setNormalTableData(normalMediaTableData);

      const globalMediaTableData = globalMediaConfiguration.map(createTableData);
      setGlobalTableData(globalMediaTableData);
    }
  }, [step, mediaConfiguration, globalMediaConfiguration, isDialogOpen]);

  const createTableData = (mc: IMediaConfigurationDto): TableData => ({
    id: mc.id,
    name: mc.name,
    label: mc.description,
    readOnly: step.stepConfigurations.some((config) => config.mediaConfiguration.visualId === mc.visualId && config.isReadOnly),
    canBeGridColumn: mc.canBeGridColumn,
    tableData: {
      checked: step.stepConfigurations.some((config) => config.mediaConfiguration.visualId === mc.visualId),
    },
  });

  const onSelectionChange = (data: TableData[], isGlobal: boolean): void => {
    const updateData = (prevData: TableData[]) =>
      prevData.map((row) => ({
        ...row,
        tableData: {
          ...row.tableData,
          checked: data.some((d) => d.id === row.id),
        },
      }));

    if (isGlobal) {
      setGlobalTableData(updateData);
    } else {
      setNormalTableData(updateData);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, rowData: TableData, isGlobal: boolean) => {
    const updateData = (prevData: TableData[]) => prevData.map((data) => (data.id === rowData.id ? { ...data, readOnly: event.target.checked } : data));

    if (isGlobal) {
      setGlobalTableData(updateData);
    } else {
      setNormalTableData(updateData);
    }
  };

  const handleAddMediaToStep = () => {
    const existingConfigs = step.stepConfigurations;
    const selectedConfigs = [...normalTableData, ...globalTableData].filter((data) => data.tableData.checked);

    const newConfigs = selectedConfigs.map((data) => {
      const fullMediaConfiguration = [...mediaConfiguration, ...globalMediaConfiguration].find((mc) => mc.id === data.id);
      return {
        mediaConfiguration: fullMediaConfiguration,
        isReadOnly: data.readOnly,
        workflowStepId: step.id,
        typeName: 'WorkflowStepMediaConfigurationDto',
      } as WorkflowStepMediaConfigurationDto;
    });

    const updatedConfigs = existingConfigs
      .filter((config) => selectedConfigs.some((data) => data.id === config.mediaConfiguration.id))
      .map((config) => {
        const updatedConfig = selectedConfigs.find((data) => data.id === config.mediaConfiguration.id);
        return {
          ...config,
          isReadOnly: updatedConfig?.readOnly ?? config.isReadOnly,
        };
      });

    const addedConfigs = newConfigs.filter((config) => !existingConfigs.some((existing) => existing.mediaConfiguration.id === config.mediaConfiguration.id));

    const finalConfigs = [...updatedConfigs, ...addedConfigs];

    const updatedStep = {
      ...step,
      stepConfigurations: finalConfigs,
    };

    dispatch(updateTemplateStep(updatedStep));
    setIsDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const renderTable = (data: TableData[], isGlobal: boolean) => (
    <div className="space-y-2">
      <div className="flex items-center justify-end mr-1">
        {isGlobal && (
          <Tooltip title={t('addNewMedia')} placement="left">
            <IconButton color="primary" onClick={() => setIsAddingMedia(true)} size="large" className="hover:bg-primary-50">
              <FontAwesomeIcon icon={faPlus} className="text-lg" />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <MaterialTable
        tableRef={tableRef}
        options={{
          tableLayout: 'auto',
          addRowPosition: 'first',
          actionsColumnIndex: -1,
          sorting: false,
          search: true,
          selection: true,
          draggable: false,
        }}
      title={isGlobal ? t('globalTeamMedia') : t('teamMedia')}
        onSelectionChange={(data) => onSelectionChange(data, isGlobal)}
        columns={[
          {
            title: t('name'),
            field: 'label',
            render: (rowData) => (
              <>
                <Typography fontSize={14}>{rowData.label}</Typography>
                <Typography fontSize={12}>{rowData.name}</Typography>
              </>
            ),
          },
          {
            title: t('canBeGridColumn'),
            field: 'canBeGridColumn',
            type: 'boolean',
            align: 'center',
            render: (rowData) =>
              rowData.canBeGridColumn ? (
                <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
              ) : (
                <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />
              ),
          },
          {
            title: t('readOnly'),
            field: 'readOnly',
            type: 'boolean',
            render: (rowData) => <Checkbox checked={rowData.readOnly} onChange={(event) => handleCheckboxChange(event, rowData, isGlobal)} color="primary" />,
          },
        ]}
        data={data}
      />
    </div>
  );

  return (
    <>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth={true} maxWidth="md">
        <DialogTitle>{t('addEditMedia')}</DialogTitle>
        <DialogContent>
          <Paper className="w-full space-y-4">
            <Tabs value={activeTab} onChange={handleTabChange} centered>
              <Tab label={t('teamMediaConfiguration')} />
              <Tab label={t('globalMediaConfiguration')} />
            </Tabs>
            {activeTab === 0 && renderTable(normalTableData, false)}
            {activeTab === 1 && renderTable(globalTableData, true)}
          </Paper>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleCloseDialog} disabled={isLoading}>
            {t('cancelButtonText')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleAddMediaToStep} disabled={isLoading}>
            {isLoading && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
            {t('submitButtonText')}
          </Button>
        </DialogActions>
      </Dialog>
      <AddMediaConfigurationDialog
        isOpen={isAddingMedia}
        onClose={() => setIsAddingMedia(false)}
        onSave={handleCreateMediaConfig}
        mediaTypes={mediaConfigurationTypes}
      />
    </>
  );
};

export default StepMediaConfigurationDialog;
