import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Typography,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FilterableColumnInfo, FilterGroup, ScheduleType, TemplateSchedule, TimeRange } from '@interfaces';
import { useTranslation } from 'react-i18next';
import TimeRangeSection from '../sections/TimeRangeSection';
import { FilterCreation } from '@components';

const DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

interface ScheduleEditDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  schedule: TemplateSchedule | null;
  availableColumns: FilterableColumnInfo[];
  scheduleName: string;
  onScheduleNameChange: (name: string) => void;
  scheduleType: ScheduleType;
  onScheduleTypeChange: (type: ScheduleType) => void;
  timeOfDay: Date;
  onTimeOfDayChange: (date: Date | null) => void;
  daysOfWeek: string[];
  onDaysOfWeekChange: (days: string[]) => void;
  dayOfMonth: number;
  onDayOfMonthChange: (day: number) => void;
  emails: string[];
  onEmailsChange: (emails: string[]) => void;
  filterGroups: FilterGroup[];
  onFilterGroupsChange: (groups: FilterGroup[]) => void;
  logicalOperators: ('AND' | 'OR')[];
  onLogicalOperatorsChange: (operators: ('AND' | 'OR')[]) => void;
  timeRange: TimeRange;
  onTimeRangeChange: (timeRange: TimeRange) => void;
  timestampColumns: Array<{
    tableName: string;
    columnName: string;
    dataType: string;
    alias?: string;
  }>;
}

const ScheduleEditDialog: React.FC<ScheduleEditDialogProps> = ({
  open,
  onClose,
  onSave,
  schedule,
  availableColumns,
  scheduleName,
  onScheduleNameChange,
  scheduleType,
  onScheduleTypeChange,
  timeOfDay,
  onTimeOfDayChange,
  daysOfWeek,
  onDaysOfWeekChange,
  dayOfMonth,
  onDayOfMonthChange,
  emails,
  onEmailsChange,
  filterGroups,
  onFilterGroupsChange,
  logicalOperators,
  onLogicalOperatorsChange,
  timeRange,
  onTimeRangeChange,
  timestampColumns,
}) => {
  const { t } = useTranslation('pano');

  const handleEmailKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test((e.target as HTMLInputElement).value)) {
      onEmailsChange([...emails, (e.target as HTMLInputElement).value]);
      (e.target as HTMLInputElement).value = '';
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose();
        }
      }}
      maxWidth="md"
      fullWidth>
      <DialogTitle>{schedule ? t('editSchedule') : t('createSchedule')}</DialogTitle>
      <DialogContent>
        <Box className="space-y-4 pt-2">
          <TextField label={t('scheduleName')} value={scheduleName} onChange={(e) => onScheduleNameChange(e.target.value)} fullWidth />

          <FormControl fullWidth>
            <InputLabel>{t('scheduleType')}</InputLabel>
            <Select value={scheduleType} onChange={(e) => onScheduleTypeChange(e.target.value as ScheduleType)} label={t('scheduleType')}>
              <MenuItem value="DAILY">{t('daily')}</MenuItem>
              <MenuItem value="WEEKLY">{t('weekly')}</MenuItem>
              <MenuItem value="MONTHLY">{t('monthly')}</MenuItem>
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label={t('hourOfDay')}
              value={timeOfDay}
              onChange={onTimeOfDayChange}
              views={['hours']}
              format="HH:00"
              ampm={false}
              slotProps={{
                textField: {
                  fullWidth: true,
                  helperText: t('24hourFormat'),
                },
              }}
            />
          </LocalizationProvider>

          {scheduleType === 'WEEKLY' && (
            <Box>
              <Typography variant="subtitle2" className="mb-2">
                {t('selectDays')}
              </Typography>
              <Box className="flex flex-wrap gap-2">
                {DAYS_OF_WEEK.map((day) => (
                  <Chip
                    key={day}
                    label={t(day.toLowerCase())}
                    onClick={() => {
                      if (daysOfWeek.includes(day)) {
                        onDaysOfWeekChange(daysOfWeek.filter((d) => d !== day));
                      } else {
                        onDaysOfWeekChange([...daysOfWeek, day]);
                      }
                    }}
                    color={daysOfWeek.includes(day) ? 'primary' : 'default'}
                    className="cursor-pointer"
                  />
                ))}
              </Box>
            </Box>
          )}

          {scheduleType === 'MONTHLY' && (
            <TextField
              label={t('dayOfMonth')}
              type="number"
              value={dayOfMonth}
              onChange={(e) => onDayOfMonthChange(parseInt(e.target.value))}
              inputProps={{ min: 1, max: 31 }}
              fullWidth
            />
          )}

          <Box>
            <Box className="flex gap-2">
              <TextField label={t('addEmail')} placeholder={t('enterEmailPrompt') as string} fullWidth onKeyPress={handleEmailKeyPress} />
            </Box>
            <Box className="flex flex-wrap gap-2 mt-2">
              {emails.map((email) => (
                <Chip key={email} label={email} onDelete={() => onEmailsChange(emails.filter((e) => e !== email))} />
              ))}
            </Box>
          </Box>

          <TimeRangeSection timeRange={timeRange} onTimeRangeChange={onTimeRangeChange} timestampColumns={timestampColumns} />

          <Box>
            <Typography variant="subtitle2" className="mb-2">
              {t('filters')}
            </Typography>
            <FilterCreation
              filterGroups={filterGroups}
              onFilterGroupsChange={onFilterGroupsChange}
              interGroupOperators={logicalOperators}
              onInterGroupOperatorsChange={onLogicalOperatorsChange}
              availableColumns={availableColumns}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>
        <Button onClick={onSave} variant="contained">
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleEditDialog;
