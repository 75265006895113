import React from 'react';
import { Box, Paper, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TemplateTableLayoutProps {
  title?: string;
  isLoading?: boolean;
  error?: string;
  children: React.ReactNode;
}

const TemplateTableLayout: React.FC<TemplateTableLayoutProps> = ({ title, isLoading, error, children }) => {
  const { t } = useTranslation('pano');

  return (
    <>
      {title && (
        <Box className="flex justify-between items-center mb-4">
          <Typography variant="h6">{title}</Typography>
        </Box>
      )}

      {isLoading ? (
        <Box className="flex justify-center items-center p-8">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box className="p-4 text-center">
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        children
      )}
    </>
  );
};

export default TemplateTableLayout;
