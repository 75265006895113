import React from 'react';
import { Box, Switch, Chip } from '@mui/material';
import MaterialTable from '@material-table/core';
import { TemplateSchedule } from '@interfaces';
import { useTranslation } from 'react-i18next';
import { Icons } from '@components';
import { Action } from '@material-table/core';

interface TemplateSchedulesTableProps {
  schedules: TemplateSchedule[];
  onAdd: () => void;
  onEdit: (schedule: TemplateSchedule) => void;
  onDelete: (schedule: TemplateSchedule) => void;
  onToggleActive: (schedule: TemplateSchedule) => void;
  onViewHistory: (schedule: TemplateSchedule) => void;
}

const TemplateSchedulesTable: React.FC<TemplateSchedulesTableProps> = ({ schedules, onAdd, onEdit, onDelete, onToggleActive, onViewHistory }) => {
  const { t } = useTranslation('pano');

  const formatScheduleText = (schedule: TemplateSchedule): string => {
    const parts = [];
    if (schedule.scheduleType === 'DAILY') {
      parts.push(t('daily'));
    } else if (schedule.scheduleType === 'WEEKLY') {
      parts.push(t('weeklyOn') + ' ' + schedule.daysOfWeek?.join(', '));
    } else if (schedule.scheduleType === 'MONTHLY') {
      parts.push(t('monthlyOn') + ' ' + schedule.dayOfMonth);
    }

    const timeOfDay = schedule.timeOfDay?.split(':')[0];
    parts.push(t('atTime') + ' ' + timeOfDay + ':00');

    return parts.join(' ');
  };

  const tableActions: Action<TemplateSchedule>[] = [
    {
      icon: Icons.Add,
      tooltip: t('addSchedule') as string,
      isFreeAction: true,
      onClick: onAdd,
    },
    {
      icon: Icons.Edit,
      tooltip: t('editSchedule') as string,
      onClick: (_event, rowData) => {
        if (rowData && !Array.isArray(rowData)) {
          onEdit(rowData);
        }
      },
    },
    {
      icon: Icons.History,
      tooltip: t('viewHistory') as string,
      onClick: (_event, rowData) => {
        if (rowData && !Array.isArray(rowData)) {
          onViewHistory(rowData);
        }
      },
    },
    {
      icon: Icons.DeleteForeverIcon,
      tooltip: t('deleteSchedule') as string,
      onClick: (_event, rowData) => {
        if (rowData && !Array.isArray(rowData)) {
          onDelete(rowData);
        }
      },
    },
  ];

  return (
    <MaterialTable
      title={t('schedules')}
      columns={[
        { title: t('name'), field: 'name' },
        {
          title: t('schedule'),
          render: (rowData: TemplateSchedule) => formatScheduleText(rowData),
        },
        {
          title: t('recipients'),
          render: (rowData: TemplateSchedule) => (
            <Box className="flex flex-wrap gap-1">
              {rowData.emailRecipients.map((email) => (
                <Chip key={email} label={email} size="small" />
              ))}
            </Box>
          ),
        },
        {
          title: t('lastRun'),
          render: (rowData: TemplateSchedule) => (rowData.lastRun ? new Date(rowData.lastRun).toLocaleString() : t('never')),
        },
        {
          title: t('active'),
          render: (rowData: TemplateSchedule) => <Switch checked={rowData.isActive} onChange={() => onToggleActive(rowData)} size="small" />,
        },
      ]}
      data={schedules}
      actions={tableActions}
      options={{
        actionsColumnIndex: -1,
        pageSize: 5,
        pageSizeOptions: [5, 10, 20],
      }}
    />
  );
};

export default TemplateSchedulesTable;
