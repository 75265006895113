import { BaseQueryApi, QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, fetchBaseQuery, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import { userManager } from '@utils';

const baseQuery = fetchBaseQuery({
  baseUrl: 'https://id-staging.lexacom.com',
  prepareHeaders: async (headers) => {
    const user = await userManager.getUser();
    if (user) {
      headers.set('authorization', `Bearer ${user.access_token}`);
    }
    headers.set('Content-Type', 'application/json');
    return headers;
  },
  responseHandler: async (response: Response): Promise<any> => {
    const result = await response.text();
    if (!result) return '';

    const parsed = JSON.parse(result);
    const isReportResponse = response.url.includes('/api/v1/template/') && response.url.endsWith('/run');
    return toCamel(parsed, isReportResponse);
  },
});

export const baseQueryHandler = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: object,
): Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>> => {
  return await baseQuery(args, api, extraOptions);
};

const toCamel = (response: any, preserveCase: boolean = false) => {
  let newResponse, origKey, newKey, value;
  if (typeof response === 'number' || typeof response === 'string' || typeof response === 'boolean') return response;
  if (response instanceof Array) {
    return response.map(function (value) {
      if (typeof value === 'object') {
        value = toCamel(value, preserveCase);
      }
      return value;
    });
  } else {
    newResponse = {};
    for (origKey in response) {
      if (response.hasOwnProperty(origKey)) {
        newKey = preserveCase ? origKey : (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
        value = response[origKey];
        if (value instanceof Array || (value !== null && value.constructor === Object)) {
          value = toCamel(value, preserveCase);
        }
        //@ts-ignore
        newResponse[newKey] = value;
      }
    }
  }
  return newResponse;
};
