import React, { useEffect } from 'react';
import { Box, Typography, Paper, FormControl, InputLabel, Select, MenuItem, TextField, FormHelperText } from '@mui/material';
import { TimeRange, TimeRangeType } from '@interfaces';
import { useTranslation } from 'react-i18next';

interface TimeRangeSectionProps {
  timeRange: TimeRange;
  onTimeRangeChange: (timeRange: TimeRange) => void;
  timestampColumns: Array<{
    tableName: string;
    columnName: string;
    dataType: string;
    alias?: string;
  }>;
}

const TimeRangeSection: React.FC<TimeRangeSectionProps> = ({ timeRange, onTimeRangeChange, timestampColumns }) => {
  const { t } = useTranslation('pano');

  useEffect(() => {
    if (timeRange.tableName && timeRange.columnName && !timeRange.columnPath) {
      onTimeRangeChange({
        ...timeRange,
        columnPath: `${timeRange.tableName}.${timeRange.columnName}`,
      });
    }
  }, []);

  const handleTypeChange = (type: TimeRangeType) => {
    onTimeRangeChange({
      ...timeRange,
      type,
      value: type === 'all' ? undefined : 1,
    });
  };

  const handleColumnChange = (columnPath: string) => {
    const [tableName, columnName] = columnPath.split('.');
    onTimeRangeChange({
      ...timeRange,
      columnPath,
      tableName,
      columnName,
    });
  };

  const handleValueChange = (value: number) => {
    onTimeRangeChange({
      ...timeRange,
      value,
    });
  };

  return (
    <Paper variant="outlined" className="p-4 mt-4">
      <Typography variant="h6" className="!mb-4">
        {t('timeRangeConfiguration')}
      </Typography>

      <Box className="space-y-4">
        <Box className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <FormControl fullWidth size="small">
            <InputLabel>{t('rangeType')}</InputLabel>
            <Select value={timeRange?.type || 'no'} onChange={(e) => handleTypeChange(e.target.value as TimeRangeType)} label={t('rangeType')}>
              <MenuItem value="no">{t('useLastRunTime')}</MenuItem>
              <MenuItem value="all">{t('allTime')}</MenuItem>
              <MenuItem value="day">{t('lastNDays')}</MenuItem>
              <MenuItem value="week">{t('lastNWeeks')}</MenuItem>
              <MenuItem value="month">{t('lastNMonths')}</MenuItem>
              <MenuItem value="year">{t('lastNYears')}</MenuItem>
            </Select>
            <FormHelperText>{t('selectTimeRangeType')}</FormHelperText>
          </FormControl>

          {timeRange && timeRange.type !== 'all' && timeRange.type !== 'no' && (
            <TextField
              type="number"
              label={t('numberUnits')}
              value={timeRange.value || 1}
              onChange={(e) => handleValueChange(parseInt(e.target.value))}
              inputProps={{ min: 1 }}
              size="small"
              helperText={t('unitsLookBack')}
            />
          )}
        </Box>

        {timeRange && timeRange.type !== 'all' && (
          <FormControl fullWidth size="small">
            <InputLabel>{t('dateColumn')}</InputLabel>
            <Select
              value={timeRange.columnPath || `${timeRange.tableName}.${timeRange.columnName}` || ''}
              onChange={(e) => handleColumnChange(e.target.value)}
              label={t('dateColumn')}
              error={!timeRange?.columnPath && !timeRange?.tableName}>
              {timestampColumns.map((col) => (
                <MenuItem key={`${col.tableName}.${col.columnName}`} value={`${col.tableName}.${col.columnName}`}>
                  {col.alias || `${col.tableName}.${col.columnName}`}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{timeRange.type === 'no' ? t('selectDateColumnLastRun') : t('selectDateColumnFilter')}</FormHelperText>
          </FormControl>
        )}
      </Box>
    </Paper>
  );
};

export default TimeRangeSection;
