import { Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem, IconButton } from '@mui/material';
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TpdTreeSelectorDialog from './TpdTreeSelectorDialog';
import { TpdTreeNode } from '@interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartNetwork } from '@fortawesome/pro-regular-svg-icons';
import { blue } from '@constants';

interface TpdBindingFieldProps {
  bindingValue: string;
  updateMode?: string;
  hasUpdateSettings?: boolean;
  onChange: (fieldName: string, value: any) => void;
  tpdTree: TpdTreeNode[];
}

const TpdBindingField = ({ bindingValue, updateMode, hasUpdateSettings, onChange, tpdTree }: TpdBindingFieldProps) => {
  const { t } = useTranslation('pano');
  const [tpdSelectorOpen, setTpdSelectorOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleTpdSelection = (value: string) => {
    const input = inputRef.current;
    if (input) {
      const start = input.selectionStart || 0;
      const end = input.selectionEnd || 0;
      const newValue = bindingValue.substring(0, start) + value + bindingValue.substring(end);
      onChange('thirdPartyDataSourceBindingPath', newValue);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                inputRef={inputRef}
                name="thirdPartyDataSourceBindingPath"
                label={t('bindingPath')}
                fullWidth
                value={bindingValue}
                onChange={(e) => onChange('thirdPartyDataSourceBindingPath', e.target.value)}
                InputProps={{
                  endAdornment: (
                    <IconButton aria-label="clear" onClick={() => setTpdSelectorOpen(true)}>
                      <FontAwesomeIcon icon={faChartNetwork} style={{ color: blue[500] }} />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {hasUpdateSettings && (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              name="type"
              label={t('updateSettings')}
              placeholder={t('updateSettings') as string}
              fullWidth
              select
              onChange={(e) => onChange('updateMode', e.target.value)}
              value={updateMode || 'replace'}>
              <MenuItem value="replace">{t('replace')}</MenuItem>
              <MenuItem value="append">{t('append')}</MenuItem>
              <MenuItem value="prepend">{t('prepend')}</MenuItem>
            </TextField>
          </Grid>
        )}
      </Grid>

      <TpdTreeSelectorDialog open={tpdSelectorOpen} onClose={() => setTpdSelectorOpen(false)} onSelect={handleTpdSelection} tpdTree={tpdTree} />
    </>
  );
};

export default TpdBindingField;
