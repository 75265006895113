import { useGetLicencesQuery } from '@apis';
import { AddLicenceForm, EditLicenceForm, Spinner } from '@components';
import { useAppSelector } from '@hooks';
import { LicenceItem } from '@interfaces';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  isDialogOpen: boolean;
  closeDialog: () => void;
  serviceId: number;
  teamId: number;
};

const LicenceDialog = ({ isDialogOpen, closeDialog, serviceId, teamId }: Props) => {
  const { isSuccess, isLoading, refetch } = useGetLicencesQuery(teamId, { skip: !teamId, refetchOnMountOrArgChange: true });
  const { t } = useTranslation('pano');
  const [status, setStatus] = useState<string>('');
  const { licences } = useAppSelector((x) => x.licence);

  const [licence, setLicence] = useState<LicenceItem>();

  useEffect(() => {
    if (isSuccess) {
      setLicence(licences.find((x) => x.serviceId === serviceId));
    }
  }, [isSuccess, licences, serviceId]);

  useEffect(() => {
    if (isSuccess) {
      setLicence(licences.find((x) => x.serviceId === serviceId));
    }
  }, []);

  const handleOnClose = (): void => {
    closeDialog();
    setStatus('');
  };

  const handleRefetch = (): void => {
    refetch();
  };

  const formatDate = (dateString: string | null) => {
    return dateString ? moment(dateString).format('DD MMM YYYY') : t('N/A');
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <Dialog
      open={isDialogOpen}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleOnClose();
        }
      }}
      aria-labelledby="licence-dialog"
      fullWidth
      disableEscapeKeyDown>
      <DialogTitle>{licence ? t('activeLicenceDetails') : t('noActiveLicence')}</DialogTitle>
      <DialogContent>
        {licence && (
          <>
            <Typography variant="body1" component="p">
              <strong>{t('licenceCountLabel')}</strong>{' '}
              {licence.trial && licence.count === 0 ? t('unlimitedTrialLicences') : licence.unlimited ? t('unlimitedLicences') : licence.count}
              {` (${licence.userLicences.length} ${t('inUse')})`}
            </Typography>
            <Typography variant="body1" component="p">
              <strong>{t('startDateLabel')}</strong> {formatDate(licence.startDate)}
            </Typography>
            <Typography variant="body1" component="p">
              <strong>{t('isTrialLabel')}</strong> {licence.trial ? t('yes') : t('no')}
            </Typography>
            <Typography variant="body1" component="p">
              <strong>{t('expirationDateLabel')}</strong> {licence.trial ? formatDate(licence.trialEnd) : formatDate(licence.endDate)}
            </Typography>
          </>
        )}
        {status === 'add' && <AddLicenceForm teamId={teamId} serviceId={serviceId} onClose={handleOnClose} refetch={handleRefetch} />}
        {status === 'edit' && <EditLicenceForm onClose={handleOnClose} licence={licence as LicenceItem} refetch={handleRefetch} />}
      </DialogContent>

      {!status && (
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleOnClose}>
            {t('cancelButtonText')}
          </Button>
          {licence ? (
            <Button variant="contained" color="info" onClick={() => setStatus('edit')}>
              {t('updateText')}
            </Button>
          ) : (
            <Button variant="contained" color="info" onClick={() => setStatus('add')}>
              {t('addText')}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default LicenceDialog;
