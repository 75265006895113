import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { Template, FilterableColumnInfo, FilterGroup } from '@interfaces';
import { useTranslation } from 'react-i18next';
import { FilterCreation } from '@components';

interface RunTemplateDialogProps {
  open: boolean;
  onClose: () => void;
  onRun: () => void;
  template: Template | null;
  filterGroups: FilterGroup[];
  onFilterGroupsChange: (groups: FilterGroup[]) => void;
  logicalOperators: ('AND' | 'OR')[];
  onLogicalOperatorsChange: (operators: ('AND' | 'OR')[]) => void;
  availableColumns: FilterableColumnInfo[];
}

const RunTemplateDialog: React.FC<RunTemplateDialogProps> = ({
  open,
  onClose,
  onRun,
  template,
  filterGroups,
  onFilterGroupsChange,
  logicalOperators,
  onLogicalOperatorsChange,
  availableColumns,
}) => {
  const { t } = useTranslation('pano');

  if (!template) return null;

  return (
    <Dialog
      open={open}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose();
        }
      }}
      maxWidth="md"
      fullWidth>
      <DialogTitle>{t('runTemplate', { name: template.name })}</DialogTitle>
      <DialogContent>
        <Typography variant="h6" className="pb-2">
          {t('addFiltersOptional')}
        </Typography>
        <FilterCreation
          filterGroups={filterGroups}
          onFilterGroupsChange={onFilterGroupsChange}
          interGroupOperators={logicalOperators}
          onInterGroupOperatorsChange={onLogicalOperatorsChange}
          availableColumns={availableColumns}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onRun}>
          {t('run')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RunTemplateDialog;
