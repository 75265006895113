export interface TeamConfigurationItem {
  clinicalSystem?: TeamConfigurationClinicalSystemItem;
  lookups: TeamConfigurationLookupItem[];
  priority?: TeamConfigurationPriorityItem;
}

export interface TeamConfigurationLookupItem {
  mediaConfigurationId: number;
  name: string;
  values: string[];
  defaultValue: string;
  isSticky: boolean;
}

export interface TeamConfigurationPriorityItem {
  mediaConfigurationId: number;
  values: PriorityItem[];
  name?: string;
}

export interface TeamConfigurationClinicalSystemItem {
  customerNumber: string | null;
  isTestSystem: boolean;
  mediaConfigurationId: number;
  type: string;
  uri: string | null;
}

export enum ThirdPartyDataSystemType {
  None,
  Emis,
  SystmOne,
  Vision,
}

export interface PriorityItem {
  deadlinePeriod: string | null;
  highlight: string;
  id: string;
  name: string;
  warningPeriod: string | null;
}

export interface WorkerPoolItem {
  category?: number;
  description: string;
  id: number;
  locked?: boolean;
  name: string;
  workers: WorkerIdItem[];
}

export interface WorkerIdItem {
  id: number;
  workerType: string;
  typeName?: string;
}

export interface WorkerPoolCategoryItem {
  id: number;
  name: string;
}

export enum WorkerItemType {
  User = 1,
  Service,
  NoOne,
}

export interface PoolMemberItem {
  ruleId: number;
  members: PoolMemberUserItem[];
}
export interface PoolMemberUserItem {
  id: number;
  email: string;
  givenName: string;
  familyName: string;
  membershipPending: boolean;
  tableData: {
    checked: boolean;
    disabled: boolean;
  };
}

export interface WorkflowItem {
  name: string;
  readOnlySteps: ReadOnlyStepItem[];
  steps: WorkflowStepItem[];
  workflowId: number;
  workflowTemplateState: number;
  workflowVersion: number;
  id: number;
  version: number;
}

export interface GlobalWorkflowItem {
  name: string;
  teamIds: number[];
  workflowId: number;
  workflowTemplateState: number;
  workflowVersion: number;
  id: number;
  version: number;
}

export interface WorkflowStepItem {
  authorOnly: boolean;
  name: string;
  stepConfigurations: WorkflowStepMediaConfigurationDto[];
  stepIndex: number;
  workflowId: number;
  id: number;
  version: number;
  isService: boolean;
  workflowServiceId: number | null;
  service: WorkflowServiceDto | null;
}

export interface ReadOnlyStepItem {
  authorOnly: boolean;
  id: number;
  index: number;
  name: string;
  workflowId: number;
}

export interface WorkflowStepFilterItemTableRow {
  definition: string;
  description: string;
  isCategory: boolean;
  workerPoolId: number;
  workflowStepId: number;
  id: number;
  version: number;
  rowId: string;
}

export interface PostWorflowFiltersResult {
  affectedJobCount: number;
  persistedWorkflowStepFilters: WorkflowStepFilterDto[];
}

export interface ThirdPartyDataSourceNotification {
  bindingPath: string | null;
  updateSettings: any | null;
  isActive: boolean;
}

export interface LookupValue {
  deadlinePeriod: string;
  highlight: string;
  id: string;
  name: string;
  warningPeriod: string;
}

export interface DestinationStep {
  colour: string;
  icon: number;
  stepId: number;
  targetStepIndex: number;
  tooltip: string;
}

export interface MediaConfiguration {
  dataSourceProviderId?: string;
  thirdPartyDataSourceSystemSettings?: string;
  searchFieldName: string;
  allowDocumentModification: boolean;
  allowsMultiple: boolean;
  ascendingDisplayOrderScore: number;
  canBeGridColumn: boolean;
  description: string;
  gridSubcolumnsCanBeChosen: boolean;
  isDerived: boolean;
  isNameValueProvider: boolean;
  isSortable: boolean;
  isSticky: boolean;
  name: string;
  required: boolean;
  teamId: number;
  thirdPartyDataSourceNotification: ThirdPartyDataSourceNotification;
  viewModelType: number;
  visualId: string;
  id: number;
  version: number;
  typeName: string;
  defaultValue?: string;
  lookupValues?: string[];
  values?: LookupValue[];
  actions?: any[];
  thumbnailSize?: number;
  viewMode?: string;
  allowNonAuthorEditing?: boolean;
  destinationSteps?: DestinationStep[];
  minimumValue?: number;
  maximumValue?: number;
}

export interface MediaConfigurationTeamGroup {
  mediaConfigurations: MediaConfiguration[];
  teamIds: number[];
  typeName: string;
}

export interface WorkflowTemplateDto {
  id: number;
  version: number;
  labels: string[];
  workflowDto: WorkflowItem;
}

export interface PostOrPutWorkflowTemplateDto {
  id: number;
  labels: string[];
  layouts: ViewLayoutPutDto[];
  name: string;
  publishAction: PublishAction;
  steps: WorkflowStepDto[];
  workerPoolGridColumnSettings: WorkerPoolGridColumnSettingsDto[];
  workflowStepFilters: WorkflowStepFilterDto[];
}

export interface PostOrPutWorkflowDto {
  layouts: ViewLayoutPutDto[];
  name: string;
  publishTemplateAction: number;
  templateState: number;
  steps: WorkflowStepDto[];
  workerPoolGridColumnSettings: WorkerPoolGridColumnSettingsDto[];
  workflowStepFilters: WorkflowStepFilterDto[];
}

export interface WorkflowServiceDto {
  name: string;
  statusRoutes: WorkflowServiceStatusRouteDto[];
  config: SystemMessageConfig;
  workFlowServiceTemplateId: number;
  workflowId: number;
  id: number;
  version: number;
}

export interface SystemMessageConfig {
  systemMessageSummary: string;
  systemMessageTypes: Array<{
    typeName: string;
    systemMessages: Array<{
      key: string;
      value: string;
    }>;
  }>;
}

export interface ViewLayoutPutDto {
  description: string;
  layoutType: string;
  stepId: number;
  xml: string;
}

export interface WorkflowStepDto {
  authorOnly: boolean;
  name: string;
  stepConfigurations: WorkflowStepMediaConfigurationDto[];
  stepIndex: number;
  workflowId: number;
  id: number;
  version: number;
  isService: boolean;
  workflowServiceId: number | null;
  service: WorkflowServiceDto | null;
}

export interface WorkflowStepMediaConfigurationDto {
  isReadOnly: boolean;
  mediaConfiguration: IMediaConfigurationDto;
  workflowStepId: number;
  typeName?: string;
}

export interface WorkerPoolGridColumnSettingsDto {
  workerPoolId: number;
  gridColumnSettingDtos: GridColumnSettingDto[];
}

export interface GridColumnSettingDto {
  columnOrder: number;
  mediaConfigurationId: number;
  subcolumnId: number;
}

export interface WorkflowStepFilterDto {
  definition: string;
  description: string;
  isCategory: boolean;
  workerPoolId: number;
  workflowStepId: number;
  id: number;
  version: number;
}

export interface IMediaConfigurationDto {
  dataSourceProviderId?: string;
  thirdPartyDataSourceSystemSettings?: string;
  searchFieldName?: string;
  allowDocumentModification: boolean;
  allowsMultiple: boolean;
  ascendingDisplayOrderScore: number;
  canBeGridColumn: boolean;
  description: string;
  gridSubcolumnsCanBeChosen: boolean;
  isDerived: boolean;
  isNameValueProvider: boolean;
  isSortable: boolean;
  isSticky: boolean;
  name: string;
  required: boolean;
  teamId: number;
  thirdPartyDataSourceNotification: ThirdPartyDataSourceNotification;
  viewModelType: ViewModelType;
  viewMode?: string;
  visualId: string;
  id: number;
  version: number;
  typeName?: string;
  destinationSteps?: DestinationStep[];
}

export interface ViewLayoutGetDto {
  configurationIds: number[];
  description: string;
  layoutAvailability: string;
  stepId: number;
  xml: string;
}

export interface ViewWorkflowLayoutDto {
  description: string;
  layoutType: string;
  stepId: number;
  stepVersion: number;
  xml: string;
}

export interface IMediaDto {
  id: string;
  mediaConfigurationId: number;
}

export enum ViewModelType {
  Embedded,
  StandAlone,
  Hidden,
}

export enum PublishAction {
  UpdateOnly,
  ToPublic,
  ToPrivate,
}

export interface DestinationStep {
  colour: string;
  icon: number;
  stepId: number;
  targetStepIndex: number;
  tooltip: string;
}

export interface LayoutElement {
  id: string;
  row: number;
  column: number;
  columnSpan?: number;
}

export interface LayoutSection {
  type: string;
  header: string;
  columns: string;
  rows: string;
  elements: LayoutElement[];
}

export interface LayoutItem {
  version: number;
  sections: LayoutSection[];
}

export interface CreateWorkflowFromTemplateDto {
  name: string;
  targetTeamId: number;
  workerPoolGridColumnSettings: WorkerPoolGridColumnSettingsDto[];
  workflowStepFilters: WorkflowStepFilterDto[];
  workflowTemplateId: number;
}

export interface WorkflowServiceTemplateDto {
  name: string;
  id: number;
  statuses: WorkflowServiceStatusRouteDto[];
  config: SystemMessageConfig | null;
}

export interface WorkflowServiceStatusRouteDto {
  name: string;
  progressStepIndex: number;
}
