import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@hooks';
import { TemplateManagement, ReportDisplay } from '@components';
import { setHelmet, setBreadcrumbs } from '@slices';
import { useTranslation } from 'react-i18next';

const Report: React.FC = () => {
  const { generatedReport } = useAppSelector((state) => state.report);
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setHelmet({ title: t('htmlTitleReportTemplateDetails') }));
    dispatch(setBreadcrumbs([{ text: t('reportTemplateBredcrum'), link: '/users' }]));
  }, []);

  return (
    <Box className="p-4">
      <TemplateManagement />
      {generatedReport.data.length > 0 && <ReportDisplay />}
    </Box>
  );
};

export default Report;
