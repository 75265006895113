import { useContext, useEffect } from 'react';
import { useNavigate, UNSAFE_NavigationContext } from 'react-router-dom';
import { UnsavedChangesContext, useUnsavedChangesContext } from '@hooks';
import { GlobalUnsavedChangesDialog } from './GlobalUnsavedChangesDialog';

interface Props {
  children: React.ReactNode;
}

export const RouterGuard = ({ children }: Props) => {
  const contextValue = useUnsavedChangesContext();
  const { isDirty, setIsDirty, pendingPath, setPendingPath, showPrompt, setShowPrompt } = contextValue;
  const navigate = useNavigate();
  const navigator = useContext(UNSAFE_NavigationContext).navigator as any;

  useEffect(() => {
    if (!isDirty) return;

    const originalPush = navigator.push;

    navigator.push = (to: string) => {
      if (to !== window.location.pathname) {
        setPendingPath(to);
        setShowPrompt(true);
        return false;
      }
      return originalPush(to);
    };

    return () => {
      navigator.push = originalPush;
    };
  }, [isDirty, navigator, setPendingPath, setShowPrompt]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = '';
        return '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isDirty]);

  const handleContinueNavigation = () => {
    setIsDirty(false);
    setShowPrompt(false);

    if (pendingPath) {
      setTimeout(() => {
        navigate(pendingPath);
        setPendingPath(null);
      }, 0);
    }
  };

  const handleCancelNavigation = () => {
    setShowPrompt(false);
    setPendingPath(null);
  };

  return (
    <UnsavedChangesContext.Provider value={contextValue}>
      {children}
      <GlobalUnsavedChangesDialog open={showPrompt} onContinue={handleContinueNavigation} onCancel={handleCancelNavigation} />
    </UnsavedChangesContext.Provider>
  );
};
