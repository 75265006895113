import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import { Button, TextField, Paper, Typography, Box, CircularProgress, Alert, Container, IconButton, ToggleButton, FormControlLabel, Switch, SelectChangeEvent, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faMicrophone, 
  faCopy, 
  faCheck, 
  faTrash
} from '@fortawesome/pro-regular-svg-icons';
import { 
  faCommentLines,
  faStethoscope,
  faCommentSmile,
  faCircle,
  faStop
} from '@fortawesome/pro-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '@hooks';
import { setBreadcrumbs, setHelmet, openSnackbar } from '@slices';
import { useAuth } from 'oidc-react';
import { useGetSubstitutionModesQuery } from '@apis';
import { SubstitutionMode } from '@interfaces';
import { useNavigate } from 'react-router-dom';
import { sysConfig } from '@utils';
import audioProcessorUrl from '../worklets/processor.worklet.js'; // Import worklet

// Get icon based on mode name
const getIconForMode = (mode: SubstitutionMode) => {
  switch (mode.name.toLowerCase()) {
    case 'default':
      return faCommentLines;
    case 'medical':
      return faStethoscope;
    case 'simplified':
      return faCommentSmile;
    default:
      return faCommentLines;
  }
};

const AudioWave = ({ isRecording, audioLevel }: { isRecording: boolean; audioLevel: number }) => {
  const EchoTranscribeWaveFormBoxRef = useRef<HTMLDivElement>(null);
  const [barCount, setBarCount] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const parentBox = EchoTranscribeWaveFormBoxRef.current;
      if (parentBox) {
        const parentBoxWidth = parentBox.clientWidth - 20;
        const barWidth = 10; // Width of each bar
        const gap = 2; // Gap between bars
        const calculatedBars = parentBox.clientWidth === 0 ? 4 : Math.floor(parentBoxWidth / (barWidth + gap)); 
        setBarCount(calculatedBars); 
      }
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  return (
    <Box 
      id='EchoTranscribeWaveFormBox'
      ref={EchoTranscribeWaveFormBoxRef} 
      sx={{ 
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center', 
        gap: '2px', // Match the gap used in barCount calculation
        height: '24px',
        marginLeft: '12px'
      }}
    >
      {[...Array(barCount)].map((_, i) => (
        <Box
          key={i}
          sx={{
            width: '10px', 
            backgroundColor: '#2196f3',
            alignItems: 'center', 
            borderRadius: '2px',
            height: isRecording 
            ? `${Math.max(4, Math.min(24, audioLevel * 20 + 10 * Math.sin((i / barCount) * 2 * Math.PI)))}px` 
            : '4px',
          }}
        />
      ))}
    </Box>
  );
};

const EchoTranscribe = (): JSX.Element => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const { userData } = useAuth();
  const { userRights } = useAppSelector((state) => state.app);
  const navigate = useNavigate();
  const [isRecording, setIsRecording] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [transcription, setTranscription] = useState(() => {
  // Initialize from sessionStorage if available
  const savedTranscription = sessionStorage.getItem('echoOriginalTranscription');
  return savedTranscription || '';
  });
  const [error, setError] = useState<string | null>(null);
  const [currentModeIndex, setCurrentModeIndex] = useState(0);
  const [audioLevel, setAudioLevel] = useState(0);
  const webSocketRef = useRef<WebSocket | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const workletNodeRef = useRef<AudioWorkletNode | null>(null);
  const animationFrameRef = useRef<number>(); 
  const [isLiveConsultation, setIsLiveConsultation] = useState(false);
  const [liveConsultationResponse, setLiveConsultationReponse] = useState('')
  // Fetch substitution modes using RTK Query
  const { data: substitutionModes = [], error: modesError, isLoading: isLoadingModes } = useGetSubstitutionModesQuery(undefined, {
    skip: !userRights.isSysAdmin
  });
  const [liveConsultationDropdown, setLiveConsultationDropdown] = useState<{ selection: string | number }>({
      selection: 'Live_Consultation',
    });
  

  useEffect(() => {
    if (modesError) {
      setError('Failed to load substitution modes');
    }
  }, [modesError]);

  useEffect(() => {
    if (substitutionModes.length > 0) {
      console.log('Loaded substitution modes:', substitutionModes.map(mode => ({
        name: mode.name,
        iconId: mode.fontAwesomeIcon,
        icon: getIconForMode(mode).iconName
      })));
    }
  }, [substitutionModes]);

  useEffect(() => {
    // Clear any existing error when auth state changes
    if (userData?.access_token) {
      setError(null);
    }
  }, [userData?.access_token]);

  useEffect(() => {
    if (!userRights.isSysAdmin) {
      navigate('/');
      return;
    }
    
    const title = 'Echo Transcribe';
    dispatch(setBreadcrumbs([{ text: title }]));
    dispatch(setHelmet({ title }));
  }, [dispatch, userRights.isSysAdmin]);

  useEffect(() => {
    return () => {
      // Cleanup when component unmounts
      handleStopRecording();
    };
  }, []); 

  useEffect(() => {
    setTranscription('');
    setLiveConsultationReponse('');
    sessionStorage.setItem('echoTranscription', '');
    sessionStorage.setItem('echoOriginalTranscription', '');
  }, [isLiveConsultation]);
  
  const handleTranscriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    sessionStorage.setItem('echoTranscription', event.target.value);
    sessionStorage.setItem('echoOriginalTranscription', event.target.value);
    setTranscription(event.target.value);
  };
  const initializeAudioProcessing = async (stream: MediaStream) => {
    try {
      console.log('Initializing Web Audio API...');
      audioContextRef.current = new AudioContext({ sampleRate: 16000 });
      
      console.log('Loading audio worklet...');
      try {
        const locationInfo = {
          origin: window.location.origin,
          pathname: window.location.pathname,
          href: window.location.href,
          baseURI: document.baseURI
        };
        console.log('Current location:', locationInfo);

        // Load worklet using the imported URL
        console.log('Loading worklet from:', audioProcessorUrl);
        await audioContextRef.current.audioWorklet.addModule(audioProcessorUrl);
        console.log('Audio worklet loaded successfully');
      } catch (error) {
        console.error('Failed to load audio worklet:', {
          error,
          errorMessage: error.message,
          errorName: error.name,
          errorStack: error.stack
        });
        throw error;
      }
      
      // Create audio source from media stream
      const source = audioContextRef.current.createMediaStreamSource(stream);
      
      // Create and connect the audio worklet node
      workletNodeRef.current = new AudioWorkletNode(audioContextRef.current, 'audio-processor');
      
      // Handle audio data from the worklet
      workletNodeRef.current.port.onmessage = (event) => {
        const { eventType, audioBuffer, level } = event.data;
        
        if (eventType === 'buffer' && webSocketRef.current?.readyState === WebSocket.OPEN) {
          // Convert Float32Array to Int16Array for WebSocket transmission
          const intBuffer = new Int16Array(audioBuffer.length);
          for (let i = 0; i < audioBuffer.length; i++) {
            intBuffer[i] = Math.max(-1, Math.min(1, audioBuffer[i])) * 0x7FFF;
          }
          webSocketRef.current.send(intBuffer.buffer);
        } else if (eventType === 'level') {
          setAudioLevel(level * 100); // Scale the level for visualization
        }
      };

      // Connect the audio nodes
      source.connect(workletNodeRef.current);
      workletNodeRef.current.connect(audioContextRef.current.destination);

    } catch (err) {
      console.error('Error initializing audio processing:', err);
      setError(t('audioInitError'));
      handleStopRecording();
    }
  };

  const CleanUpWebsocketAfterStop = ()=> {
    if (webSocketRef.current) {
      webSocketRef.current.close();
      webSocketRef.current = null;
    }

    if (workletNodeRef.current) {
      workletNodeRef.current.disconnect();
      workletNodeRef.current = null;
    }

    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }

    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach(track => track.stop());
      mediaStreamRef.current = null;
    }   
  };

  const handleStopRecording = () => {
    setIsRecording(false);
    setIsConnecting(false);
    if(isLiveConsultation && error === null)
          webSocketRef.current?.send("LiveConsultationStop");
        else 
          CleanUpWebsocketAfterStop();     
  };
  const handleLiveConsultationSelection = (event: SelectChangeEvent<string | number>): void => {
        setLiveConsultationDropdown({ selection: event.target.value });      
    };

  const startRecording = async () => {
    try {
      setError(null);
      setIsConnecting(true);
      console.log('Starting recording...');

      // Check for token availability first
      if (!userData?.access_token) {
        console.error('No access token available, waiting for authentication...');
        setError(t('authenticationError'));
        setIsConnecting(false);
        return;
      }

      // Cleanup any existing WebSocket connection
      if (webSocketRef.current) {
        console.log('Cleaning up existing WebSocket connection...');
        webSocketRef.current.close();
        webSocketRef.current = null;
      }

      // Get audio stream
      console.log('Requesting microphone access...');
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: 1,
          sampleRate: 16000,
          echoCancellation: true,
          noiseSuppression: true,
        }
      });
      console.log('Microphone access granted');
      mediaStreamRef.current = stream;

      // Initialize Web Audio API
      await initializeAudioProcessing(stream);

      // Initialize WebSocket
      console.log('Initializing WebSocket...');
      const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
      const currentMode = substitutionModes[currentModeIndex];
      const echoModeType = isLiveConsultation ? liveConsultationDropdown.selection : 'EchoTranscribe';
      // Convert https:// to wss:// or http:// to ws://
      const wsUrl = sysConfig.echoUri.replace(/^https?:\/\//, `${protocol}://`) + `/api/v2/transcription/ws?substitutionModeId=${currentMode.id}&Transcription=${transcription}&echoModeType=${echoModeType}`;
      
      
      webSocketRef.current = new WebSocket(wsUrl);

      // Add connection timeout
      const connectionTimeout = setTimeout(() => {
        if (webSocketRef.current?.readyState !== WebSocket.OPEN) {
          console.error('WebSocket connection timeout');
          setError(t('connectionTimeout'));
          handleStopRecording();
        }
      }, 5000);

      webSocketRef.current.onopen = () => {
        console.log('WebSocket connected, sending authentication...');
        clearTimeout(connectionTimeout);
        
        if (webSocketRef.current && userData?.access_token) {
          console.log('Access token available, preparing auth message...');
          const authMessage = {
            type: 'authentication',
            token: userData.access_token
          };
          console.log('Sending auth message:', JSON.stringify({ ...authMessage, token: '[REDACTED]' }));
          webSocketRef.current.send(JSON.stringify(authMessage));
          console.log('Authentication message sent');
        } else {
          console.error('No access token available', { 
            webSocketState: webSocketRef.current?.readyState,
            hasUserData: !!userData,
            hasToken: !!userData?.access_token
          });
          setError(t('authenticationError'));
          setIsConnecting(false);
          handleStopRecording();
          return;
        }
      };

      webSocketRef.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        setError(t('connectionError'));
        setIsConnecting(false);
        handleStopRecording();
      };

      webSocketRef.current.onclose = (event) => {
        console.log('WebSocket closed:', { 
          code: event.code, 
          reason: event.reason, 
          wasClean: event.wasClean,
          readyState: webSocketRef.current?.readyState
        });
        if (isRecording) {
          setError(t('connectionClosed'));
          handleStopRecording();
        }
        setIsConnecting(false);
      };

      webSocketRef.current.onmessage = (event) => {       
        try {          
          const response = JSON.parse(event.data);
          if (response.type === 'transcription') {
            const savedTranscription = sessionStorage.getItem('echoOriginalTranscription') || '';
            const transcription = savedTranscription + response.text;  
           setTranscription(transcription);
          }
          else if (response.type === 'liveConsultation'){
           setLiveConsultationReponse(response.text);
           CleanUpWebsocketAfterStop();
          }
          else if (response.type === 'failedConsultation'){
            setLiveConsultationReponse('Consultation has failed to process');
            CleanUpWebsocketAfterStop();
           }
          else if (response.type === 'error') {
            console.error('Server error:', response.message);
            setError(response.message);
            handleStopRecording();
          } 
          else if (response.type === 'authenticated') {
            console.log('Authentication successful, starting audio processing...');
            setIsRecording(true);
            setIsConnecting(false);
          } 
          else {
            console.warn('Unknown message type:', response.type);
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', error, event.data);
        }
      };
    } catch (error) {
      console.error('Error starting recording:', error);
      setError(t('microphoneError'));
      setIsConnecting(false);
      await handleStopRecording();
    }
  };
 

  const toggleSubstitutionMode = () => {
    setCurrentModeIndex((prevIndex) => (prevIndex + 1) % substitutionModes.length);
  };

  return (
    <Container maxWidth="md">
      <Paper className="p-6">
        <Box className="flex flex-col gap-4">
        <FormControlLabel
          control={
            <Switch checked={isLiveConsultation} onChange={(_e, checked) => setIsLiveConsultation(checked)} />
          }
          label="Live Consultation"
        />
        {isLiveConsultation &&(
           <Select
           id="liveConsultationOptions"
           label={t('liveConsultationOptions')}
           value={liveConsultationDropdown.selection}
           onChange={handleLiveConsultationSelection}>
            <MenuItem value={'Live_Consultation'}>{'GP Consultation'}</MenuItem>
            <MenuItem value={'Live_Referral'}>{'GP Referral'}</MenuItem>
            <MenuItem value={'Live_Home'}>{'GP Home Visit'}</MenuItem>
            <MenuItem value={'Live_NursingHome'}>{'GP Nursing Home Visit'}</MenuItem>
         </Select>
        )}
          <Box className="flex gap-2">          
            <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  border: '1px solid #ccc',
                  borderRadius: '20px', 
                  padding: '2px' 
            }}>
              <IconButton
              onClick={isRecording ? handleStopRecording : startRecording}
              disabled={isConnecting || substitutionModes.length === 0 || isLoadingModes}
              title={isRecording ? t('echoTranscribeStop') : t('echoTranscribeStart')}>
                <FontAwesomeIcon icon={isRecording ? faStop : faCircle} />
              </IconButton>
              
            
              {substitutionModes.length > 0 && (
                <IconButton
                onClick={() => setCurrentModeIndex((prevIndex) => (prevIndex + 1) % substitutionModes.length)}
                title={substitutionModes[currentModeIndex].name.toLowerCase() === 'default' ? 'Default' : substitutionModes[currentModeIndex].tooltip}
                disabled={isConnecting || isRecording}>
                <FontAwesomeIcon 
                  icon={getIconForMode(substitutionModes[currentModeIndex])} 
                  style={{ color: substitutionModes[currentModeIndex].name.toLowerCase() === 'default' ? '#808080' : substitutionModes[currentModeIndex].iconColour }} 
                />
              </IconButton>
              )}
            </Box>
            <Box sx={{ 
                  display: 'flex', 
                  flexGrow: 1,
                  alignItems: 'center'
            }}>
            {<AudioWave isRecording={isRecording} audioLevel={audioLevel} />}     
            </Box>     
          </Box>

          {isConnecting && (
            <Box display="flex" alignItems="center" gap={2}>
              <CircularProgress size={20} />
              <Typography variant="body2">{t('Connecting...')}</Typography>
            </Box>
          )}

          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}
          {!isLiveConsultation &&(
            <TextField
              multiline
              minRows={12}
              value={transcription}
              onChange={handleTranscriptionChange}
              variant="outlined"
              fullWidth
              placeholder="Transcription will be here..."           
            />
          )}
          {isLiveConsultation &&(
            <TextField
              multiline  
              minRows={12}            
              value={liveConsultationResponse}
              fullWidth variant="standard"
              placeholder="Waiting for consultation to stop"           
            />
          )}

        </Box>        
      </Paper>
    </Container>
  );
};

export default EchoTranscribe;
